import { SET_SOCKET } from './actionTypes';

const setSocket = (socket) => (dispatch) => {
	dispatch({
		type: SET_SOCKET,
		payload: socket,
	});
};

export { setSocket };
