import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
	border,
	shadow,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Content = styled.nav`
		padding-left: 20px;
		border-bottom: 1px solid #333333;
		box-shadow: 0 0 30px #f3f1f1;
		overflow: auto;
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		${border}
		${shadow}
`;

const Header = ({ ...restProps }) => {
	const theme = useContext(ThemeContext);
	return <Content {...theme.nav} {...restProps} />;
};

export default Header;
