import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { BookFilled } from '@ant-design/icons';
import { Box } from '@common/components';
import { Badge, Modal } from 'antd';
import {
  blankpage,
  notebook01,
  notebook02,
  notebook03,
  notebook04,
  notebook05,
  notebook06,
} from '@games/BataviaExpress/assets/images';
import HTMLFlipBook from 'react-pageflip';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';

const Notebook = ({ clues, doAction, socket, match }) => {
  const { params } = match || {};
  const { channelId } = params || {};
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (clues.filter((clue) => clue.id === 'clue0203').length > 0) {
      setCount(1);
    }
  }, [clues]);

  const notebook = useCallback(() => {
    return clues.filter((clue) => clue.id === 'clue0203').length > 0 ? (
      <HTMLFlipBook width={400} height={580}>
        <div className="demoPage">
          <img src={blankpage} alt="Blank" />
        </div>
        <div className="demoPage">
          <img src={notebook01} alt="Notebook Page 1" />
        </div>
        <div className="demoPage">
          <img src={notebook02} alt="Notebook Page 2" />
        </div>
        <div className="demoPage">
          <img src={notebook03} alt="Notebook Page 3" />
        </div>
        <div className="demoPage">
          <img src={notebook04} alt="Notebook Page 4" />
        </div>
        <div className="demoPage">
          <img src={notebook05} alt="Notebook Page 5" />
        </div>
        <div className="demoPage">
          <img src={notebook06} alt="Notebook Page 6" />
        </div>
        <div className="demoPage">
          <img src={blankpage} alt="Blank" />
        </div>
      </HTMLFlipBook>
    ) : (
      <HTMLFlipBook width={400} height={580}>
        <div className="demoPage">
          <img src={blankpage} alt="Blank" />
        </div>
        <div className="demoPage">
          <img src={notebook01} alt="Notebook Page 1" />
        </div>
        <div className="demoPage">
          <img src={notebook02} alt="Notebook Page 2" />
        </div>
        <div className="demoPage">
          <img src={notebook03} alt="Notebook Page 3" />
        </div>
        <div className="demoPage">
          <img src={notebook04} alt="Notebook Page 4" />
        </div>
        <div className="demoPage">
          <img src={notebook05} alt="Notebook Page 5" />
        </div>
        <div className="demoPage">
          <Box
            centered
            width={400}
            height={581}
            p={20}
            backgroundColor="#333"
            textAlign="center"
          >
            The back of the notebook seems to have been torned. It might be
            somewhere in the other rooms.
          </Box>
        </div>
      </HTMLFlipBook>
    );
  }, [clues]);

  return (
    <>
      <Box margin={10}>
        <Badge count={count}>
          <Box
            display="inline-block"
            padding={1}
            borderRadius={3}
            backgroundColor="#FFF"
            color="#333"
            pr={10}
            onClick={() => {
              doAction({
                game: 'batavia-express',
                channel: socket.channel,
                event: 'log',
                message: {
                  id: socket.id,
                  name: u.name,
                  channel: channelId,
                  message: `has opened Naga Mas notebook`,
                  game: 'batavia-express',
                },
              });
              setVisible(true);
            }}
          >
            <BookFilled style={{ fontSize: 40, color: 'brown' }} /> Naga Mas
            Notebook
          </Box>
        </Badge>
      </Box>
      <Modal
        visible={visible}
        width={850}
        footer={null}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              channel: channelId,
              message: `has closed Naga Mas notebook`,
              game: 'batavia-express',
            },
          });
          setVisible(false);
        }}
      >
        {notebook()}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  clues: state.clues,
  navigation: state.navigation,
  socket: state.socket,
});

export default connect(mapStateToProps, { doAction })(Notebook);
