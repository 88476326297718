import {
	SET_HELPS,
	SET_HELP,
	CLEAR_HELPS,
	CLEAR_HELP,
	ADD_HELP,
	ADD_HELPS,
	UPDATE_HELP,
	REMOVE_HELP,
} from './actionTypes';

export const help = (state = {}, action) => {
	switch (action.type) {
		case SET_HELP:
			return action.payload;
		case UPDATE_HELP:
			if (state.toString() !== action.payload.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_HELP:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_HELPS:
			return action.payload;
		case ADD_HELP:
			if (state.filter((item) => item === action.payload).length > 0) {
				return [...state];
			}

			return [...state, action.payload];
		case ADD_HELPS:
			const c = [...state];
			action.payload.forEach((payload) => {
				if (state.filter((item) => item === payload).length === 0) {
					c.push(payload);
				}
			});

			return c;
		case UPDATE_HELP:
			return state.map((item) => {
				if (item !== action.payload) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_HELP:
			return state.map((item) => {
				if (item !== action.payload) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_HELPS:
			return [];
		default:
			return state;
	}
};
