import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path02, door01, door03, scenarioBg, clue02 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import Help from '@games/GrandHotelPro/fragments/Help';

const Lift = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addPuzzle,
  clues,
  updateMember,
  addSolve,
  addStory,
}) => {
  const u = getCookie('GHPuser') ? JSON.parse(getCookie('GHPuser')) : {};
  const location = 'Lift';
  const [visiblePuzzle01, setVisiblePuzzle01] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1740;
  const height = 1210;
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle02',
        shape: 'poly',
        coords: [1251, 721, 1251, 828, 1312, 828, 1312, 721],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/lift`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel-pro',
          },
        });
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel-pro',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/lift`
    ) {
      setNavigation({
        backward: {
          title: 'Room 202',
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/room-202`);
          },
          image: door01,
        },
        forward: {
          title: 'Wine Cellar',
          locked: solves.filter((solve) => solve === '02').length === 0,
          image: door03,
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/wine-cellar`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle02') {
      addPuzzle({
        ...puzzlesConf.puzzle01,
        status: 'unsolved',
      });
      setVisiblePuzzle01(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Lift puzzle',
          game: 'grand-hotel-pro',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path02}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('02');
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '02',
              channel: channelId,
              game: 'grand-hotel-pro',
              location: 'Lift',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          As soon as you decipher intent of the letter and the location
          concealed in it, you hastily search for the nearest lift. Along the
          corridor, you bump into Lauren. “Good evening. You seem lost. May I
          help you?” asked Lauren. Hesitating to tell her what happened, you
          reply, “I am fine. I just wanted to explore more of this exquisite
          hotel. I will be on my way.”
        </Box>
        <Box fontSize={20}>
          As you raise your hand to press the button, Lauren beat you to it and
          with a pleasant smile in her face say, “Let me escort you to your
          location. We do not want our guest to be stranded in an unknown room,
          won’t we? Where are you heading to?“
        </Box>
      </Modal>
      <Modal
        visible={visiblePuzzle01}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Room 202 puzzle',
              game: 'grand-hotel-pro',
            },
          });
          setVisiblePuzzle01(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12}>
            <Box fontSize={20}>
              <Box>Choose the 3-digit number of the location</Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  001 – 099{' '}
                </Box>
                <Box>Guest Suites</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  111
                </Box>
                <Box> Ballroom</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  112
                </Box>
                <Box> Wine Cellar</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  116
                </Box>
                <Box>Kitchen</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  117
                </Box>
                <Box>Honeymoon Suite</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  118
                </Box>
                <Box> Lobby</Box>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box width={150} fontWeight="bold">
                  120
                </Box>
                <Box>Maid’s Quarter</Box>
              </Box>
            </Box>
          </Col>
          <Col xs={12}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '02').length === 0 ? (
                <>
                  You need to solve the next location and use the room number to
                  open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="117"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('02');
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Lift Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in the Lift! The door to Wine Cellar has now been unlocked.`,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '02',
                          channel: channelId,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          game: 'grand-hotel-pro',
                          puzzle: '02',
                          message: 'has solved the Lift puzzle',
                        },
                      });

                      setNavigation({
                        backward: {
                          title: 'Room 202',
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/room-202`
                            );
                          },
                          content: door01,
                        },
                        forward: {
                          title: 'Wine Cellar',
                          image: door03,
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/wine-cellar`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="02"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <p>
                          <img width={300} src={clue02} alt="clue02" />
                        </p>
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Lift);
