import React, { useState, useEffect } from 'react';
import { Box, Container, View, Title } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { Button, Input, Form, InputNumber } from 'antd';
import {
  fetchPuzzles,
  fetchPuzzle,
  clearPuzzle,
} from '@resources/Puzzle/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { isEmpty } from 'lodash';
import { BasicUploadField } from '@components';
const FormItem = Form.Item;

const Puzzle = ({
  socket,
  match,
  history,
  fetchPuzzle,
  puzzle,
  doAction,
  clearPuzzle,
}) => {
  const { params } = match || {};
  const { gameId, puzzleId } = params;
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState(
    puzzle && puzzle.media && puzzle.media.originalName
      ? [
          {
            uid: 'media',
            name: puzzle.media.originalName,
            url: puzzle.media.path,
            response: { data: [puzzle.media] },
          },
        ]
      : []
  );

  const fetchData = async () => {
    puzzleId && (await fetchPuzzle(puzzleId));
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);

    return () => {
      clearPuzzle();
    };
  }, []);

  useEffect(() => {
    puzzle &&
      puzzle.media &&
      puzzle.media.originalName &&
      setMedia([
        {
          uid: 'media',
          name: puzzle.media.originalName,
          url: puzzle.media.path,
          response: { data: [puzzle.media] },
        },
      ]);
  }, [puzzle]);

  const handleFinish = async (values) => {
    await doAction({
      game: gameId,
      event: 'puzzle',
      action: puzzleId ? 'update' : 'create',
      id: puzzleId,
      message: {
        game: gameId,
        stage: values.stage,
        question: values.question,
        description: values.description,
        media:
          media && media.length > 0
            ? media
                .filter(
                  (med) => med.response.data && med.response.data.length > 0
                )
                .map((med) => med.response.data[0]._id)
            : [],
      },
    });

    history.push(`/carnival/${gameId}/manage`);
  };

  return (
    <View>
      <Container>
        <Box maxWidth={500} py={100}>
          {!loading && (!puzzleId || !isEmpty(puzzle)) && (
            <Form
              layout="vertical"
              name="access-form"
              onFinish={handleFinish}
              initialValues={puzzle}
            >
              <Title>Enter your booth</Title>
              <BasicUploadField
                fieldOptions={{
                  name: 'media',
                  label: 'Media Booth',
                  rules: [
                    {
                      required: true,
                      message: 'Please upload your media',
                    },
                  ],
                }}
                folder="/media/booth"
                uploadOptions={{
                  className: 'full-width',
                  beforeUpload: (file) => {
                    return true;
                  },
                }}
                source={media}
                setSource={setMedia}
                text="Upload"
              />
              <FormItem
                label="Order"
                name="stage"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your order',
                  },
                ]}
              >
                <InputNumber />
              </FormItem>
              <FormItem
                label="Booth name"
                name="question"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your booth',
                  },
                ]}
              >
                <Input />
              </FormItem>

              <FormItem
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your booth description',
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem>
                <Button
                  className="login-form-button"
                  onClick={() => {
                    history.push(`/carnival/${gameId}/manage`);
                  }}
                >
                  {`Cancel`}
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {`Submit Booth`}
                </Button>
              </FormItem>
            </Form>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  puzzle: state.puzzle,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  state: state.state,
});

export default connect(mapStateToProps, {
  doAction,
  setNavigation,
  updateMember,
  fetchPuzzles,
  fetchPuzzle,
  clearPuzzle,
})(Puzzle);
