import React, { useState, useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { Box, View, Title } from '@common/components';
import { setTeam, clearTeam, fetchTeam } from '@resources/Team/actions';
import { connect } from 'react-redux';
import { doAction } from '@resources/Action/actions';
import { isEmpty } from 'lodash';
const FormItem = Form.Item;

const Team = ({ doAction, match, history, team, clearTeam, fetchTeam }) => {
  const { params } = match || {};
  const { gameId, teamId } = params;
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    await clearTeam();
    teamId && (await fetchTeam(teamId));
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);

    return () => {
      clearTeam();
    };
  }, []);

  const handleFinish = async (values) => {
    await doAction({
      game: gameId,
      event: 'team',
      action: teamId ? 'update' : 'create',
      id: teamId,
      message: {
        game: gameId,
        name: values.name,
        whatsapp: values.whatsapp,
      },
    });
    history.push(`/carnival/${gameId}/teams/manage`);
  };
  return (
    <View>
      <Box centered textAlign="center">
        {!loading && (!teamId || !isEmpty(team)) && (
          <Form
            layout="vertical"
            name="access-form"
            initialValues={team}
            onFinish={handleFinish}
          >
            <Title>Enter your team</Title>
            <FormItem
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please create a team',
                },
              ]}
            >
              <Input style={{ fontSize: 30 }} placeholder="Enter team name" />
            </FormItem>
            <FormItem
              label="Submission URL"
              name="whatsapp"
              rules={[
                {
                  required: true,
                  message: 'Please enter a whatsapp URL',
                },
              ]}
            >
              <Input placeholder="Enter whatsapp URL" />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Submit Team
              </Button>
            </FormItem>
          </Form>
        )}
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  team: state.team,
});

export default connect(mapStateToProps, {
  setTeam,
  doAction,
  clearTeam,
  fetchTeam,
})(Team);
