import {
  SET_INSTRUCTIONS,
  SET_INSTRUCTION,
  CLEAR_INSTRUCTIONS,
  CLEAR_INSTRUCTION,
  ADD_INSTRUCTION,
  ADD_INSTRUCTIONS,
  UPDATE_INSTRUCTION,
  REMOVE_INSTRUCTION,
} from './actionTypes';

export const instruction = (state = {}, action) => {
  switch (action.type) {
    case SET_INSTRUCTION:
      return action.payload;
    case UPDATE_INSTRUCTION:
      if (state.id.toString() !== action.payload.id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_INSTRUCTION:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_INSTRUCTIONS:
      return action.payload;
    case ADD_INSTRUCTION:
      if (state.filter((item) => item.id === action.payload.id).length > 0) {
        return [...state];
      }

      return [...state, action.payload];
    case ADD_INSTRUCTIONS:
      const c = [...state];
      action.payload.forEach((payload) => {
        if (state.filter((item) => item.id === payload.id).length === 0) {
          c.push(payload);
        }
      });

      return c;
    case UPDATE_INSTRUCTION:
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_INSTRUCTION:
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_INSTRUCTIONS:
      return [];
    default:
      return state;
  }
};
