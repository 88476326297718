import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path0102,
  door0101,
  door0103,
  puzzle0102,
  hint0102,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const HectorMcKing = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  clues,
  updateMember,
  addSolve,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Hector McKing Room';
  const [visiblePuzzle0102, setVisiblePuzzle0102] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1425;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0102',
        shape: 'poly',
        coords: [159, 203, 300, 200, 308, 297, 158, 307],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/hector-mc-king`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/hector-mc-king`
    ) {
      setNavigation({
        backward: {
          title: 'Smoking Car Room',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/smoking-car`);
          },
          image: door0101,
        },
        forward: {
          title: 'Countess Andrenzki Room',
          locked: solves.filter((solve) => solve === '0102').length === 0,
          image: door0103,
          onPress: () => {
            history.push(
              `/batavia-express/${channelId}/play/countess-andrenzki`
            );
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0102') {
      addPuzzle({
        ...puzzlesConf.puzzle0102,
        status: 'unsolved',
      });
      setVisiblePuzzle0102(true);
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Hector McKing puzzle',
          game: 'batavia-express',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0102}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0102}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Hector McKing puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0102(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12}>
            <img src={puzzle0102} alt="Puzzle" />
          </Col>
          <Col xs={12}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0102').length === 0 ? (
                <>
                  You need to solve the puzzle to the left and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="428"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room and found two clues! You may proceed to the next room'
                      );
                      addSolve('0102');
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Hector McKing Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in Hector McKing's room and found two clues! The door to Countess Andrenzki's room has now been unlocked.`,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0102',
                          channel: channelId,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0102',
                          message: 'has solved the Hector McKing puzzle',
                          game: 'batavia-express',
                        },
                      });
                      addClue({
                        ...cluesConf.clue010201,
                      });
                      addClue({
                        ...cluesConf.clue010202,
                      });
                      updatePuzzle({
                        ...puzzlesConf.puzzle0102,
                        status: 'solved',
                      });
                      setNavigation({
                        backward: {
                          title: 'Smoking Car Room',
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/smoking-car`
                            );
                          },
                          content: door0101,
                        },
                        forward: {
                          title: 'Countess Andrenzki Room',
                          image: door0103,
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/countess-andrenzki`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0102"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        The puzzle indicate that you should draw lines on the
                        clock faces on the smoking car room.
                        <br />
                        <br />
                        Using the example of the top instruction, draw line from
                        the <strong>yellow</strong> mark to the time six dots
                        farther along in a clockwise direction, then draw
                        another line from that dot to the time nine dots farther
                        along in a clockwise direction, then draw the last line
                        for that dot to the time six dots farther along in
                        clockwise direction. You will end up with the following
                        <br />
                        <br />
                        <img width={100} src={hint0102} alt="hint0102" />
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found two
                  clues in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(HectorMcKing);
