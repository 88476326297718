import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Carousel, Modal } from 'antd';
import { Box } from '@common/components';
import { storiesConfig } from '../../../../config';
import { scenarioBg } from '../../../../assets/images';

const Stories = ({ stories }) => {
  const [visible, setVisible] = useState(false);
  const storeConf = { ...storiesConfig() };
  return (
    <>
      {stories.length > 0 && (
        <Box mr={30} display="inline-block">
          <Badge count={stories.length}>
            <Button onClick={() => setVisible(true)}>Stories</Button>
          </Badge>
        </Box>
      )}
      <Modal
        visible={visible}
        width={600}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Box height={520}>
          <Carousel dotPosition="right">
            {stories &&
              stories.map((story, index) => {
                const store = `story${story}`;
                return (
                  <div key={`solve-${index}`}>
                    <Box px={30}>
                      <Box
                        centered
                        textAlign="center"
                        height={520}
                        overflowY="auto"
                        backgroundImage={`url(${scenarioBg})`}
                        color="#333"
                        flexDirection="row"
                      >
                        {storeConf[store].paragraphs.map((sto, index) => (
                          <Box fontSize={20} mb={30} p={20}>
                            {sto}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </div>
                );
              })}
          </Carousel>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  stories: state.stories,
});

export default connect(mapStateToProps)(Stories);
