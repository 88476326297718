import {
  SET_STATES,
  SET_STATE,
  CLEAR_STATES,
  CLEAR_STATE,
  ADD_STATE,
  ADD_STATES,
  UPDATE_STATE,
  REMOVE_STATE,
} from './actionTypes';

export const state = (state = {}, action) => {
  switch (action.type) {
    case SET_STATE:
      return action.payload;
    case UPDATE_STATE:
      if (state.id.toString() !== action.payload.id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_STATE:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_STATES:
      return action.payload;
    case ADD_STATE:
      return [...state, action.payload];
    case ADD_STATES:
      const s = [...state];
      action.payload.forEach((payload) => {
        if (state.filter((item) => item.id === payload.id).length === 0) {
          s.push(payload);
        }
      });

      return s;

    case UPDATE_STATE:
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_STATE:
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_STATES:
      return [];
    default:
      return state;
  }
};
