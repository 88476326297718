import { useEffect } from 'react';
import { setGame } from '@resources/Game/actions';
import { connect } from 'react-redux';

const Game = ({ match, game, setGame }) => {
  useEffect(() => {
    const { params } = match || {};
    const { gameId } = params || {};
    let title = '';

    switch (gameId) {
      case 'batavia-express':
        title = 'Batavia Express';
        break;
      case 'grand-hotel':
        title = 'Grand Hotel';
        break;
      case 'grand-hotel-pro':
        title = 'Grand Hotel PRO';
        break;
      case 'threevia':
        title = 'Threevia';
        break;
      case 'carnival':
        title = 'Carnival';
        break;
      default:
        title = 'Batavia Express';
        break;
    }

    setGame({ title });
  }, [setGame]);
  return null;
};

const mapStateToProps = (state) => ({
  game: state.game,
});

export default connect(mapStateToProps, { setGame })(Game);
