import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0201, door00, door0202, puzzle0201 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const DiningRoom = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Dining Room';
  const [visiblePuzzle0201, setVisiblePuzzle0201] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1412;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0201',
        shape: 'poly',
        coords: [804, 388, 974, 382, 979, 663, 797, 661],
      },
    ],
  };

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/dining-room`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/dining-room`
    ) {
      setNavigation({
        backward: {
          title: 'Hallway',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
          image: door00,
        },
        forward: {
          title: "Agatha Godsson's Room",
          locked: solves.filter((solve) => solve === '0201').length === 0,
          image: door0202,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/agatha-godsson`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0201') {
      addPuzzle({
        ...puzzlesConf.puzzle0201,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Dining Room puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0201(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0201}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0201}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Dining Room puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0201(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0201} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <br />
            <br />
            <Box textAlign="center">
              {solves.filter((puzzle) => puzzle === '0201').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <Box width="100%">
                    <CombinationLock
                      style={{ color: '#333' }}
                      combination="462"
                      height={70}
                      onMatch={() => {
                        message.success(
                          'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                        );
                        addSolve('0201');
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'notification',
                          message: {
                            type: 'success',
                            title: 'Dining Room Puzzle Solved',
                            description: `Your team member ${
                              u.name ? u.name : ''
                            } has successfully solved the puzzle in Dining room and found a clue! The door to Agatha Godsson's room has now been unlocked.`,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'solved',
                          message: {
                            puzzle: '0201',
                            channel: channelId,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'log',
                          type: 'solved',
                          message: {
                            id: socket.id,
                            name: u.name,
                            location: location,
                            channel: channelId,
                            puzzle: '0201',
                            message: 'has solved the Dining Room puzzle',
                            game: 'batavia-express',
                          },
                        });
                        addClue({
                          ...cluesConf.clue0201,
                        });
                        updatePuzzle({
                          ...puzzlesConf.puzzle0201,
                          status: 'solved',
                        });
                        setNavigation({
                          backward: {
                            title: 'Hallway',
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/fork`
                              );
                            },
                            content: door00,
                          },
                          forward: {
                            title: "Agatha Godsson's Room",
                            image: door0202,
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/agatha-godsson`
                              );
                            },
                          },
                        });
                      }}
                      openText={'Unlocked!'}
                    />
                  </Box>
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0201"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        The hint to solve the puzzle is in this room. If you
                        align the trains according to the hint in this room and
                        read in the order of the arrow placement and direction,
                        you would find the 3 digits to unlock the kitchen door
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(DiningRoom);
