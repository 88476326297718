import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import { SET_STATES, SET_STATE, ADD_STATE, ADD_STATES } from './actionTypes';

const fetchStates = (game, channel) => (dispatch) => {
  request('get', `${API_SOCKET}/states/${game}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_STATES,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));
};

const fetchState = (game, channel) => (dispatch) => {
  request('get', `${API_SOCKET}/state/${game}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_STATE,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));
};

const fetchStateByGame = (game) => (dispatch) => {
  request('get', `${API_SOCKET}/state/${game}`)
    .then((response) => {
      dispatch({
        type: SET_STATE,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));
};

const setStates = (states) => (dispatch) => {
  dispatch({
    type: SET_STATES,
    payload: states,
  });
};

const setState = (state) => (dispatch) => {
  dispatch({
    type: SET_STATE,
    payload: state,
  });
};

const addState = (state) => (dispatch) => {
  dispatch({
    type: ADD_STATE,
    payload: state,
  });
};

const addStates = (state) => (dispatch) => {
  dispatch({
    type: ADD_STATES,
    payload: state,
  });
};

export {
  fetchStates,
  fetchStateByGame,
  fetchState,
  setStates,
  setState,
  addState,
  addStates,
};
