import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path03,
  door02,
  door04,
  clue03,
  puzzle03,
  scenarioBg,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import Help from '@games/GrandHotel/fragments/Help';

const WineCellar = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addSolve,
  addStory,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Wine Cellar';
  const [visiblePuzzle03, setVisiblePuzzle03] = useState(false);
  const [visibleClue03, setVisibleClue03] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1754;
  const height = 1212;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle03',
        shape: 'poly',
        coords: [1624, 412, 1624, 1162, 1752, 1205, 1752, 400],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/wine-cellar`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/wine-cellar`
    ) {
      setNavigation({
        backward: {
          title: 'Lift',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/lift`);
          },
          image: door02,
        },
        forward: {
          title: 'Kitchen',
          locked: solves.filter((solve) => solve === '03').length === 0,
          image: door04,
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/kitchen`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle03') {
      setVisiblePuzzle03(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Room 202 puzzle',
          game: 'grand-hotel',
        },
      });
    }

    if (area.name === 'clue03') {
      setVisibleClue03(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Wine Cellar clue',
          game: 'grand-hotel',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path03}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('03');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '03',
              channel: channelId,
              game: 'grand-hotel',
              location: 'Wine Cellar',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          Lauren guided you in and stand behind you. As the gilded doors of the
          lift closes and the lift starts to ascend, you feel a violent thud at
          the back of your head with a metal object. Your vision blurs and your
          head starts to swim. You falls on you knees and then there is nothing
          but black.
        </Box>
      </Modal>
      <Modal
        visible={visibleClue03}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Wine Cellar clue',
              game: 'grand-hotel',
            },
          });
          setVisibleClue03(false);
        }}
        footer={null}
        bodyStyle={{ padding: 0 }}
      >
        <img src={clue03} alt="Clue" width="100%" />
      </Modal>
      <Modal
        visible={visiblePuzzle03}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Room 202 puzzle',
              game: 'grand-hotel',
            },
          });
          setVisiblePuzzle03(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} textAlign="center">
            <Box textAlign="center" mb={20}>
              <img src={puzzle03} alt="Puzzle" />
            </Box>
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '03').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="116"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('03');
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'WineCellar Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in the Wine Cellar! The door to Kitchen has now been unlocked.`,
                          game: 'grand-hotel',
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '03',
                          channel: channelId,
                          game: 'grand-hotel',
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '03',
                          message: 'has solved the Wine Cellar puzzle',
                          game: 'grand-hotel',
                        },
                      });

                      setNavigation({
                        backward: {
                          title: 'Lift',
                          onPress: () => {
                            history.push(`/grand-hotel/${channelId}/play/lift`);
                          },
                          content: door02,
                        },
                        forward: {
                          title: 'Kitchen',
                          image: door04,
                          onPress: () => {
                            history.push(
                              `/grand-hotel/${channelId}/play/kitchen`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="03"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />

                        <img width={300} src={clue03} alt="clue03" />
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(WineCellar);
