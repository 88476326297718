import React, { useState, useEffect } from 'react';
import { Box, Button, View } from '@common/components';
import { path09, puzzle09, door08, clue06, scenarioBg } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import { setEnd } from '@resources/End/actions';
import Help from '@games/GrandHotel/fragments/Help';
import ReactPlayer from 'react-player';
import { setAudio } from '@resources/Audio/actions';

const Outside = ({
  end,
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addSolve,
  addStory,
  setEnd,
  setAudio,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const [solved, setSolved] = useState(false);
  const location = 'Outside';
  const [visiblePuzzle06, setVisiblePuzzle06] = useState(false);
  const [visibleClue06, setVisibleClue06] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1920;
  const height = 1040;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle06',
        shape: 'circle',
        coords: [1043, 646, 30],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/map`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/map`
    ) {
      setNavigation({
        backward: {
          title: 'Lobby',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/lobby`);
          },
          image: door08,
        },
        forward: null,
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle06') {
      setVisiblePuzzle06(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Map puzzle',
          game: 'grand-hotel',
        },
      });
    }

    if (area.name === 'clue06') {
      setVisibleClue06(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Map clue',
          game: 'grand-hotel',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path09}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('09');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '09',
              channel: channelId,
              game: 'grand-hotel',
              location: 'Outside',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          Objective: Determine Lauren’s Exit Point. One time chance only. Click
          on point 103 for instruction.
        </Box>
      </Modal>
      <Modal
        visible={visibleClue06}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Map clue',
              game: 'grand-hotel',
            },
          });
          setVisibleClue06(false);
        }}
        footer={null}
      >
        <img src={clue06} alt="Clue" width="100%" />
      </Modal>
      <Modal
        visible={visiblePuzzle06}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Map puzzle',
              game: 'grand-hotel',
            },
          });
          setVisiblePuzzle06(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle09} width="100%" alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <Box textAlign="center" mt={20}>
              {!end ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  You can only make <strong>ONE ATTEMPT</strong> to guess the
                  puzzle
                  <br />
                  <br />
                  <CombinationLock
                    combination="091"
                    height={70}
                    onMatch={() => {
                      setSolved(true);
                    }}
                    openText={''}
                  />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Make the guess',
                        content: (
                          <div>
                            Are you sure you want to make the guess with the
                            numbers that you have specified? Your team only have
                            one chance to make one guess
                          </div>
                        ),
                        onOk: () => {
                          if (solved) {
                            addSolve('09');

                            doAction({
                              game: 'grand-hotel',
                              channel: socket.channel,
                              event: 'solved',
                              message: {
                                puzzle: '09',
                                channel: channelId,
                                game: 'grand-hotel',
                              },
                            });

                            doAction({
                              game: 'grand-hotel',
                              channel: socket.channel,
                              event: 'log',
                              type: 'solved',
                              message: {
                                id: socket.id,
                                name: u.name,
                                location: location,
                                channel: channelId,
                                puzzle: '09',
                                message:
                                  'has solved the Outside puzzle and solved the mystery',
                                game: 'grand-hotel',
                              },
                            });
                          }

                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'end',
                            message: {
                              channel: channelId,
                              game: 'grand-hotel',
                            },
                          });

                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: 'has made the final submission',
                              game: 'grand-hotel',
                            },
                          });

                          setEnd(true);
                        },
                      });
                    }}
                  >
                    Submit Final Combination
                  </Button>
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="09"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Lauren can reach this exit just by spending 2 bus
                        tickets and 2 taxi tickets.
                      </Box>
                    }
                  />
                </>
              ) : solves.filter((solve) => solve === '09').length > 0 ||
                solved ? (
                <>
                  <Box fontSize={20}>
                    {visiblePuzzle06 && (
                      <ReactPlayer
                        url="https://youtu.be/31tfOX_5uvA"
                        playing
                        width="100%"
                        height="500px"
                        onPlay={() => {
                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: `is watching the ${location} success`,
                              game: 'grand-hotel',
                            },
                          });
                          setAudio(false);
                        }}
                        onEnded={() => {
                          setAudio(true);
                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: `has finished watching the ${location}`,
                              game: 'grand-hotel',
                            },
                          });
                        }}
                      />
                    )}
                  </Box>
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              ) : (
                <>
                  <Box fontSize={20}>
                    {visiblePuzzle06 && (
                      <ReactPlayer
                        url="https://youtu.be/BkqrWCl_eac"
                        playing
                        width="100%"
                        height="500px"
                        onPlay={() => {
                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: `is watching the ${location} fail`,
                              game: 'grand-hotel',
                            },
                          });
                          setAudio(false);
                        }}
                        onEnded={() => {
                          setAudio(true);
                          doAction({
                            game: 'grand-hotel',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: `has finished watching the ${location} fail`,
                              game: 'grand-hotel',
                            },
                          });
                        }}
                      />
                    )}
                  </Box>
                  <br />
                  <br />
                  <CloseCircleOutlined
                    style={{
                      fontSize: 100,
                      color: '#FF0000',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  end: state.end,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
  setEnd,
  setAudio,
})(Outside);
