import React, { useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { Box, Container, View, Text, Title } from '@common/components';
import { fetchStateByGame } from '@resources/State/actions';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { addSolve, fetchSolvesByChannel } from '@resources/Solve/actions';
import { clearPuzzle, fetchPuzzlesByGame } from '@resources/Puzzle/actions';
import { GithubOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { boothBottom, boothTop } from '../assets/images';
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const Lobby = ({
  match,
  history,
  setNavigation,
  state,
  solves,
  fetchStateByGame,
  fetchSolvesByChannel,
  fetchPuzzlesByGame,
  clearPuzzle,
  puzzles,
}) => {
  const u = getCookie('CNVuser') ? JSON.parse(getCookie('CNVuser')) : {};
  const { params } = match || {};
  const { gameId, channelId } = params;
  const fetchData = async () => {
    // You can await here
    await fetchStateByGame(gameId);
    await fetchSolvesByChannel(gameId, channelId);
    // ...
  };

  useEffect(() => {
    if (u && u.name) {
      fetchData();
    } else {
      history.replace(`/carnival/${gameId}/${channelId}/user`);
    }
  }, []);

  const fetchPuzzleData = async () => {
    // You can await here
    await fetchPuzzlesByGame(gameId);
    // ...
  };

  useEffect(() => {
    clearPuzzle();
    fetchPuzzleData();
  }, []);

  useEffect(() => {
    setNavigation({
      backward: null,
      forward: null,
    });
  }, [history, match]);

  // const reducer = (accumulator, curr) => accumulator + curr;

  return (
    <View>
      <Container>
        <Box pt={100} textAlign="center">
          <Box flexDirection="row" width="100%">
            <GithubOutlined color="#FFCC00" />{' '}
            <Text display="inline-block" color="#FFCC00" html={u.name}></Text>{' '}
          </Box>

          {state && state.isStart ? (
            state.isOpen ? (
              <Box width="100%">
                {/* <Box
                  height={100}
                  backgroundImage={`url('${boothTop}')`}
                  backgroundSize="100% 100%"
                  width="100%"
                ></Box>

                <Box height={200} px={50} width="100%"></Box>
                <Box
                  height={100}
                  backgroundImage={`url('${boothBottom}')`}
                  backgroundSize="100% 100%"
                  width="100%"
                ></Box> */}
                <Row>
                  {puzzles &&
                    puzzles.map((puzzle) => (
                      <Col xs={24} md={12} lg={8}>
                        <Box
                          mb={50}
                          px={20}
                          onClick={() =>
                            history.push(
                              `/carnival/${gameId}/${channelId}/play/booth/${puzzle._id}`
                            )
                          }
                        >
                          <Box
                            height={100}
                            backgroundImage={`url('${boothTop}')`}
                            backgroundSize="100% 100%"
                            width="100%"
                          ></Box>

                          <Box
                            height={200}
                            mx="10%"
                            width="80%"
                            display="flex"
                            flexDirection="row"
                            alignItems="stretch"
                            borderWidth="0 10px"
                            borderColor="#d22235"
                            borderStyle="solid"
                          >
                            <img
                              alt="media"
                              src={puzzle.media && puzzle.media.path}
                              width="100%"
                              height="100%"
                            />
                          </Box>
                          <Box
                            height={100}
                            backgroundImage={`url('${boothBottom}')`}
                            backgroundSize="100% 100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              px="6%"
                              width="100%"
                              mt={20}
                              textAlign="center"
                            >
                              <Title
                                fontSize={18}
                                color="#FFF"
                                lineHeight="20px"
                              >
                                {puzzle.question}
                              </Title>
                            </Box>
                          </Box>
                          <Box mt={10}>
                            {solves &&
                            solves.filter(
                              (solve) =>
                                solve &&
                                solve.booth._id === puzzle._id &&
                                solve.isDeleted !== true
                            ).length > 0 ? (
                              <Text
                                fontSize={10}
                                html={`COMPLETED ${moment(
                                  solves.filter(
                                    (solve) =>
                                      solve &&
                                      solve.booth._id === puzzle._id &&
                                      solve.isDeleted !== true
                                  )[0].createdDate
                                ).fromNow()}`}
                                color="#00FF00"
                              ></Text>
                            ) : (
                              <Text
                                fontSize={10}
                                html={`INCOMPLETE`}
                                color="#FF0000"
                              ></Text>
                            )}
                          </Box>
                        </Box>
                      </Col>
                      // <Box
                      //   textAlign="left"
                      //   py={15}
                      //   onClick={() =>
                      //     history.push(
                      //       `/carnival/${gameId}/${channelId}/play/booth/${puzzle._id}`
                      //     )
                      //   }
                      // >
                      //   <Row align="middle">
                      //     <Col xs={6} sm={12} md={4}>
                      //       <Avatar
                      //         src={puzzle.media && puzzle.media.path}
                      //         size={70}
                      //       />
                      //     </Col>

                      //     <Col xs={18} sm={12} md={20}>
                      //       <Title fontSize={20} lineHeight="25px">
                      //         {puzzle.question}
                      //       </Title>
                      //       {solves &&
                      //       solves.filter(
                      //         (solve) =>
                      //           solve &&
                      //           solve.booth._id === puzzle._id &&
                      //           solve.isDeleted !== true
                      //       ).length > 0 ? (
                      //         <Text
                      //           html={`COMPLETED ${moment(
                      //             solves.filter(
                      //               (solve) =>
                      //                 solve &&
                      //                 solve.booth._id === puzzle._id &&
                      //                 solve.isDeleted !== true
                      //             )[0].createdDate
                      //           ).fromNow()}`}
                      //           color="#00FF00"
                      //         ></Text>
                      //       ) : (
                      //         <Text html={`INCOMPLETE`} color="#FF0000"></Text>
                      //       )}
                      //     </Col>
                      //   </Row>
                      //   <hr style={{ backgroundColor: '#222222' }} />
                      // </Box>
                    ))}
                </Row>
                <Box height={100}></Box>
              </Box>
            ) : (
              <>
                <Title>The game is currently closed</Title>
                <Text
                  fontSize={20}
                  html={`Please wait for the game to be open`}
                ></Text>
              </>
            )
          ) : state && state.isStart === false ? (
            <>
              <Title>The game has ended</Title>
              <Text fontSize={20} html={`Thank you for playing!`}></Text>
            </>
          ) : (
            <>
              <Title>The game has not started yet</Title>
              <Text
                fontSize={20}
                html={`Waiting for the Game Master to start the game`}
              ></Text>
              <br />
              <Spin
                indicator={antIcon}
                size={'large'}
                style={{ fontSize: 30 }}
              />
            </>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  channel: state.channel,
  state: state.state,
  solves: state.solves,
  socket: state.socket,
  puzzle: state.puzzle,
  puzzles: state.puzzles,
});

export default connect(mapStateToProps, {
  setNavigation,
  fetchStateByGame,
  doAction,
  addSolve,
  fetchSolvesByChannel,
  fetchPuzzlesByGame,
  clearPuzzle,
})(Lobby);
