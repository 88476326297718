import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Carousel, Modal } from 'antd';
import { Box } from '@common/components';

const Puzzles = ({ puzzles, navigation }) => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			{puzzles.length > 0 && (
				<Box mr={30} display="inline-block">
					<Badge count={puzzles.length}>
						<Button onClick={() => setVisible(true)}>
							Puzzles
						</Button>
					</Badge>
				</Box>
			)}
			<Modal
				visible={visible}
				width={600}
				footer={null}
				onCancel={() => {
					setVisible(false);
				}}>
				<Box height={520}>
					<Carousel dotPosition="right">
						{puzzles.map((puzzle, index) => (
							<div key={`puzzle-${index}`}>
								<Box centered textAlign="center" height={520}>
									<img
										src={puzzle.image}
										width={puzzle.width}
										height={puzzle.height}
										alt="Puzzles"
									/>
								</Box>
							</div>
						))}
					</Carousel>
				</Box>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	puzzles: state.puzzles,
});

export default connect(mapStateToProps)(Puzzles);
