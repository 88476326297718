import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0303, door0302, door00, puzzle0304 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const RadioRoom = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Radio Door';
  const [visiblePuzzle0304, setVisiblePuzzle0304] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1407;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0304',
        shape: 'poly',
        coords: [714, 457, 780, 512, 775, 688, 697, 758, 624, 688, 635, 499],
      },
    ],
  };

  if (solves.filter((solve) => solve === '0303').length === 0) {
    return (
      <Redirect to={`/batavia-express/${channelId}/play/colonel-arbuthyet`} />
    );
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/radio-room`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/radio-room`
    ) {
      setNavigation({
        backward: {
          title: "Colonel Arbuthyet's Room",
          onPress: () => {
            history.push(
              `/batavia-express/${channelId}/play/colonel-arbuthyet`
            );
          },
          image: door0302,
        },
        forward: {
          title: 'Hallway',
          locked: solves.filter((solve) => solve === '0304').length === 0,
          image: door00,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0304') {
      addPuzzle({
        ...puzzlesConf.puzzle0304,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Radio Room puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0304(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0303}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0304}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Radio Room puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0304(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0304} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <br />
            <br />
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0304').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <Box width="100%">
                    <CombinationLock
                      style={{ color: '#333' }}
                      combination="058"
                      height={70}
                      onMatch={() => {
                        message.success(
                          'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                        );
                        addSolve('0304');
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'notification',
                          message: {
                            type: 'success',
                            title: 'Radio Room Puzzle Solved',
                            description: `Your team member ${
                              u.name ? u.name : ''
                            } has successfully solved the puzzle in Radio room and found a clue! Path 03 is now completed.`,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'solved',
                          message: {
                            puzzle: '0304',
                            channel: channelId,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'log',
                          type: 'solved',
                          message: {
                            id: socket.id,
                            name: u.name,
                            location: location,
                            channel: channelId,
                            puzzle: '0304',
                            message: 'has solved the Radio Room puzzle',
                            game: 'batavia-express',
                          },
                        });
                        updatePuzzle({
                          ...puzzlesConf.puzzle0304,
                          status: 'solved',
                        });
                        addClue({
                          ...cluesConf.clue0304,
                        });
                        setNavigation({
                          backward: {
                            title: "Colonel Arbuthyet's Room",
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/colonel-arbuthyet`
                              );
                            },
                            content: door0302,
                          },
                          forward: {
                            title: 'Hallway',
                            image: door00,
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/fork`
                              );
                            },
                          },
                        });
                      }}
                      openText={'Unlocked!'}
                    />
                  </Box>
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0303"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        The different colored circles represent different
                        numbers. The sum of the five circles of the first row
                        from the top is 24, second row is 18, and so on. The sum
                        of the five circles of the first column from the left is
                        23, second column is 31, and so on. The sum of the four
                        circles in the top left is 19, top middle left is 11,
                        bottom middle right is 14, bottom right is 14, and so
                        on. Using simultaneous equation, you get the numbers
                        represented by each colored circles.
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(RadioRoom);
