import Lobby from './Lobby';
import Booth from './Detail';
import Manage from './Booth';
import Puzzle from './Booth/Form';
import Team from './Team';
import TeamForm from './Team/Form';
import Instruction from './Instruction';
import InstructionForm from './Instruction/Form';

export default {
  Lobby,
  Booth,
  Manage,
  Puzzle,
  Team,
  TeamForm,
  Instruction,
  InstructionForm,
};
