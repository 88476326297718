import {
	SET_ACTIONS,
	SET_ACTION,
	CLEAR_ACTIONS,
	CLEAR_ACTION,
	ADD_ACTION,
	UPDATE_ACTION,
	REMOVE_ACTION,
} from './actionTypes';

export const action = (state = {}, action) => {
	switch (action.type) {
		case SET_ACTION:
			return action.payload;
		case UPDATE_ACTION:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_ACTION:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_ACTIONS:
			return action.payload;
		case ADD_ACTION:
			return [...state, action.payload];
		case UPDATE_ACTION:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_ACTION:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_ACTIONS:
			return [];
		default:
			return state;
	}
};
