import React, { useContext } from 'react';
import {
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';

const Wrapper = styled.section`
    ${fontSize}
    ${fontFamily}
    ${textAlign}
    ${lineHeight}
    ${fontWeight}
    ${letterSpacing}
    ${color}
    ${space}
    ${fontStyle}
    ${flexWrap}
		${width}
		position: relative;
`;

const Section = ({ wrapperStyle, children, className, ...restProps }) => {
	const theme = useContext(ThemeContext);
	return (
		<Wrapper
			className={`fn-section ${className ? className : ''}`}
			{...theme.card}
			{...wrapperStyle}
			{...restProps}>
			{children}
		</Wrapper>
	);
};

export default Section;
