import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '@common/timelines';

import { Dashboard, User, Team, Game } from '@containers';
import { Analytics, Backward, Forward, Navbar } from '@fragments';
import BataviaExpress from '@games/BataviaExpress/screens';
import GrandHotel from '@games/GrandHotel/screens';
import GrandHotelPro from '@games/GrandHotelPro/screens';
import Threevia from '@games/Threevia/screens';
import Carnival from '@games/Carnival/screens';
import { Spectator } from '@fragments';
import BataviaExpressFragments from '@games/BataviaExpress/fragments';
import GrandHotelFragments from '@games/GrandHotel/fragments';
import GrandHotelProFragments from '@games/GrandHotelPro/fragments';
import ThreeviaFragments from '@games/Threevia/fragments';
import CarnivalFragments from '@games/Carnival/fragments';

import ScrollToTop from '@common/fragments/ScrollToTop';
import { setLocalSubscriptions } from '@common/resources/Subscription/actions';

const Base = ({ dispatch, setLocalSubscriptions, ...restProps }) => {
  let basename = '';

  return (
    <BrowserRouter basename={basename}>
      <ScrollToTop>
        <Route
          path="/batavia-express/:channelId/play"
          component={BataviaExpressFragments.Player}
          {...restProps}
        />

        <Route
          path="/grand-hotel/:channelId/play"
          component={GrandHotelFragments.Player}
          {...restProps}
        />

        <Route
          path="/grand-hotel-pro/:channelId/play"
          component={GrandHotelProFragments.Player}
          {...restProps}
        />
        <Route
          path="/threevia/:channelId/play"
          component={ThreeviaFragments.Player}
          {...restProps}
        />

        <Route
          path="/carnival/:gameId/:channelId/play"
          component={CarnivalFragments.Player}
          {...restProps}
        />
        <Route path="/" component={Analytics} {...restProps} />
        <Route path="/:gameId" component={Game} {...restProps} />
        <Navbar />

        <Route
          exact
          path="/batavia-express/:channelId/play"
          render={({ match }) => {
            const { channelId } = match.params || {};
            return <Redirect to={`/batavia-express/${channelId}/play/fork`} />;
          }}
        />

        <Route
          exact
          path="/grand-hotel/:channelId/play"
          render={({ match }) => {
            const { channelId } = match.params || {};
            return <Redirect to={`/grand-hotel/${channelId}/play/room-202`} />;
          }}
        />

        <Route
          exact
          path="/grand-hotel-pro/:channelId/play"
          render={({ match }) => {
            const { channelId } = match.params || {};
            return (
              <Redirect to={`/grand-hotel-pro/${channelId}/play/room-202`} />
            );
          }}
        />

        <Route
          render={({ location }) => {
            const { pathname, key } = location;

            return (
              <TransitionGroup component={null}>
                <Transition
                  key={key}
                  appear={true}
                  onEnter={(node, appears) => {
                    if (node) {
                      return play(pathname, node, appears);
                    }
                  }}
                  onExit={(node, appears) => {
                    if (node) {
                      return exit(node, appears);
                    }
                  }}
                  timeout={{ enter: 150, exit: 150 }}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/threevia/:channelId/game-master"
                      component={ThreeviaFragments.GameMaster}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/threevia/:channelId/manage"
                      component={Threevia.Manage}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/threevia/:channelId/puzzle"
                      component={Threevia.Puzzle}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/threevia/:channelId/puzzle/:puzzleId"
                      component={Threevia.Puzzle}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/carnival/:gameId/game-master"
                      component={CarnivalFragments.GameMaster}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/manage"
                      component={Carnival.Manage}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/teams/manage"
                      component={Carnival.Team}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/carnival/:gameId/teams"
                      component={Carnival.TeamForm}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/carnival/:gameId/teams/:teamId"
                      component={Carnival.TeamForm}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/puzzle"
                      component={Carnival.Puzzle}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/puzzle/:puzzleId"
                      component={Carnival.Puzzle}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/puzzle/:puzzleId/manage"
                      component={Carnival.Instruction}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/puzzle/:puzzleId/instructions"
                      component={Carnival.InstructionForm}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/carnival/:gameId/puzzle/:puzzleId/instructions/:instructionId"
                      component={Carnival.InstructionForm}
                      {...restProps}
                    />

                    <Route
                      path="/carnival/:gameId/team"
                      exact
                      component={Carnival.Team}
                      {...restProps}
                    />

                    <Route
                      path="/carnival/:gameId/:channelId/user"
                      exact
                      component={User}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/:gameId/:channelId/spectate"
                      component={Spectator}
                      {...restProps}
                    />

                    <Route
                      path="/:gameId/team"
                      exact
                      component={Team}
                      {...restProps}
                    />

                    <Route
                      path="/:gameId/:channelId/user"
                      exact
                      component={User}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/"
                      component={Dashboard}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/batavia-express/:channelId"
                      render={({ match }) => {
                        const { channelId } = match.params || {};
                        return (
                          <Redirect
                            to={`/batavia-express/${channelId}/play/fork`}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/intro"
                      exact
                      component={BataviaExpress.Intro}
                      {...restProps}
                    />

                    <Route
                      path="/batavia-express/:channelId/play/fork"
                      exact
                      component={BataviaExpress.Fork}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/smoking-car"
                      exact
                      component={BataviaExpress.SmokingCar}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/hector-mc-king"
                      exact
                      component={BataviaExpress.HectorMcKing}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/countess-andrenzki"
                      exact
                      component={BataviaExpress.CountessAndrenzki}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/signore-bianco"
                      exact
                      component={BataviaExpress.SignoreBianco}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/dining-room"
                      exact
                      component={BataviaExpress.DiningRoom}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/agatha-godsson"
                      exact
                      component={BataviaExpress.AgathaGodsson}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/mr-fleddoes"
                      exact
                      component={BataviaExpress.MrFleddoes}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/mary-ovenham"
                      exact
                      component={BataviaExpress.MaryOvenham}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/colonel-arbuthyet"
                      exact
                      component={BataviaExpress.ColonelArbuthyet}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/radio-room"
                      exact
                      component={BataviaExpress.RadioRoom}
                      {...restProps}
                    />
                    <Route
                      path="/batavia-express/:channelId/play/radio"
                      exact
                      component={BataviaExpress.Radio}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/grand-hotel/:channelId"
                      render={({ match }) => {
                        const { channelId } = match.params || {};
                        return (
                          <Redirect
                            to={`/grand-hotel/${channelId}/play/room-202`}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/grand-hotel/:channelId/play/intro"
                      exact
                      component={GrandHotel.Intro}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/room-202"
                      exact
                      component={GrandHotel.Room202}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/lift"
                      exact
                      component={GrandHotel.Lift}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/wine-cellar"
                      exact
                      component={GrandHotel.WineCellar}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/kitchen"
                      exact
                      component={GrandHotel.Kitchen}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/maids-quarter"
                      exact
                      component={GrandHotel.MaidsQuarter}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/ballroom"
                      exact
                      component={GrandHotel.Ballroom}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/honeymoon-suite"
                      exact
                      component={GrandHotel.HoneymoonSuite}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/lobby"
                      exact
                      component={GrandHotel.Lobby}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel/:channelId/play/outside"
                      exact
                      component={GrandHotel.Outside}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/grand-hotel-pro/:channelId"
                      render={({ match }) => {
                        const { channelId } = match.params || {};
                        return (
                          <Redirect
                            to={`/grand-hotel-pro/${channelId}/play/room-202`}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/grand-hotel-pro/:channelId/play/intro"
                      exact
                      component={GrandHotelPro.Intro}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/room-202"
                      exact
                      component={GrandHotelPro.Room202}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/lift"
                      exact
                      component={GrandHotelPro.Lift}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/wine-cellar"
                      exact
                      component={GrandHotelPro.WineCellar}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/kitchen"
                      exact
                      component={GrandHotelPro.Kitchen}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/maids-quarter"
                      exact
                      component={GrandHotelPro.MaidsQuarter}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/ballroom"
                      exact
                      component={GrandHotelPro.Ballroom}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/honeymoon-suite"
                      exact
                      component={GrandHotelPro.HoneymoonSuite}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/lobby"
                      exact
                      component={GrandHotelPro.Lobby}
                      {...restProps}
                    />

                    <Route
                      path="/grand-hotel-pro/:channelId/play/outside"
                      exact
                      component={GrandHotelPro.Outside}
                      {...restProps}
                    />

                    <Route
                      path="/threevia/:channelId/play/lobby"
                      exact
                      component={Threevia.Lobby}
                      {...restProps}
                    />

                    <Route
                      path="/carnival/:gameId/:channelId/play/lobby"
                      exact
                      component={Carnival.Lobby}
                      {...restProps}
                    />

                    <Route
                      path="/carnival/:gameId/:channelId/play/booth/:puzzleId"
                      exact
                      component={Carnival.Booth}
                      {...restProps}
                    />
                    <Route
                      path="*"
                      render={() => {
                        return <Redirect to="/" />;
                      }}
                    />
                  </Switch>
                </Transition>
              </TransitionGroup>
            );
          }}
        />
        <Backward />
        <Forward />

        <Route
          path="/:gameId"
          {...restProps}
          render={({ match }) => {
            const { gameId } = match.params || {};
            return gameId === 'grand-hotel-pro' ? (
              <GrandHotelProFragments.Footer />
            ) : gameId === 'grand-hotel' ? (
              <GrandHotelFragments.Footer />
            ) : gameId === 'threevia' || gameId === 'carnival' ? (
              ''
            ) : (
              <BataviaExpressFragments.Footer />
            );
          }}
        />
      </ScrollToTop>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { setLocalSubscriptions })(Base);
