import {
	SET_PUZZLES,
	SET_PUZZLE,
	CLEAR_PUZZLES,
	CLEAR_PUZZLE,
	ADD_PUZZLE,
	ADD_PUZZLES,
	UPDATE_PUZZLE,
	REMOVE_PUZZLE,
} from './actionTypes';

export const puzzle = (state = {}, action) => {
	switch (action.type) {
		case SET_PUZZLE:
			return action.payload;
		case UPDATE_PUZZLE:
			if (state.id.toString() !== action.payload.id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_PUZZLE:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_PUZZLES:
			return action.payload;
		case ADD_PUZZLE:
			if (
				state.filter((item) => item.id === action.payload.id).length > 0
			) {
				return [...state];
			}

			return [...state, action.payload];
		case ADD_PUZZLES:
			const c = [...state];
			action.payload.forEach((payload) => {
				if (
					state.filter((item) => item.id === payload.id).length === 0
				) {
					c.push(payload);
				}
			});

			return c;
		case UPDATE_PUZZLE:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_PUZZLE:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_PUZZLES:
			return [];
		default:
			return state;
	}
};
