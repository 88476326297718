import { puzzle01 } from '../assets/images';

export default (channelId) => {
  return {
    puzzle01: {
      order: 1,
      id: 'puzzle01',
      image: puzzle01,
      width: 324,
      height: 500,
      room: 'Room 202',
      url: `/grand-hotel/${channelId}/play/room-202`,
      clues: [],
    },
  };
};
