import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_INSTRUCTIONS,
  SET_INSTRUCTION,
  ADD_INSTRUCTION,
  ADD_INSTRUCTIONS,
  UPDATE_INSTRUCTION,
  CLEAR_INSTRUCTION,
  CLEAR_INSTRUCTIONS,
  REMOVE_INSTRUCTION,
} from './actionTypes';

const fetchInstructions = (gameId, channel) => (dispatch) => {
  return request(
    'get',
    `${API_SOCKET}/instructions/${gameId}${channel ? `/${channel}` : ''}`
  )
    .then((response) => {
      dispatch({
        type: SET_INSTRUCTIONS,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchInstructionsByPuzzle = (puzzleId) => (dispatch) => {
  return request('get', `${API_SOCKET}/instructions/puzzle/${puzzleId}`)
    .then((response) => {
      dispatch({
        type: SET_INSTRUCTIONS,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchInstructionByStage = (gameId, channel, stage) => (dispatch) => {
  return request(
    'get',
    `${API_SOCKET}/instructions/${gameId}/${channel}/${stage}`
  )
    .then((response) => {
      dispatch({
        type: SET_INSTRUCTION,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchInstruction = (id) => (dispatch) => {
  return request('get', `${API_SOCKET}/instructions/${id}`)
    .then((response) => {
      dispatch({
        type: SET_INSTRUCTION,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const setInstructions = (instructions) => (dispatch) => {
  dispatch({
    type: SET_INSTRUCTIONS,
    payload: instructions,
  });
};

const setInstruction = (puzzle) => (dispatch) => {
  dispatch({
    type: SET_INSTRUCTION,
    payload: puzzle,
  });
};

const addInstruction = (puzzle) => (dispatch) => {
  dispatch({
    type: ADD_INSTRUCTION,
    payload: puzzle,
  });
};

const updateInstruction = (puzzle) => (dispatch) => {
  dispatch({
    type: UPDATE_INSTRUCTION,
    payload: puzzle,
  });
};

const addInstructions = (puzzle) => (dispatch) => {
  dispatch({
    type: ADD_INSTRUCTIONS,
    payload: puzzle,
  });
};

const clearInstruction = () => (dispatch) => {
  dispatch({
    type: CLEAR_INSTRUCTION,
  });
};

const clearInstructions = () => (dispatch) => {
  dispatch({
    type: CLEAR_INSTRUCTIONS,
  });
};

const deleteInstruction = (id, fid) => (dispatch) =>
  request('delete', `${API_SOCKET}/instructions/${id}`)
    .then((response) => {
      dispatch({
        type: REMOVE_INSTRUCTION,
        payload: response.data,
      });

      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));

export {
  fetchInstructions,
  fetchInstruction,
  fetchInstructionByStage,
  fetchInstructionsByPuzzle,
  setInstructions,
  setInstruction,
  addInstruction,
  addInstructions,
  updateInstruction,
  clearInstruction,
  clearInstructions,
  deleteInstruction,
};
