import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { LeftCircleFilled, LockFilled } from '@ant-design/icons';
import { Box } from '@common/components';
import { Popover } from 'antd';
import { setNavigation } from '@resources/Navigation/actions';
import './assets/styles.scss';

const Backward = ({ navigation, setNavigation }) => {
	const { backward } = navigation || {};
	const { image, title, locked, onPress, dark } = backward || {};

	const content = useCallback(() => {
		return (
			<Box>
				{title && <div>{title}</div>}
				<img src={image} alt="Backward" />
			</Box>
		);
	}, [image]);

	const nav = () => {
		return locked ? (
			<LockFilled style={{ color: dark ? '#333' : '#FFF' }} />
		) : (
			<LeftCircleFilled
				style={{ fontSize: 50, color: dark ? '#333' : '#FFF' }}
				onClick={() => {
					setNavigation({ forward: null, backward: null });
					onPress && onPress();
				}}
			/>
		);
	};

	return backward ? (
		<Box className="hs-navigation-backward">
			{image ? (
				<Popover content={content()} placement="right" trigger="hover">
					{nav()}
				</Popover>
			) : (
				nav()
			)}
		</Box>
	) : null;
};

const mapStateToProps = (state) => ({
	navigation: state.navigation,
});

export default connect(mapStateToProps, { setNavigation })(Backward);
