import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0104, door0103, door00, puzzle0104 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { Redirect } from 'react-router-dom';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const SignoreBianco = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Signore Bianco Room';
  const [visiblePuzzle0104, setVisiblePuzzle0104] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1424;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0104',
        shape: 'poly',
        coords: [39, 825, 275, 815, 293, 950, 47, 975],
      },
    ],
  };

  if (solves.filter((solve) => solve === '0103').length === 0) {
    return (
      <Redirect to={`/batavia-express/${channelId}/play/countess-andrenzki`} />
    );
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/signore-bianco`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/signore-bianco`
    ) {
      setNavigation({
        backward: {
          title: "Countess Andrenzki's Room",
          onPress: () => {
            history.push(
              `/batavia-express/${channelId}/play/countess-andrenzki`
            );
          },
          image: door0103,
        },
        forward: {
          title: 'Hallway',
          locked: solves.filter((solve) => solve === '0104').length === 0,
          image: door00,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0104') {
      addPuzzle({
        ...puzzlesConf.puzzle0104,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Signore Bianco puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0104(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0104}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0104}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Signore Bianco puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0104(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12}>
            <img src={puzzle0104} alt="Puzzle" />
          </Col>
          <Col xs={12}>
            <Box centered height={500} textAlign="center">
              {solves.filter((solve) => solve === '0104').length === 0 ? (
                <>
                  You need to solve the puzzle to the left and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="579"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                      );
                      addSolve('0104');
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Signore Bianco Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in Signore Bianco's room and found a clue! Path 01 is now completed.`,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0104',
                          channel: channelId,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0104',
                          message: 'has solved the Signore Bianco puzzle',
                          game: 'batavia-express',
                        },
                      });
                      addClue({
                        ...cluesConf.clue0104,
                      });
                      updatePuzzle({
                        ...puzzlesConf.puzzle0104,
                        status: 'solved',
                      });
                      setNavigation({
                        backward: {
                          title: "Countess Andrenzki's Room",
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/smoking-car`
                            );
                          },
                          content: door0103,
                        },
                        forward: {
                          title: 'Hallway',
                          image: door00,
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/countess-andrenzki`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0104"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Based on the article clippings, you would find items in
                        the other previous rooms that are related to the title
                        of the articles.
                        <br />
                        <br />
                        Take note of the numbers around the items as they are
                        needed to unlock the box
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(SignoreBianco);
