import React from 'react';
import { Affix, Col, Row } from 'antd';
import { connect } from 'react-redux';
import Notebook from './items/Notebook/Notebook';
import Clues from './items/Clues/Clues';
import Puzzles from './items/Puzzles/Puzzles';
import ReactAudioPlayer from 'react-audio-player';
import exhale from '@games/BataviaExpress/assets/audio/exhale.mp3';
import { getCookie } from '@common/utils/cookie';
import { SoundFilled, SoundOutlined } from '@ant-design/icons';
import { setAudio } from '@resources/Audio/actions';
import { Box } from '@common/components';

const Navbar = ({ audio, setAudio, ...restProps }) => {
	return (
		<Affix
			style={{
				position: 'fixed',
				bottom: 0,
				zIndex: 100,
				width: '100%',
				backgroundColor: 'rgba(0,0,0,0.5)',
			}}>
			<Row align="middle">
				<Col xs={12}>
					{getCookie('user') && <Notebook {...restProps} />}
				</Col>
				<Col xs={12} align="right">
					<Puzzles /> <Clues />{' '}
					<Box
						onClick={() => {
							setAudio(!audio);
						}}
						display="inline-block"
						justifyContent="center"
						align="middle"
						pt={12}
						textAlign="center"
						width={50}
						height={50}
						borderRadius={25}
						backgroundColor="#333"
						mr={20}>
						{audio ? (
							<SoundFilled style={{ fontSize: 25 }} />
						) : (
							<SoundOutlined style={{ fontSize: 25 }} />
						)}
					</Box>
					{audio && (
						<ReactAudioPlayer
							src={exhale}
							autoPlay
							loop
							volume={0.1}
						/>
					)}
				</Col>
			</Row>
		</Affix>
	);
};

const mapStateToProps = (state) => {
	return {
		audio: state.audio,
	};
};

export default connect(mapStateToProps, { setAudio })(Navbar);
