import React, { useEffect } from 'react';
import { Box, Title, View } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { Avatar, Button, Space, Table, Modal } from 'antd';
import {
  addPuzzle,
  fetchPuzzles,
  fetchPuzzle,
  clearPuzzle,
} from '@resources/Puzzle/actions';
import {
  deleteInstruction,
  clearInstructions,
  fetchInstructionsByPuzzle,
} from '@resources/Instruction/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const Manage = ({
  instructions,
  match,
  history,
  clearPuzzle,
  clearInstructions,
  fetchInstructionsByPuzzle,
  puzzle,
  fetchPuzzle,
  deleteInstruction,
}) => {
  const { params } = match || {};
  const { gameId, puzzleId } = params;

  const fetchData = async () => {
    await clearPuzzle();
    await fetchPuzzle(puzzleId);
    await clearInstructions();
    await fetchInstructionsByPuzzle(puzzleId);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 50,
      render: (text) => text,
    },
    {
      title: 'Image',
      dataIndex: 'media',
      key: 'media',
      width: 50,
      render: (text) => {
        return text && text.path ? <Avatar src={text.path} size={100} /> : null;
      },
    },
    {
      title: 'Instruction',
      dataIndex: 'instruction',
      key: 'instruction',
    },

    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button
            size="small"
            onClick={() => {
              history.push(
                `/carnival/${gameId}/puzzle/${puzzleId}/instructions/${record._id}`
              );
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={async () => {
              Modal.confirm({
                title: 'Delete Instruction',
                content: 'Are you sure you want to delete this instruction?',
                onOk: async () => {
                  await deleteInstruction(record._id);
                  fetchData();
                },
              });
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Title>Booth - {puzzle && puzzle.question}</Title>
        <Button onClick={() => history.push(`/carnival/${gameId}/manage`)}>
          Back to Booth
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() =>
            history.push(`/carnival/${gameId}/puzzle/${puzzleId}/instructions`)
          }
          type="primary"
        >
          + Add Instruction
        </Button>
        <Table columns={columns} dataSource={instructions} />
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  instructions: state.instructions,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  puzzle: state.puzzle,
});

export default connect(mapStateToProps, {
  clearPuzzle,
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
  fetchPuzzles,
  clearInstructions,
  fetchPuzzle,
  fetchInstructionsByPuzzle,
  deleteInstruction,
})(Manage);
