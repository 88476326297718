import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path0401,
  door00,
  solutionFail,
  solutionSuccess,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal } from 'antd';
import CombinationLock from 'combination-lock-react';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { PhoneFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { Redirect } from 'react-router-dom';
import { addSolve } from '@resources/Solve/actions';
import { setEnd } from '@resources/End/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const RadioRoom = ({
  end,
  setNavigation,
  solves,
  socket,
  match,
  history,
  doAction,
  addSolve,
  updateMember,
  clues,
  setEnd,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const [solved, setSolved] = useState(false);
  const location = 'Radio Room';
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1369;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [],
  };

  if (
    solves.filter(
      (solve) =>
        solve === '0102' ||
        solve === '0103' ||
        solve === '0104' ||
        solve === '0201' ||
        solve === '0202' ||
        solve === '0203' ||
        solve === '0301' ||
        solve === '0302' ||
        solve === '0303' ||
        solve === '0304'
    ).length < 10
  ) {
    return <Redirect to={`/batavia-express/${channelId}/play/fork`} />;
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/batavia-express/${channelId}/play/radio`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/batavia-express/${channelId}/play/radio`
    ) {
      setNavigation({
        backward: {
          title: 'Hallway',
          dark: true,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
          image: door00,
        },
        forward: null,
      });
    }
  }, [clues, solves, history]);

  const handleClick = () => {};

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0401}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
        <Box
          position="absolute"
          width="100%"
          height="100%"
          centered
          top={0}
          zIndex={100}
        >
          {!end ? (
            <>
              <Box textAlign="center" width={500}>
                <span
                  style={{
                    backgroundColor: '#000',
                    padding: '5px',
                  }}
                >
                  You have one chance to make a call. Use the clues that you
                  found to dial the phone. So be very careful in dialing the 3
                  numbers to the correct numbers before you click on the call
                  button to the right of the numbers.
                </span>
              </Box>
              <br />
              <Row>
                <Col>
                  <CombinationLock
                    combination="626"
                    height={70}
                    onMatch={() => {
                      setSolved(true);
                    }}
                    openText={''}
                  />
                </Col>
                <Col>
                  <PhoneFilled
                    style={{
                      fontSize: 120,
                      marginLeft: 20,
                      cursor: 'pointer',
                      color: '#CCC',
                    }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Make the call',
                        content: (
                          <div>
                            Are you sure you want to make the call with the
                            numbers that you have specified? Your team only have
                            one chance to make one call
                          </div>
                        ),
                        onOk: () => {
                          if (solved) {
                            addSolve('0401');

                            doAction({
                              game: 'batavia-express',
                              channel: socket.channel,
                              event: 'solved',
                              message: {
                                puzzle: '0401',
                                channel: channelId,
                                game: 'batavia-express',
                              },
                            });

                            doAction({
                              game: 'batavia-express',
                              channel: socket.channel,
                              event: 'log',
                              type: 'solved',
                              message: {
                                id: socket.id,
                                name: u.name,
                                location: location,
                                channel: channelId,
                                puzzle: '0401',
                                message:
                                  'has solved the Radio puzzle and solved the mystery',
                                game: 'batavia-express',
                              },
                            });
                          }

                          doAction({
                            game: 'batavia-express',
                            channel: socket.channel,
                            event: 'end',
                            message: {
                              channel: channelId,
                              game: 'batavia-express',
                            },
                          });

                          doAction({
                            game: 'batavia-express',
                            channel: socket.channel,
                            event: 'log',
                            message: {
                              id: socket.id,
                              name: u.name,
                              location: location,
                              channel: channelId,
                              message: 'has made the final call',
                              game: 'batavia-express',
                            },
                          });

                          setEnd(true);
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Help
                location={location}
                puzzle="0401"
                channel={channelId}
                solution={
                  <Box>
                    <span
                      style={{
                        backgroundColor: '#333',
                        padding: 10,
                      }}
                    >
                      <strong>Hint</strong>
                      <br />
                      Colonel Arbuthyet was in the dining car and lost a star
                      from his uniform there. So Agatha Godsson was not dining
                      at the time of the crime.
                      <br />
                      Two of the three poker players were also men. Number three
                      must be a woman, as indicated by the lipstick on the
                      glass.
                      <br />
                      MR. Fleddoes was visited by a woman with the initials
                      "M.O" and claims that he never drank alcohol. But there
                      were three whiskey glasses on the table. So who is "M.O"?
                    </span>
                  </Box>
                }
              />
            </>
          ) : (
            <>
              <Row gutter={24}>
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <img
                    src={
                      solves.filter((solve) => solve === '0401').length === 0
                        ? solutionFail
                        : solutionSuccess
                    }
                    alt="Puzzle"
                  />
                </Col>
                <Col xs={12}>
                  <Box centered height={500} textAlign="center">
                    <span
                      style={{
                        backgroundColor: '#000',
                        padding: '10px',
                      }}
                    >
                      Your team member has made the call.
                      <br />
                      Tell your answer or send a screenshot of the clue to the
                      left to the host
                    </span>
                  </Box>
                </Col>
              </Row>
            </>
          )}
        </Box>
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  end: state.end,
});

export default connect(mapStateToProps, {
  doAction,
  setNavigation,
  updateMember,
  addSolve,
  setEnd,
})(RadioRoom);
