import {
	SET_MEMBERS,
	ADD_MEMBER,
	UPDATE_MEMBER,
	REMOVE_MEMBER,
	UPDATE_MEMBERS,
} from './actionTypes';

const setMembers = (member) => (dispatch) => {
	dispatch({
		type: SET_MEMBERS,
		payload: member,
	});
};

const updateMembers = (member) => (dispatch) => {
	dispatch({
		type: UPDATE_MEMBERS,
		payload: member,
	});
};

const addMember = (member) => (dispatch) => {
	dispatch({
		type: ADD_MEMBER,
		payload: member,
	});
};

const updateMember = (member) => (dispatch) => {
	dispatch({
		type: UPDATE_MEMBER,
		payload: member,
	});
};

const removeMember = (member) => (dispatch) => {
	dispatch({
		type: REMOVE_MEMBER,
		payload: member,
	});
};

export { setMembers, updateMembers, addMember, updateMember, removeMember };
