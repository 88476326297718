import React, { useState, useEffect } from 'react';
import { Box, View, Title } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { Button, Input, Form, InputNumber } from 'antd';
import { fetchPuzzle, clearPuzzle } from '@resources/Puzzle/actions';
import {
  fetchInstructions,
  fetchInstruction,
  clearInstruction,
} from '@resources/Instruction/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { isEmpty } from 'lodash';
import { BasicUploadField } from '@components';
const FormItem = Form.Item;

const Instruction = ({
  match,
  history,
  fetchInstruction,
  puzzle,
  doAction,
  clearInstruction,
  instruction,
  fetchPuzzle,
  clearPuzzle,
}) => {
  const { params } = match || {};
  const { gameId, puzzleId, instructionId } = params;
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState(
    puzzle && puzzle.media && puzzle.media.originalName
      ? [
          {
            uid: 'media',
            name: puzzle.media.originalName,
            url: puzzle.media.path,
            response: { data: [puzzle.media] },
          },
        ]
      : []
  );

  const fetchData = async () => {
    await clearPuzzle();
    puzzleId && (await fetchPuzzle(puzzleId));
    await clearInstruction();
    instructionId && (await fetchInstruction(instructionId));
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);

    return () => {
      clearInstruction();
    };
  }, []);

  useEffect(() => {
    instruction && instruction.media && instruction.media.originalName
      ? setMedia([
          {
            uid: 'media',
            name: instruction.media.originalName,
            url: instruction.media.path,
            response: { data: [instruction.media] },
          },
        ])
      : setMedia([]);
  }, [instruction]);

  const handleFinish = async (values) => {
    await doAction({
      game: gameId,
      event: 'instruction',
      action: instructionId ? 'update' : 'create',
      id: instructionId,
      message: {
        game: gameId,
        puzzle: puzzleId,
        link: values.link,
        order: values.order,
        instruction: values.instruction,
        media:
          media && media.length > 0
            ? media
                .filter(
                  (med) => med.response.data && med.response.data.length > 0
                )
                .map((med) => med.response.data[0]._id)
            : undefined,
      },
    });

    history.push(`/carnival/${gameId}/puzzle/${puzzleId}/manage`);
  };

  return (
    <View>
      <Box maxWidth={500} minWidth={`40%`} py={100} ml={`30%`}>
        {!loading && (!instructionId || !isEmpty(instruction)) && (
          <Form
            layout="vertical"
            name="access-form"
            onFinish={handleFinish}
            initialValues={instruction}
          >
            <Title>Enter your Instruction</Title>
            <BasicUploadField
              fieldOptions={{
                name: 'media',
                label: 'Media Instruction',
                rules: [],
              }}
              folder="/media/instruction"
              uploadOptions={{
                className: 'full-width',
                beforeUpload: (file) => {
                  return true;
                },
              }}
              source={media}
              setSource={setMedia}
              text="Upload"
            />
            <FormItem
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: 'Please enter your order',
                },
              ]}
            >
              <InputNumber />
            </FormItem>
            <FormItem label="Instruction" name="instruction" rules={[]}>
              <Input />
            </FormItem>

            <FormItem label="Link" name="link" rules={[]}>
              <Input />
            </FormItem>

            <FormItem>
              <Button
                className="login-form-button"
                onClick={() => {
                  history.push(`/carnival/${gameId}/puzzle/${puzzleId}/manage`);
                }}
              >
                {`Cancel`}
              </Button>
              &nbsp;&nbsp;
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {`Submit Instruction`}
              </Button>
            </FormItem>
          </Form>
        )}
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  puzzle: state.puzzle,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  state: state.state,
  instruction: state.instruction,
});

export default connect(mapStateToProps, {
  doAction,
  setNavigation,
  updateMember,
  fetchInstructions,
  fetchInstruction,
  clearInstruction,
  fetchPuzzle,
  clearPuzzle,
})(Instruction);
