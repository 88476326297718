import Pusher from 'pusher-js';
import { HOPESGINDO_PUSHER_KEY, HOPESGINDO_PUSHER_CLUSTER } from '@config/app';
import { API_SOCKET } from '@config/api';

const WebSocket = ({
	game,
	channel,
	name,
	role,
	events = [],
	onSubscribed,
	onMemberAdded,
	onMemberRemoved,
}) => {
	const pusher = new Pusher(HOPESGINDO_PUSHER_KEY, {
		cluster: HOPESGINDO_PUSHER_CLUSTER,
		encrypted: true,
		authEndpoint: `${API_SOCKET}/auth/pusher/${role.toLowerCase()}`,
	});

	var presenceChannel = pusher.subscribe(`presence-${game}-${channel}`, {
		name,
		role,
	});

	presenceChannel.bind('pusher:subscription_succeeded', (member) => {
		onSubscribed && onSubscribed(member);
	});

	onMemberAdded &&
		presenceChannel.bind('pusher:member_added', function(member) {
			onMemberAdded && onMemberAdded(member);
		});

	presenceChannel.bind('pusher:member_removed', function(member) {
		onMemberRemoved && onMemberRemoved(member);
	});

	events.forEach(({ title, callback }) => {
		presenceChannel.bind(title, callback);
	});

	return { pusher, channel: `presence-${game}-${channel}`, presenceChannel };
};

export default WebSocket;
