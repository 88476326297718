import React, { useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0101, door00, door0102 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { getCookie } from '@common/utils/cookie';
import { doAction } from '@resources/Action/actions';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';

const SmokingCar = ({
  socket,
  clues,
  setNavigation,
  match,
  history,
  updateMember,
  doAction,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Smoking Car';
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1393;
  const solvedPuzzles = getCookie('solved')
    ? JSON.parse(getCookie('solved'))
    : [];

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/smoking-car`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/smoking-car`
    ) {
      setNavigation({
        backward: {
          title: 'Hallway',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
          image: door00,
        },
        forward: {
          title: "Hector McKing's Room",
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/hector-mc-king`);
          },
          image: door0102,
        },
      });
    }
  }, [clues, solvedPuzzles, history]);

  return (
    <View>
      <Box textAlign="center" pb={70}>
        <ImageMapper
          src={path0101}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
        />
      </Box>
    </View>
  );
};
const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
});

export default connect(mapStateToProps, {
  setNavigation,
  updateMember,
  doAction,
})(SmokingCar);
