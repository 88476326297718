import actions, { action } from '@resources/Action/reducers';
import audios, { audio } from '@resources/Audio/reducers';
import clues, { clue } from '@resources/Clue/reducers';
import ends, { end } from '@resources/End/reducers';
import games, { game } from '@resources/Game/reducers';
import helps, { help } from '@resources/Help/reducers';
import instructions, { instruction } from '@resources/Instruction/reducers';
import logs, { log } from '@resources/Log/reducers';
import members, { member } from '@resources/Member/reducers';
import navigations, { navigation } from '@resources/Navigation/reducers';
import puzzles, { puzzle } from '@resources/Puzzle/reducers';
import sockets, { socket } from '@resources/Socket/reducers';
import solves, { solve } from '@resources/Solve/reducers';
import states, { state } from '@resources/State/reducers';
import stories, { story } from '@resources/Story/reducers';
import teams, { team } from '@resources/Team/reducers';
import users, { user } from '@resources/User/reducers';

export const reducers = {
  audios,
  audio,
  actions,
  action,
  clues,
  clue,
  ends,
  end,
  games,
  game,
  helps,
  help,
  instructions,
  instruction,
  logs,
  log,
  members,
  member,
  navigations,
  navigation,
  puzzles,
  puzzle,
  sockets,
  socket,
  solves,
  solve,
  states,
  state,
  stories,
  story,
  teams,
  team,
  users,
  user,
};
