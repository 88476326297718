import {
	SET_CLUES,
	SET_CLUE,
	CLEAR_CLUES,
	CLEAR_CLUE,
	ADD_CLUE,
	ADD_CLUES,
	UPDATE_CLUE,
	REMOVE_CLUE,
} from './actionTypes';

export const clue = (state = {}, action) => {
	switch (action.type) {
		case SET_CLUE:
			return action.payload;
		case UPDATE_CLUE:
			if (state.id.toString() !== action.payload.id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_CLUE:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_CLUES:
			return action.payload;
		case ADD_CLUE:
			if (
				state.filter((item) => item.id === action.payload.id).length > 0
			) {
				return [...state];
			}

			return [...state, action.payload];
		case ADD_CLUES:
			const s = [...state];
			action.payload.forEach((payload) => {
				if (
					state.filter((item) => item.id === payload.id).length === 0
				) {
					s.push(payload);
				}
			});

			return s;

		case UPDATE_CLUE:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_CLUE:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_CLUES:
			return [];
		default:
			return state;
	}
};
