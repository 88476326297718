import React, { useEffect, useState } from 'react';
import WebSocket from '@services/WebSocket';
import { connect } from 'react-redux';
import { USER_ROLE } from '@config/app';
import { setTeam } from '@resources/Team/actions';
import { addClue, addClues } from '@resources/Clue/actions';
import { addLog } from '@resources/Log/actions';
import { addPuzzle, addPuzzles } from '@resources/Puzzle/actions';
import { setSocket } from '@resources/Socket/actions';
import {
  addMember,
  removeMember,
  updateMember,
} from '@resources/Member/actions';
import { fetchTeamsByGame } from '@resources/Team/actions';
import { setUser } from '@resources/User/actions';
import { doAction } from '@resources/Action/actions';

import { fetchStateByGame, setState } from '@resources/State/actions';
import moment from 'moment';
import { Box, Title, View } from '@common/components';
import { Col, Row, Button, notification, Table } from 'antd';

import {
  fetchSolvesByGame,
  addSolve,
  updateSolve,
  fetchSolvesFull,
} from '@resources/Solve/actions';
import { addHelp, fetchHelps } from '@resources/Help/actions';
import { fetchPuzzlesByGame } from '@resources/Puzzle/actions';

const GameMaster = ({
  puzzles,
  solves,
  match,
  history,
  removeMember,
  updateMember,
  addMember,
  addLog,
  addSolve,
  doAction,
  state,
  setState,
  updateSolve,
  setSocket,
  members,
  fetchPuzzlesByGame,
  fetchTeamsByGame,
  teams,
  fetchSolvesByGame,
  fetchStateByGame,
}) => {
  const { params } = match || {};
  const { gameId, channelId } = params || {};
  const [chan, setChan] = useState();

  const [mem, showMem] = useState(true);

  const fetchData = async () => {
    await fetchStateByGame(gameId);
    await fetchTeamsByGame(gameId);
    await fetchPuzzlesByGame(gameId);
    await fetchSolvesByGame(gameId);
  };

  const fetchPuzzle = async (state) => {
    // await fetchPuzzlesByGame(gameId, channelId, state.stage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    state && fetchPuzzle(state);
  }, [state]);

  useEffect(() => {
    let uid = null;
    const { pusher, channel } = WebSocket({
      game: gameId,
      channel: 'universal',
      role: USER_ROLE.SPECTATOR,
      onSubscribed: (member) => {
        uid = member.me.id;
        for (var id in member.members) {
          const m = member.members[id];
          if (m.role === 'player') {
            addMember({ id, name: m.name, info: member.info });
          }
        }
        setSocket({ id: uid, channel });

        setChan(channel);
      },
      onMemberAdded: (member) => {
        if (member.info.role === 'player') {
          // addLog({
          // 	id: moment().format('DDMMYYYYHHmmss'),
          // 	date: moment(),
          // 	name: member.info.name,
          // 	member: member.id,
          // 	message: `has joined the game`,
          // });
          addMember({
            id: member.id,
            name: member.info.name,
            info: member.info,
          });
        }
      },
      onMemberRemoved: (member) => {
        if (member.info.role === 'player') {
          addLog({
            id: moment().format('DDMMYYYYHHmmss'),
            name: member.info.name,
            member: member.id,
            date: moment(),
            message: `has left the game`,
          });

          removeMember(member);
        }
      },
      events: [
        // {
        // 	title: 'member',
        // 	callback: (data) => {
        // 		updateMember(data);
        // 		data.location &&
        // 			addLog({
        // 				...data,
        // 				id: moment().format('DDMMYYYYHHmmss'),
        // 				date: moment(),
        // 				message: `has moved to ${data.location}`,
        // 			});
        // 	},
        // },
        {
          title: 'log',
          callback: (data) => {
            addLog({
              ...data,
              id: `${data.id}.${moment().format('DDMMYYYYHHmmss')}`,
              date: moment(),
            });

            updateMember(data);
          },
        },
        {
          title: 'notification',
          callback: (notif) => {
            // console.log('SOCKET NOTIFICATION', notif);
            notification[notif.type]({
              message: notif.title,
              description: notif.description,
            });
          },
        },
        {
          title: 'solved',
          callback: async () => {
            await fetchSolvesByGame(gameId);
          },
        },

        {
          title: 'state',
          callback: (state) => {
            setState(state);
          },
        },
      ],
    });

    window.addEventListener('beforeunload', () => {
      pusher.unsubscribe(channel);
    });
  }, [history]);

  // useEffect(() => {
  //   stage = 0;
  // }, [solves, members, logs, state]);

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Row gutter={24}>
          <Col xs={24} md={mem ? 18 : 24} lg={mem ? 18 : 24}>
            <Row align="middle">
              <Col xs={12}>
                <Title mb={0}>Game Master </Title>
              </Col>
              <Col xs={12}>
                <Row align="middle" gutter={24}>
                  <Col>
                    <Button
                      size="small"
                      onClick={() => {
                        history.push(`/carnival/${gameId}/manage`);
                      }}
                    >
                      Manage Game
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size="small"
                      onClick={() => {
                        history.push(`/carnival/${gameId}/teams/manage`);
                      }}
                    >
                      Manage Team
                    </Button>
                  </Col>

                  <Col>
                    {mem ? (
                      <Button
                        size="small"
                        onClick={() => {
                          showMem(false);
                        }}
                      >
                        Hide participants
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        onClick={() => {
                          showMem(true);
                        }}
                      >
                        Show participants ({members.length})
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            {!state || (state && !state.isStart) ? (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  doAction({
                    action: state ? 'update' : 'create',
                    game: gameId,
                    channel: chan,
                    event: 'state',

                    id: state ? state._id : undefined,
                    message: {
                      channel: channelId,
                      game: gameId,
                      isStart: true,
                      isOpen: false,
                    },
                  });
                }}
              >
                Start Game
              </Button>
            ) : (
              <Button
                type="danger"
                size="small"
                onClick={() => {
                  doAction({
                    action: 'update',
                    game: gameId,
                    channel: chan,
                    event: 'state',
                    id: state._id,
                    message: {
                      channel: channelId,
                      game: gameId,
                      isStart: false,
                      isOpen: false,
                    },
                  });
                }}
              >
                End Game
              </Button>
            )}
            &nbsp;&nbsp;
            {state && state.isStart && !state.isOpen ? (
              <Button
                size="small"
                onClick={() => {
                  doAction({
                    action: 'update',
                    game: gameId,
                    channel: chan,
                    event: 'state',
                    id: state._id,
                    message: {
                      channel: channelId,
                      game: gameId,
                      isStart: true,
                      isOpen: true,
                    },
                  });
                }}
              >
                Open Game
              </Button>
            ) : state && state.isStart && state.isOpen ? (
              <Button
                type="danger"
                size="small"
                onClick={() => {
                  doAction({
                    action: 'update',
                    game: gameId,
                    channel: chan,
                    event: 'state',
                    id: state._id,
                    message: {
                      channel: channelId,
                      game: gameId,
                      isStart: true,
                      isOpen: false,
                    },
                  });
                }}
              >
                Close Game
              </Button>
            ) : null}
            <br />
            <Table
              columns={[
                {
                  fixed: 'left',
                  width: 100,
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                ...(puzzles
                  ? puzzles.map((puzzle) => ({
                      title: puzzle.question,
                      dataIndex: 'name',
                      key: puzzle._id,
                      render: (text, record) => {
                        const solved =
                          solves &&
                          solves.filter((solve) => {
                            return (
                              solve.channel === text &&
                              solve.booth._id === puzzle._id &&
                              solve.isDeleted !== true
                            );
                          });

                        console.log(solved);

                        return solved.length > 0 ? (
                          <>
                            SOLVED!
                            <br />
                            {moment(solved[0].createdDate).fromNow()}
                            <br />
                            <Button
                              type="danger"
                              size="small"
                              onClick={() => {
                                doAction({
                                  action: 'update',
                                  game: gameId,
                                  channel: chan,
                                  event: 'solved',
                                  id: solved[0]._id,
                                  message: {
                                    isDeleted: true,
                                  },
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="small"
                            onClick={() => {
                              doAction({
                                action: 'create',
                                game: gameId,
                                channel: chan,
                                event: 'solved',
                                message: {
                                  channel: record.name,
                                  game: gameId,
                                  booth: puzzle._id,
                                  isDeleted: false,
                                },
                              });
                            }}
                          >
                            Solved
                          </Button>
                        );
                      },
                    }))
                  : []),
              ]}
              dataSource={teams.map((team) => {
                return {
                  name: team.name,
                  key: team.id,
                };
              })}
              pagination={false}
              width="100%"
              scroll={{ x: teams ? teams.length * 300 + 100 : 1000 }}
            />
            <br />
          </Col>
          {mem && (
            <Col>
              <Title fontSize={30}>Participants</Title>
              <Table
                columns={[{ title: 'Name', dataIndex: 'name', key: 'name' }]}
                dataSource={members.map((member) => {
                  return {
                    name: member.name,
                    location: member.location,
                    key: member.id,
                  };
                })}
                pagination={false}
                width="100%"
              />
            </Col>
          )}
        </Row>

        <br />
        <br />
      </Box>

      <Box height={100}></Box>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clues: state.clues,
    logs: state.logs,
    members: state.members,
    state: state.state,
    solves: state.solves,
    helps: state.helps,
    puzzle: state.puzzle,
    teams: state.teams,
    puzzles: state.puzzles,
  };
};

export default connect(mapStateToProps, {
  addMember,
  addPuzzle,
  addPuzzles,
  removeMember,
  updateMember,
  setTeam,
  setUser,
  doAction,
  setSocket,
  addClue,
  addClues,
  addLog,
  fetchSolvesFull,
  fetchSolvesByGame,
  fetchHelps,
  fetchStateByGame,
  addSolve,
  addHelp,
  setState,
  updateSolve,
  fetchPuzzlesByGame,
  fetchTeamsByGame,
})(GameMaster);
