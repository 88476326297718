import React, { useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { Box, Container, View, Text, Title } from '@common/components';
import { fetchStateByGame } from '@resources/State/actions';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { addSolve, fetchSolves } from '@resources/Solve/actions';
import { clearPuzzle, fetchPuzzle } from '@resources/Puzzle/actions';
import {
  clearInstructions,
  fetchInstructionsByPuzzle,
} from '@resources/Instruction/actions';
import { fetchTeamByName } from '@resources/Team/actions';
import {
  GithubOutlined,
  LeftCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';

const Lobby = ({
  match,
  history,
  setNavigation,
  solves,
  fetchStateByGame,
  fetchPuzzle,
  clearPuzzle,
  puzzle,
  instructions,
  clearInstructions,
  fetchInstructionsByPuzzle,
  fetchTeamByName,
  team,
}) => {
  const u = getCookie('CNVuser') ? JSON.parse(getCookie('CNVuser')) : {};
  const { params } = match || {};
  const { gameId, channelId, puzzleId } = params;
  const fetchData = async () => {
    // You can await here
    await clearInstructions();
    await fetchStateByGame(gameId);
    await fetchInstructionsByPuzzle(puzzleId);

    // ...
  };

  useEffect(() => {
    if (u && u.name) {
      fetchData();
    } else {
      history.replace(`/carnival/${gameId}/${channelId}/user`);
    }
  }, []);

  const fetchPuzzleData = async () => {
    // You can await here
    await fetchPuzzle(puzzleId);
    await fetchTeamByName(channelId);
    // ...
  };

  useEffect(() => {
    clearPuzzle();
    fetchPuzzleData();
  }, []);

  useEffect(() => {
    setNavigation({
      backward: null,
      forward: null,
    });
  }, [history, match]);

  // const reducer = (accumulator, curr) => accumulator + curr;

  return (
    <View>
      <Container>
        <Box centered textAlign="center">
          <Box flexDirection="row" width="100%">
            <GithubOutlined color="#FFCC00" />{' '}
            <Text display="inline-block" color="#FFCC00" html={u.name}></Text>{' '}
          </Box>

          <Box
            textAlign="left"
            width="100%"
            onClick={() =>
              history.push(`/carnival/${gameId}/${channelId}/play/lobby`)
            }
          >
            <Row>
              <Col xs={12}>
                <LeftCircleOutlined /> Back to Lobby
              </Col>
              <Col xs={12}>
                <Box textAlign="right" width="100%"></Box>
              </Col>
            </Row>
          </Box>
          <Box>
            <Title>{puzzle.question}</Title>
            {puzzle.description}
          </Box>

          <Box mt={50}>
            Scroll down for instructions
            <br />
            <DownCircleOutlined />
          </Box>
        </Box>

        <Box mt={50}>
          <Title fontSize={25}>What you need to do</Title>
          {instructions &&
            instructions.map((instruction) => (
              <Box my={30}>
                {instruction.media && instruction.media.path && (
                  <img
                    alt="Instruction"
                    src={instruction.media.path}
                    width="100%"
                  />
                )}
                {instruction.instruction && (
                  <Box my={15}>{instruction.instruction}</Box>
                )}

                {instruction.link && (
                  <Box my={15}>
                    <a
                      href={instruction.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {instruction.link}
                    </a>
                  </Box>
                )}
              </Box>
            ))}

          <Box>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => {
                window.open(team.whatsapp, '_blank');
              }}
            >
              Submit Answer
            </Button>
          </Box>
          <Box height={100}></Box>
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  team: state.team,
  channel: state.channel,
  state: state.state,
  solves: state.solves,
  socket: state.socket,
  puzzle: state.puzzle,
  puzzles: state.puzzles,
  instructions: state.instructions,
});

export default connect(mapStateToProps, {
  setNavigation,
  fetchStateByGame,
  doAction,
  addSolve,
  fetchSolves,
  fetchPuzzle,
  clearPuzzle,
  clearInstructions,
  fetchInstructionsByPuzzle,
  fetchTeamByName,
})(Lobby);
