import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path01, door00, door02, puzzle01, scenarioBg } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal } from 'antd';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const Room202 = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addStory,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Room 202';
  const [visiblePuzzle01, setVisiblePuzzle01] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1760;
  const height = 1210;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle01',
        shape: 'poly',
        coords: [808, 499, 797, 559, 886, 575, 896, 516],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/room-202`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/room-202`
    ) {
      setNavigation({
        backward: {
          title: 'Intro',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/intro`);
          },
          image: door00,
        },
        forward: {
          title: 'Lift',
          image: door02,
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/lift`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle01') {
      setVisiblePuzzle01(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'solved',
        message: {
          puzzle: '01',
          channel: channelId,
          game: 'grand-hotel',
        },
      });
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Room 202 puzzle',
          game: 'grand-hotel',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path01}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('01');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '01',
              channel: channelId,
              game: 'grand-hotel',
              location: 'Room 202',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          You are invited to explore the hotel until welcome drinks are served.
          However, Lauren makes a point of telling you all: “The hotel has a
          very intricate security system. We have precious items we need to
          protect, so please be careful that you don’t find yourself in a room
          that you can’t get out of.”
        </Box>
        <Box fontSize={20} mb={30}>
          Feeling tired from the journey, you’re all eager to go to your rooms
          and get settled in. But as the group disperses, there is an obvious
          air of unease between Lauren and Kurt. Whilst Lauren seems every inch
          the natural hostess, Kurt appears anxious and uneasy.
        </Box>

        <Box fontSize={20}>
          As you enter your suite, you spot an envelope taped to the mirror of
          the bathroom.
        </Box>
      </Modal>
      <Modal
        visible={visiblePuzzle01}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Room 202 puzzle',
              game: 'grand-hotel',
            },
          });
          setVisiblePuzzle01(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12}>
            <img src={puzzle01} alt="Puzzle" />
          </Col>
          <Col xs={12}>
            <Box textAlign="center"></Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Room202);
