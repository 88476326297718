import React, { useEffect } from 'react';
import WebSocket from '@services/WebSocket';
import { connect } from 'react-redux';
import { USER_ROLE } from '@config/app';
import { getCookie } from '@common/utils/cookie';
import { setTeam } from '@resources/Team/actions';
import { addClue, addClues } from '@resources/Clue/actions';
import { addPuzzle, addPuzzles } from '@resources/Puzzle/actions';
import { setSocket } from '@resources/Socket/actions';
import {
  addMember,
  removeMember,
  updateMember,
} from '@resources/Member/actions';
import { setUser } from '@resources/User/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '@games/BataviaExpress/config';
import { notification } from 'antd';
import { addSolve, fetchSolves } from '@resources/Solve/actions';
import { addHelp, fetchHelps } from '@resources/Help/actions';
import { setEnd, fetchEnd } from '@resources/End/actions';

const Player = ({
  match,
  user,
  solves,
  helps,
  history,
  addMember,
  removeMember,
  setTeam,
  doAction,
  updateMember,
  setSocket,
  addPuzzle,
  addPuzzles,
  addClue,
  addClues,
  addSolve,
  addHelp,
  fetchHelps,
  fetchSolves,
  fetchEnd,
  setEnd,
}) => {
  const { params } = match || {};
  const { channelId } = params || {};
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);

  useEffect(() => {
    let solvesData = [];
    const fetchData = async () => {
      // You can await here
      await fetchHelps('batavia-express', channelId);
      solvesData = await fetchSolves('batavia-express', channelId);
      await fetchEnd('batavia-express', channelId);

      const pp = [];
      const cc = [];

      solvesData.forEach((solveData) => {
        if (solveData !== '0401') {
          if (solveData === '0303') {
            const c = cluesConf[`clue0303`];
            cc.push(c);
          } else {
            const p = puzzlesConf[`puzzle${solveData}`];
            pp.push({ ...p, status: 'solved' });
            p &&
              p.clues.forEach((clue) => {
                const c = cluesConf[`clue${clue}`];
                cc.push(c);
              });
          }
        }
      });

      addPuzzles(pp);
      addClues(cc);

      setTeam(channelId);
      // ...
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!getCookie('user')) {
      history.push(`/batavia-express/${channelId}/user`);
    }

    if (channelId && getCookie('user')) {
      const u = JSON.parse(getCookie('user'));
      let uid = null;
      let chan = null;
      if (u && u.name) {
        const { pusher, channel } = WebSocket({
          game: 'batavia-express',
          channel: channelId,
          name: u.name,
          role: USER_ROLE.PLAYER,
          onSubscribed: (member) => {
            uid = member.me.id;
            chan = channel;
            doAction({
              game: 'batavia-express',
              event: 'log',
              channel: chan,
              message: {
                id: uid,
                name: u.name,
                channel: channelId,
                location: 'lobby',
                message: 'has joined the game',
                game: 'batavia-express',
              },
            });
            for (var id in member.members) {
              const m = member.members[id];
              if (m.role === USER_ROLE.PLAYER) {
                if (id === uid) {
                  addMember({ id, name: u.name });
                } else {
                  addMember({ id, name: m.name });
                }
              }
            }
            setSocket({ id: uid, channel });
          },
          onMemberAdded: (member) => {
            if (member.info.role === USER_ROLE.PLAYER) {
              doAction({
                game: 'batavia-express',
                event: 'member',
                channel,
                message: { id: uid, name: u.name, game: 'batavia-express' },
              });
              addMember({
                id: member.id,
                name: member.info.name,
              });
            }
          },
          onMemberRemoved: (member) => {
            if (member.info.role === USER_ROLE.PLAYER) {
              removeMember(member);
            }
          },
          events: [
            {
              title: 'member',
              callback: (data) => {
                // console.log('SOCKET MEMBER', data);
                updateMember(data);
              },
            },
            {
              title: 'clues',
              callback: (data) => {
                // console.log('SOCKET CLUES', data);
                // const solved = getCookie('solved')
                // 	? JSON.parse(getCookie('solved'))
                // 	: [];
                // const newSolved = [...solved];
                // const newClues = [];
                // const newPuzzles = [];
                // data.forEach((d) => {
                // 	newClues.push(d);
                // 	solved
                // 		? solved.filter((puzz) => {
                // 				return (
                // 					puzz.toString() ===
                // 					d.puzzle.toString()
                // 				);
                // 		  }).length === 0 &&
                // 		  newSolved.filter((puzz) => {
                // 				return (
                // 					puzz.toString() ===
                // 					d.puzzle.toString()
                // 				);
                // 		  }).length === 0 &&
                // 		  newSolved.push(d.puzzle)
                // 		: newSolved.push(d.puzzle);
                // 	if (d.puzzle !== '0303') {
                // 		newPuzzles.push({
                // 			...puzzlesConf[`puzzle${d.puzzle}`],
                // 			status: 'solved',
                // 		});
                // 	}
                // });
                // setCookie('solved', JSON.stringify(newSolved));
                // addClues(newClues);
                // addPuzzles(newPuzzles);
              },
            },
            {
              title: 'puzzle',
              callback: (data) => {
                // console.log('SOCKET NOTIFICATION', data);
                data.forEach((d) => addPuzzle(d));
              },
            },
            {
              title: 'end',
              callback: (data) => {
                // console.log('SOCKET END', data);
                setEnd(true);
              },
            },
            {
              title: 'notification',
              callback: (notif) => {
                // console.log('SOCKET NOTIFICATION', notif);
                notification[notif.type]({
                  message: notif.title,
                  description: notif.description,
                });
              },
            },
            {
              title: 'solved',
              callback: (solve) => {
                // console.log('SOCKET SOLVED', solve);
                // const solved = getCookie('solved')
                // 	? JSON.parse(getCookie('solved'))
                // 	: [];
                // setCookie(
                // 	'solved',
                // 	JSON.stringify(
                // 		solved
                // 			? solved.filter(
                // 					(puzz) =>
                // 						puzz === solve
                // 			  ).length > 0
                // 				? solved
                // 				: [...solved, solve]
                // 			: [solve]
                // 	)
                // );

                addSolve(solve.puzzle);
                if (solve.puzzle !== '0401') {
                  if (solve.puzzle !== '0303') {
                    addPuzzle({
                      ...puzzlesConf[`puzzle${solve.puzzle}`],
                      status: 'solved',
                    });

                    puzzlesConf[`puzzle${solve.puzzle}`].clues.forEach(
                      (clue) => {
                        addClue(cluesConf[`clue${clue}`]);
                      }
                    );
                  } else {
                    addClue(cluesConf[`clue0303`]);
                  }
                }
              },
            },
            {
              title: 'help',
              callback: (help) => {
                // console.log('SOCKET HELP', help);
                addHelp(help.puzzle);
              },
            },
          ],
        });

        window.addEventListener('beforeunload', () => {
          doAction({
            game: 'batavia-express',
            event: 'log',
            channel,
            message: {
              id: uid,
              name: u.name,
              channel: channelId,
              location: 'lobby',
              message: 'has left the game',
              game: 'batavia-express',
            },
          });
          pusher.unsubscribe(channel);
        });
      }
    }
  }, [channelId, user, history]);

  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clues: state.clues,
    helps: state.helps,
    solves: state.solves,
  };
};

export default connect(mapStateToProps, {
  addMember,
  addPuzzle,
  addPuzzles,
  removeMember,
  updateMember,
  setTeam,
  setUser,
  doAction,
  setSocket,
  addClue,
  addClues,
  fetchSolves,
  fetchHelps,
  addSolve,
  addHelp,
  setEnd,
  fetchEnd,
})(Player);
