import { SET_NAVIGATION } from './actionTypes';

const setNavigation = (team) => (dispatch) => {
	dispatch({
		type: SET_NAVIGATION,
		payload: team,
	});
};

export { setNavigation };
