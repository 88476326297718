import React, { useEffect } from 'react';
import WebSocket from '@services/WebSocket';
import { connect } from 'react-redux';
import { USER_ROLE } from '@config/app';
import { setTeam } from '@resources/Team/actions';
import { addClue, addClues } from '@resources/Clue/actions';
import { addLog } from '@resources/Log/actions';
import { addPuzzle, addPuzzles } from '@resources/Puzzle/actions';
import { setSocket } from '@resources/Socket/actions';
import {
  addMember,
  removeMember,
  updateMember,
} from '@resources/Member/actions';
import { setUser } from '@resources/User/actions';
import { doAction } from '@resources/Action/actions';

import { fetchLogs } from '@resources/Log/actions';
import moment from 'moment';
import { Box, Title, View } from '@common/components';
import { Col, Row, notification } from 'antd';

import { addSolve, fetchSolves } from '@resources/Solve/actions';
import { addHelp, fetchHelps } from '@resources/Help/actions';

const Spectator = ({
  solves,
  helps,
  logs,
  match,
  history,
  removeMember,
  updateMember,
  addMember,
  setTeam,
  members,
  addLog,
  fetchLogs,
  fetchSolves,
  fetchHelps,
  addSolve,
  addHelp,
}) => {
  const { params } = match || {};
  const { channelId } = params || {};

  const fetchData = async (channelId) => {
    await fetchLogs('batavia-express', channelId);
    await fetchSolves('batavia-express', channelId);
    await fetchHelps('batavia-express', channelId);
  };

  useEffect(() => {
    fetchData(channelId);
  }, [channelId]);

  useEffect(() => {
    if (channelId) {
      setTeam(channelId);
      let uid = null;
      const { pusher, channel } = WebSocket({
        game: 'batavia-express',
        channel: channelId,
        role: USER_ROLE.SPECTATOR,
        onSubscribed: (member) => {
          uid = member.me.id;
          for (var id in member.members) {
            const m = member.members[id];
            if (m.role === 'player') {
              addMember({ id, name: m.name, info: member.info });
            }
          }
          setSocket({ id: uid, channel });
        },
        onMemberAdded: (member) => {
          if (member.info.role === 'player') {
            // addLog({
            // 	id: moment().format('DDMMYYYYHHmmss'),
            // 	date: moment(),
            // 	name: member.info.name,
            // 	member: member.id,
            // 	message: `has joined the game`,
            // });
            addMember({
              id: member.id,
              name: member.info.name,
              info: member.info,
            });
          }
        },
        onMemberRemoved: (member) => {
          if (member.info.role === 'player') {
            addLog({
              id: moment().format('DDMMYYYYHHmmss'),
              name: member.info.name,
              member: member.id,
              date: moment(),
              message: `has left the game`,
            });

            removeMember(member);
          }
        },
        events: [
          // {
          // 	title: 'member',
          // 	callback: (data) => {
          // 		updateMember(data);
          // 		data.location &&
          // 			addLog({
          // 				...data,
          // 				id: moment().format('DDMMYYYYHHmmss'),
          // 				date: moment(),
          // 				message: `has moved to ${data.location}`,
          // 			});
          // 	},
          // },
          {
            title: 'log',
            callback: (data) => {
              addLog({
                ...data,
                id: `${data.id}.${moment().format('DDMMYYYYHHmmss')}`,
                date: moment(),
              });

              updateMember(data);
            },
          },
          {
            title: 'notification',
            callback: (notif) => {
              // console.log('SOCKET NOTIFICATION', notif);
              notification[notif.type]({
                message: notif.title,
                description: notif.description,
              });
            },
          },
          {
            title: 'solved',
            callback: (solve) => {
              // console.log('SOCKET SOLVED', solve);
              // const solved = getCookie('solved')
              // 	? JSON.parse(getCookie('solved'))
              // 	: [];
              // setCookie(
              // 	'solved',
              // 	JSON.stringify(
              // 		solved
              // 			? solved.filter(
              // 					(puzz) =>
              // 						puzz === solve
              // 			  ).length > 0
              // 				? solved
              // 				: [...solved, solve]
              // 			: [solve]
              // 	)
              // );

              addSolve(solve.puzzle);
            },
          },
          {
            title: 'help',
            callback: (help) => {
              // console.log('SOCKET HELP', help);
              addHelp(help.puzzle);
            },
          },
        ],
      });

      window.addEventListener('beforeunload', () => {
        pusher.unsubscribe(channel);
      });
    }
  }, [channelId, history]);

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Title>Spectator</Title>
        Solved {solves.length}/11 | Help requested {helps.length}/3
        <br />
        <br />
        {logs.length === 0
          ? 'Waiting for player activities'
          : logs.map((log, index) => (
              <Row
                key={`log-${index}`}
                gutter={24}
                style={{
                  border: '1px dashed #333',
                  backgroundColor:
                    log.message.indexOf('solved') > -1
                      ? '#0f9001'
                      : log.message.indexOf('clicked the help button') > -1
                      ? 'orange'
                      : log.message.indexOf('requested help') > -1 && 'red',
                }}
              >
                <Col xs={8} sm={7} md={5} lg={4}>
                  {moment(log.date ? log.date : log.createdDate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )}
                </Col>

                <Col xs={4} sm={2} md={2} lg={1}>
                  {log.id.split('.')[0].substr(0, 4)}
                </Col>
                <Col xs={12} sm={15} md={17} lg={19}>
                  <strong>{log.name}</strong>{' '}
                  {log.member &&
                  members.filter((member) => member.id === log.member).length >
                    0
                    ? `(${
                        members.filter((member) => member.id === log.member)[0]
                          .name
                      })`
                    : ``}{' '}
                  {log.message}
                </Col>
              </Row>
            ))}
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clues: state.clues,
    logs: state.logs,
    members: state.members,
    solves: state.solves,
    helps: state.helps,
  };
};

export default connect(mapStateToProps, {
  addMember,
  addPuzzle,
  addPuzzles,
  removeMember,
  updateMember,
  setTeam,
  setUser,
  doAction,
  setSocket,
  addClue,
  addClues,
  addLog,
  fetchLogs,
  fetchSolves,
  fetchHelps,
  addSolve,
  addHelp,
})(Spectator);
