import React from 'react';
import { Modal as AntdModal } from 'antd';

// import { ThemeContext } from '@contexts/ThemeStore';

const Modal = (props) => {
	// const { theme } = useContext(ThemeContext);
	return (
		<AntdModal
			wrapClassName="fn-modal"
			footer={false}
			forceRender={true}
			{...props}
		/>
	);
};

export default Modal;
