import React, { useEffect, useState } from 'react';
import { Button, Input, Form, InputNumber, Radio, Spin } from 'antd';
import { Box, Container, View, Text, Title } from '@common/components';
import { fetchState } from '@resources/State/actions';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { addSolve, fetchSolvesByName } from '@resources/Solve/actions';
import { clearPuzzle, fetchPuzzleByStage } from '@resources/Puzzle/actions';
import {
  GithubOutlined,
  LoadingOutlined,
  FrownTwoTone,
  SmileTwoTone,
} from '@ant-design/icons';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;
const FormItem = Form.Item;

const Lobby = ({
  match,
  history,
  setNavigation,
  state,
  solves,
  socket,
  doAction,
  addSolve,
  fetchState,
  fetchSolvesByName,
  fetchPuzzleByStage,
  clearPuzzle,
  puzzle,
}) => {
  const u = getCookie('THVuser') ? JSON.parse(getCookie('THVuser')) : {};
  const { params } = match || {};
  const [chan, setChan] = useState();
  const { channelId } = params;
  const [timesUp, setTimesUp] = useState(false);
  const fetchData = async () => {
    // You can await here
    await fetchState('threevia', channelId);
    await fetchSolvesByName('threevia', channelId, u.name);
    // ...
  };

  useEffect(() => {
    if (u && u.name) {
      fetchData();
    } else {
      history.replace(`/threevia/${channelId}/user`);
    }
  }, []);

  const fetchPuzzleData = async () => {
    // You can await here
    state && (await fetchPuzzleByStage('threevia', channelId, state.stage));
    // ...
  };

  useEffect(() => {
    clearPuzzle();
    fetchPuzzleData();
    setTimesUp(false);
  }, [state]);

  useEffect(() => {
    socket && socket.channel && setChan(socket.channel);
  }, [socket]);

  const handleFinish = (values) => {
    doAction({
      game: 'threevia',
      channel: chan,
      event: 'solved',
      message: {
        puzzle: state.stage,
        channel: channelId,
        game: 'threevia',
        stage: state.stage,
        answer: values.answer,
        name: u.name,
      },
    });
  };

  useEffect(() => {
    setNavigation({
      backward: null,
      forward: null,
    });
  }, [history, match]);

  const reducer = (accumulator, curr) => accumulator + curr;

  return (
    <View>
      <Container>
        <Box centered textAlign="center">
          <Box flexDirection="row" width="100%">
            <GithubOutlined color="#FFCC00" />{' '}
            <Text display="inline-block" color="#FFCC00" html={u.name}></Text>{' '}
          </Box>
          {solves &&
          solves.length > 0 &&
          solves.filter((solve) => solve.isCorrect && solve.point).length >
            0 ? (
            <Text
              color="#FFCC00"
              html={`TOTAL SCORE ${solves
                .filter((solve) => solve.isCorrect && solve.point)
                .map((solve) => solve.point)
                .reduce(reducer)}`}
            ></Text>
          ) : (
            <Text html={`TOTAL SCORE 0`}></Text>
          )}
          {state && state.isStart ? (
            <>
              <Title fontSize={60}>ROUND {state.stage}</Title>

              <Box maxWidth={400}>
                {state && !state.isOpen ? (
                  <Box>
                    {solves &&
                    solves.filter((solve) => solve.stage === state.stage)
                      .length > 0 &&
                    solves.filter((solve) => solve.stage === state.stage)[0]
                      .isCorrect ? (
                      <Box>
                        <SmileTwoTone twoToneColor="#00FF00" />
                        <Text
                          ml={10}
                          display="inline-block"
                          color="#00FF00"
                          html={`You earned ${
                            solves.filter(
                              (solve) => solve.stage === state.stage
                            )[0].point
                          } point(s) for this round`}
                        ></Text>
                      </Box>
                    ) : (
                      solves &&
                      solves.filter((solve) => solve.stage === state.stage)
                        .length > 0 &&
                      solves.filter((solve) => solve.stage === state.stage)[0]
                        .isCorrect === false && (
                        <Box>
                          <FrownTwoTone twoToneColor="#FF0000" />
                          <Text
                            ml={10}
                            display="inline-block"
                            color="#FF0000"
                            html="You did not earn a point for this round"
                          ></Text>
                        </Box>
                      )
                    )}
                    <Text
                      fontSize={20}
                      html="Game Master has closed the form, no more answers are currently accepted for the round"
                    ></Text>
                    <br />
                    <Text
                      fontSize={20}
                      html={`Waiting for the form to be reopenned or next round`}
                    ></Text>
                    <br />
                    <Spin indicator={antIcon} />
                  </Box>
                ) : solves.filter((solve) => solve.stage === state.stage)
                    .length === 0 ? (
                  timesUp ? (
                    <Box>
                      <Text
                        color="#FFCC00"
                        html="Times Up for answering this round!"
                      ></Text>
                      <br />
                      <Text
                        fontSize={20}
                        html={`Waiting for the next round`}
                      ></Text>
                      <br />
                      <Spin indicator={antIcon} />
                    </Box>
                  ) : (
                    <>
                      <Form
                        layout="vertical"
                        name="access-form"
                        onFinish={handleFinish}
                      >
                        {state && puzzle && (
                          <Text
                            fontSize={20}
                            color={'#FFFFFF'}
                            html={puzzle.question}
                          ></Text>
                        )}
                        <br />
                        <Title fontSize={20}>
                          {puzzle && puzzle.type === 'text'
                            ? `Enter your answer`
                            : puzzle && puzzle.type === 'number'
                            ? `Enter your answer (Numbers only)`
                            : puzzle && puzzle.type === 'select'
                            ? 'Select your answer'
                            : `Enter your answer`}
                        </Title>
                        {state &&
                          ((puzzle && puzzle.type === 'text') ||
                            (puzzle && !puzzle.type) ||
                            !puzzle) && (
                            <FormItem
                              name="answer"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter your answer',
                                },
                              ]}
                            >
                              <Input placeholder="e.g. answer" />
                            </FormItem>
                          )}
                        {state && puzzle && puzzle.type === 'number' && (
                          <FormItem
                            name="answer"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your answer',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="e.g. 123"
                              fontSize={20}
                              formatter={(value) =>
                                `Rp ${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )
                              }
                              parser={(value) =>
                                value.replace(/Rp\s?|(,*)/g, '')
                              }
                              style={{ width: '100%' }}
                            />
                          </FormItem>
                        )}
                        {state && puzzle && puzzle.type === 'select' && (
                          <FormItem
                            name="answer"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your answer',
                              },
                            ]}
                          >
                            <Radio.Group>
                              {puzzle.options &&
                                puzzle.options.map((opt) => (
                                  <Radio value={opt.title}>{opt.title}</Radio>
                                ))}
                            </Radio.Group>
                          </FormItem>
                        )}
                        <FormItem>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                          >
                            {`Submit Answer`}
                          </Button>
                        </FormItem>
                      </Form>
                      {state.hasTimer === true && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          fontSize={50}
                          textAlign="center"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box width={180}>
                            <CountdownCircleTimer
                              isPlaying
                              duration={15}
                              colors={[
                                ['#004777', 0.33],
                                ['#F7B801', 0.33],
                                ['#A30000', 0.33],
                              ]}
                              onComplete={() => {
                                setTimesUp(true);
                              }}
                            >
                              {({ remainingTime }) => remainingTime}
                            </CountdownCircleTimer>
                          </Box>
                        </Box>
                      )}
                    </>
                  )
                ) : (
                  <Box>
                    {solves.filter((solve) => solve.stage === state.stage)[0]
                      .isCorrect ? (
                      <Box>
                        <SmileTwoTone twoToneColor="#00FF00" />
                        <Text
                          ml={10}
                          display="inline-block"
                          color="#00FF00"
                          html={`You earned ${
                            solves.filter(
                              (solve) => solve.stage === state.stage
                            )[0].point
                          } point(s) for this round`}
                        ></Text>
                      </Box>
                    ) : (
                      solves.filter((solve) => solve.stage === state.stage)[0]
                        .isCorrect === false && (
                        <Box>
                          <FrownTwoTone twoToneColor="#FF0000" />
                          <Text
                            ml={10}
                            display="inline-block"
                            color="#FF0000"
                            html="You did not earn a point for this round"
                          ></Text>
                        </Box>
                      )
                    )}
                    <Text
                      fontSize={20}
                      html={`You have answered the current round`}
                    ></Text>
                    <Text fontSize={20} html={`Waiting for next round`}></Text>
                    <br />
                    <Spin
                      indicator={antIcon}
                      size={'large'}
                      style={{ fontSize: 30 }}
                    />
                  </Box>
                )}
              </Box>
            </>
          ) : state && state.isStart === false ? (
            <>
              <Title>The game has ended</Title>
              <Text fontSize={20} html={`Thank you for playing!`}></Text>
            </>
          ) : (
            <>
              <Title>The game has not started yet</Title>
              <Text
                fontSize={20}
                html={`Waiting for the Game Master to start the game`}
              ></Text>
              <br />
              <Spin
                indicator={antIcon}
                size={'large'}
                style={{ fontSize: 30 }}
              />
            </>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  channel: state.channel,
  state: state.state,
  solves: state.solves,
  socket: state.socket,
  puzzle: state.puzzle,
});

export default connect(mapStateToProps, {
  setNavigation,
  fetchState,
  doAction,
  addSolve,
  fetchSolvesByName,
  fetchPuzzleByStage,
  clearPuzzle,
})(Lobby);
