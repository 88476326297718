import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { addHelp } from '@resources/Help/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';

const Help = ({
  socket,
  helps,
  puzzle,
  solution,
  addHelp,
  doAction,
  channel,
  location,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  if (!solution) {
    return null;
  }
  if (helps.filter((help) => help === puzzle).length > 0) {
    return solution;
  }

  if (helps.length >= 3) {
    return 'Your team have used up all of your 3 help chances. You will not be able to request for help on this puzzle.';
  }

  return (
    <Button
      onClick={() => {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location,
            channel,
            puzzle,
            message: `has clicked the help button for the ${location} puzzle`,
            game: 'batavia-express',
          },
        });

        Modal.confirm({
          title: 'Ask for help',
          content: (
            <div>
              You have {3 - helps.length} chances to ask for help. Do you want
              to ask help for this puzzle? <br />
              <br />
              Note: Your will receive penalty points for every help you
              requested.
            </div>
          ),
          onOk: () => {
            addHelp(puzzle);
            doAction({
              game: 'batavia-express',
              channel: socket.channel,
              event: 'notification',
              message: {
                type: 'info',
                title: `${location} Puzzle Help Requested`,
                game: 'batavia-express',
                description: `Your team member ${
                  u.name
                } has requested for help to solve ${location} Puzzle. The hint for the Puzzle has been revealed. Your team have ${2 -
                  helps.length} help requests left.`,
              },
            });
            doAction({
              game: 'batavia-express',
              channel: socket.channel,
              event: 'help',
              message: {
                puzzle,
                channel,
                game: 'batavia-express',
              },
            });
            doAction({
              game: 'batavia-express',
              channel: socket.channel,
              event: 'log',
              type: 'help',
              message: {
                id: socket.id,
                name: u.name,
                location,
                channel,
                puzzle,
                message: `has requested help for the ${location} puzzle`,
                game: 'batavia-express',
              },
            });
          },
        });
      }}
    >
      Ask for help
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    helps: state.helps,
    socket: state.socket,
  };
};

export default connect(mapStateToProps, { addHelp, doAction })(Help);
