import {
	puzzle0102,
	puzzle0103,
	puzzle0104,
	puzzle0201,
	puzzle0202,
	puzzle0203,
	puzzle0301,
	puzzle0302,
	puzzle0304,
} from '../assets/images';

export default (channelId) => {
	return {
		puzzle0102: {
			order: 1,
			id: 'puzzle0102',
			image: puzzle0102,
			width: 324,
			height: 500,
			room: 'Hector McKing Room',
			url: `/batavia-express/${channelId}/play/smoking-car`,
			clues: ['010201', '010202'],
		},
		puzzle0103: {
			order: 2,
			id: 'puzzle0103',
			image: puzzle0103,
			width: 500,
			height: 320,
			room: 'Countess Andrenzki Room',
			url: `/batavia-express/${channelId}/play/countess-andrenzki`,
			clues: ['0103'],
		},
		puzzle0104: {
			order: 3,
			id: 'puzzle0104',
			image: puzzle0104,
			width: 320,
			height: 500,
			room: 'Signore Bianco Room',
			url: `/batavia-express/${channelId}/play/signore-bianco`,
			clues: ['0104'],
		},
		puzzle0201: {
			order: 4,
			id: 'puzzle0201',
			image: puzzle0201,
			width: 500,
			height: 336,
			room: 'Dining Room',
			url: `/batavia-express/${channelId}/play/dining-room`,
			clues: ['0201'],
		},
		puzzle0202: {
			order: 5,
			id: 'puzzle0202',
			image: puzzle0202,
			width: 320,
			height: 500,
			room: 'Agatha Godsson Room',
			url: `/batavia-express/${channelId}/play/agatha-godsson`,
			clues: ['0202'],
		},
		puzzle0203: {
			order: 6,
			id: 'puzzle0203',
			image: puzzle0203,
			width: 500,
			height: 328,
			room: 'Mr Fleddoes Room',
			url: `/batavia-express/${channelId}/play/mr-fleddoes`,
			clues: ['0203'],
		},
		puzzle0301: {
			order: 7,
			id: 'puzzle0301',
			image: puzzle0301,
			width: 500,
			height: 366,
			room: 'Mary Ovenham Room',
			url: `/batavia-express/${channelId}/play/mary-ovenham`,
			clues: ['0301'],
		},
		puzzle0302: {
			order: 8,
			id: 'puzzle0302',
			image: puzzle0302,
			width: 500,
			height: 325,
			room: 'Mary Ovenham Room',
			url: `/batavia-express/${channelId}/play/mary-ovenham`,
			clues: ['0302'],
		},
		puzzle0304: {
			order: 9,
			id: 'puzzle0304',
			image: puzzle0304,
			width: 500,
			height: 379,
			room: 'Radio Room',
			url: `/batavia-express/${channelId}/play/radio-room`,
			clues: ['0304'],
		},
	};
};
