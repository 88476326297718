import React from 'react';
import { Box, View, Title } from '@common/components';
import { connect } from 'react-redux';
import { door00 as BataviaExpress } from '@games/BataviaExpress/assets/images';
import { door00 as GrandHotel } from '@games/GrandHotel/assets/images';
import { door00 as GrandHotelPro } from '@games/GrandHotelPro/assets/images';

const Dashboard = ({ history }) => {
  return (
    <View>
      <Box centered textAlign="center" flex>
        <Title>Pick your Game &raquo;</Title>
        <Box centered>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/batavia-express/team');
            }}
          >
            <img src={BataviaExpress} alt="Batavia Express" />
          </Box>

          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/grand-hotel/team');
            }}
          >
            <img src={GrandHotel} alt="Grand Hotel" />
          </Box>
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/grand-hotel-pro/team');
            }}
          >
            <img src={GrandHotelPro} alt="Grand Hotel PRO" />
          </Box>
        </Box>
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
});

export default connect(mapStateToProps, {})(Dashboard);
