import { SET_GAME } from './actionTypes';

const setGame = (game) => (dispatch) => {
	dispatch({
		type: SET_GAME,
		payload: game,
	});
};

export { setGame };
