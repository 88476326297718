import {
  SET_SOLVES,
  SET_SOLVE,
  CLEAR_SOLVES,
  CLEAR_SOLVE,
  ADD_SOLVE,
  ADD_SOLVES,
  UPDATE_SOLVE,
  REMOVE_SOLVE,
} from './actionTypes';

export const solve = (state = {}, action) => {
  switch (action.type) {
    case SET_SOLVE:
      return action.payload;
    case UPDATE_SOLVE:
      if (state.toString() !== action.payload.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_SOLVE:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_SOLVES:
      return action.payload;
    case ADD_SOLVE:
      if (state.filter((item) => item === action.payload).length > 0) {
        return [...state];
      }

      return [...state, action.payload];
    case ADD_SOLVES:
      const c = [...state];
      action.payload.forEach((payload) => {
        if (state.filter((item) => item === payload).length === 0) {
          c.push(payload);
        }
      });

      return c;
    case UPDATE_SOLVE:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_SOLVE:
      return state.map((item) => {
        if (item !== action.payload) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_SOLVES:
      return [];
    default:
      return state;
  }
};
