import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path0301,
  door00,
  door0302,
  puzzle0301,
  puzzle0302,
  hint0301,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const MaryOvenham = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Mary Ovenham Room';
  const [visiblePuzzle0301, setVisiblePuzzle0301] = useState(false);
  const [visiblePuzzle0302, setVisiblePuzzle0302] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1421;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0301',
        shape: 'poly',
        coords: [
          732,
          968,
          801,
          924,
          871,
          901,
          980,
          939,
          1018,
          976,
          1026,
          1127,
          914,
          1194,
          723,
          1142,
          733,
          1099,
        ],
      },
      {
        name: 'puzzle0302',
        shape: 'poly',
        coords: [
          1063,
          885,
          1197,
          869,
          1232,
          888,
          1232,
          968,
          1100,
          981,
          1066,
          965,
        ],
      },
    ],
  };

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/mary-ovenham`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/mary-ovenham`
    ) {
      setNavigation({
        backward: {
          title: 'Hallway',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/fork`);
          },
          image: door00,
        },
        forward: {
          title: "Colonel Arbuthyet's Room",
          locked:
            solves.filter((solve) => solve === '0301' || solve === '0302')
              .length < 2,
          image: door0302,
          onPress: () => {
            history.push(
              `/batavia-express/${channelId}/play/colonel-arbuthyet`
            );
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0301') {
      addPuzzle({
        ...puzzlesConf.puzzle0301,
        status: 'unsolved',
      });
      setVisiblePuzzle0301(true);
    }

    if (area.name === 'puzzle0302') {
      addPuzzle({
        ...puzzlesConf.puzzle0302,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Mary Ovenham puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0302(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0301}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0301}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Mary Ovenham puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0301(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0301} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <br />
            <br />
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0301').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <Box width="100%">
                    <CombinationLock
                      style={{ color: '#333' }}
                      combination="901"
                      height={70}
                      onMatch={() => {
                        message.success(
                          'Congrats! You have solved the first puzzle for this room and found a clue! You may now continue to solve the second puzzle'
                        );
                        addSolve('0301');
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'notification',
                          message: {
                            type: 'success',
                            title: 'Mary Ovenham Puzzle Solved',
                            description: `Your team member ${
                              u.name ? u.name : ''
                            } has successfully solved the first puzzle in Mary Ovenham's room and found a clue! You need to solve another puzzle in this room to unlock the door to Colonel Arbuthyet's room.`,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'solved',
                          message: {
                            puzzle: '0301',
                            channel: channelId,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'log',
                          type: 'solved',
                          message: {
                            id: socket.id,
                            name: u.name,
                            location: location,
                            channel: channelId,
                            puzzle: '0301',
                            message: 'has solved Mary Ovenham first puzzle',
                            game: 'batavia-express',
                          },
                        });
                        addClue({
                          ...cluesConf.clue0301,
                        });
                        updatePuzzle({
                          ...puzzlesConf.puzzle0301,
                          status: 'solved',
                        });
                        setNavigation({
                          backward: {
                            title: 'Hallway',
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/fork`
                              );
                            },
                            content: door00,
                          },
                          forward: {
                            title: "Colonel Arbuthyet's Room",
                            locked:
                              solves.filter(
                                (solve) => solve === '0301' || solve === '0302'
                              ).length < 2,
                            image: door0302,
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/colonel-arbuthyet`
                              );
                            },
                          },
                        });
                      }}
                      openText={'Unlocked!'}
                    />
                  </Box>
                  <br />
                  <Help
                    location={location}
                    puzzle="0301"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        When you draw imaginary lines tracing the 3 sequences in
                        the photos of the puzzle, you will end up with 3 digits
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visiblePuzzle0302}
        onCancel={() => {
          setVisiblePuzzle0302(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0302} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <br />
            <br />
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0302').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    style={{ color: '#333' }}
                    combination="371"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                      );
                      addSolve('0302');
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Mary Ovenham Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the second puzzle in Mary Ovenham's room and found a clue! The door to Colonel Arbuthyet's room has now been unlocked.`,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0302',
                          channel: channelId,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0302',
                          message: 'has solved Mary Ovenham second puzzle',
                          game: 'batavia-express',
                        },
                      });
                      addClue({
                        ...cluesConf.clue0302,
                      });
                      updatePuzzle({
                        ...puzzlesConf.puzzle0302,
                        status: 'solved',
                      });
                      setNavigation({
                        backward: {
                          title: 'Hallway',
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/fork`
                            );
                          },
                          content: door00,
                        },
                        forward: {
                          title: "Colonel Arbuthyet's Room",
                          locked:
                            solves.filter(
                              (solve) => solve === '0301' || solve === '0302'
                            ).length < 2,
                          image: door0302,
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/colonel-arbuthyet`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0302"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Use the clue you receive from the other puzzle in this
                        room to solve this puzzle.
                        <br />
                        <br />
                        Follow through the numbers on the wheel and the
                        remaining numbers is the digits to solve this puzzle.
                        The beginning of the solution can be seen below
                        <br />
                        <br />
                        <img width={100} src={hint0301} alt="hint0102" />
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(MaryOvenham);
