import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_TEAM,
  SET_TEAMS,
  CLEAR_TEAM,
  CLEAR_TEAMS,
  REMOVE_TEAM,
} from './actionTypes';

const fetchTeamsByGame = (gameId) => (dispatch) => {
  return request('get', `${API_SOCKET}/teams/game/${gameId}`)
    .then((response) => {
      dispatch({
        type: SET_TEAMS,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchTeamByName = (gameId) => (dispatch) => {
  return request('get', `${API_SOCKET}/teams/name/${gameId}`)
    .then((response) => {
      dispatch({
        type: SET_TEAM,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchTeam = (id) => (dispatch) => {
  return request('get', `${API_SOCKET}/teams/${id}`)
    .then((response) => {
      dispatch({
        type: SET_TEAM,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const setTeam = (team) => (dispatch) => {
  dispatch({
    type: SET_TEAM,
    payload: team,
  });
};

const clearTeam = () => (dispatch) => {
  dispatch({
    type: CLEAR_TEAM,
  });
};

const clearTeams = () => (dispatch) => {
  dispatch({
    type: CLEAR_TEAMS,
  });
};

const deleteTeam = (id, fid) => (dispatch) =>
  request('delete', `${API_SOCKET}/teams/${id}`)
    .then((response) => {
      dispatch({
        type: REMOVE_TEAM,
        payload: response.data,
      });

      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));

export {
  fetchTeamsByGame,
  fetchTeamByName,
  fetchTeam,
  setTeam,
  clearTeam,
  clearTeams,
  deleteTeam,
};
