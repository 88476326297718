import Intro from './00-Intro';

import Room202 from './01-Room202';
import Lift from './02-Lift';
import WineCellar from './03-WineCellar';
import Kitchen from './04-Kitchen';
import MaidsQuarter from './05-MaidsQuarter';
import Ballroom from './06-Ballroom';
import HoneymoonSuite from './07-HoneymoonSuite';
import Lobby from './08-Lobby';
import Outside from './09-Outside';

export default {
  Intro,
  Room202,
  Lift,
  WineCellar,
  Kitchen,
  MaidsQuarter,
  Ballroom,
  HoneymoonSuite,
  Lobby,
  Outside,
};
