import {
	SET_LOGS,
	SET_LOG,
	CLEAR_LOGS,
	CLEAR_LOG,
	ADD_LOG,
	ADD_LOGS,
	UPDATE_LOG,
	REMOVE_LOG,
} from './actionTypes';

export const log = (state = {}, action) => {
	switch (action.type) {
		case SET_LOG:
			return action.payload;
		case UPDATE_LOG:
			if (state.id.toString() !== action.payload.id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_LOG:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_LOGS:
			return action.payload;
		case ADD_LOG:
			return [...state, action.payload];
		case ADD_LOGS:
			const s = [...state];
			action.payload.forEach((payload) => {
				if (
					state.filter((item) => item.id === payload.id).length === 0
				) {
					s.push(payload);
				}
			});

			return s;

		case UPDATE_LOG:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_LOG:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_LOGS:
			return [];
		default:
			return state;
	}
};
