import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path01,
  door00,
  door02,
  puzzle01,
  puzzle0102,
  scenarioBg,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import CombinationLock from 'combination-lock-react';
import Help from '@games/GrandHotelPro/fragments/Help';
import { CheckCircleFilled } from '@ant-design/icons';

const Room202 = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addStory,
}) => {
  const u = getCookie('GHPuser') ? JSON.parse(getCookie('GHPuser')) : {};
  const location = 'Room 202';
  const [visiblePuzzle01, setVisiblePuzzle01] = useState(false);
  const [visiblePuzzle02, setVisiblePuzzle02] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1760;
  const height = 1210;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle01',
        shape: 'poly',
        coords: [808, 499, 797, 559, 886, 575, 896, 516],
      },
      {
        name: 'puzzle0102',
        shape: 'poly',
        coords: [1434, 328, 1611, 253, 1611, 933, 1434, 939],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel-pro/${channelId}/play/room-202`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel-pro',
          },
        });
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel-pro',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel-pro/${channelId}/play/room-202`
    ) {
      setNavigation({
        backward: {
          title: 'Intro',
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/intro`);
          },
          image: door00,
        },
        forward: {
          title: 'Lift',
          locked: solves.filter((solve) => solve === '0102').length === 0,
          image: door02,
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/lift`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle01') {
      setVisiblePuzzle01(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'solved',
        message: {
          puzzle: '01',
          channel: channelId,
          game: 'grand-hotel-pro',
        },
      });
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Room 202 puzzle',
          game: 'grand-hotel-pro',
        },
      });
    }

    if (area.name === 'puzzle0102') {
      setVisiblePuzzle02(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Room 202 puzzle 02',
          game: 'grand-hotel-pro',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path01}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('01');
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '01',
              channel: channelId,
              game: 'grand-hotel-pro',
              location: 'Room 202',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          You are invited to explore the hotel until welcome drinks are served.
          However, Lauren makes a point of telling you all: “The hotel has a
          very intricate security system. We have precious items we need to
          protect, so please be careful that you don’t find yourself in a room
          that you can’t get out of.”
        </Box>
        <Box fontSize={20} mb={30}>
          Feeling tired from the journey, you’re all eager to go to your rooms
          and get settled in. But as the group disperses, there is an obvious
          air of unease between Lauren and Kurt. Whilst Lauren seems every inch
          the natural hostess, Kurt appears anxious and uneasy.
        </Box>

        <Box fontSize={20}>
          As you enter your suite, you spot an envelope taped to the mirror of
          the bathroom.
        </Box>
      </Modal>
      <Modal
        visible={visiblePuzzle01}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Room 202 puzzle',
              game: 'grand-hotel-pro',
            },
          });
          setVisiblePuzzle01(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12}>
            <img src={puzzle01} alt="Puzzle" />
          </Col>
          <Col xs={12}>
            <Box textAlign="center"></Box>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visiblePuzzle02}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Room 202 puzzle 02',
              game: 'grand-hotel-pro',
            },
          });
          setVisiblePuzzle02(false);
        }}
        width={1000}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} textAlign="center">
            <Box fontSize={25} textAlign="center" mb={20}>
              What was the code to your room’s lock again?
            </Box>
            <Box textAlign="center" mb={20}>
              <img src={puzzle0102} alt="Puzzle" width="100%" />
            </Box>
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0102').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="311"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('0102');
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Room 202 Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in Room 202! The door to the Lift has now been unlocked.`,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0102',
                          channel: channelId,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0102',
                          message: 'has solved the Room 202 puzzle',
                          game: 'grand-hotel-pro',
                        },
                      });

                      setNavigation({
                        backward: {
                          title: 'Intro',
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/intro`
                            );
                          },
                          image: door00,
                        },
                        forward: {
                          title: 'Lift',
                          image: door02,
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/lift`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0102"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Move back to see clearly
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Room202);
