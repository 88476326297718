import {
	SET_USERS,
	SET_USER,
	CLEAR_USERS,
	CLEAR_USER,
	ADD_USER,
	UPDATE_USER,
	REMOVE_USER,
} from './actionTypes';

export const user = (state = {}, action) => {
	switch (action.type) {
		case SET_USER:
			return action.payload;
		case UPDATE_USER:
			if (state.id.toString() !== action.payload.id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_USER:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_USERS:
			return action.payload;
		case ADD_USER:
			return [...state, action.payload];
		case UPDATE_USER:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_USER:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_USERS:
			return [];
		default:
			return state;
	}
};
