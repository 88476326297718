import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path07, door06, door08, scenarioBg } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Modal } from 'antd';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const HoneymoonSuite = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addStory,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Honeymoon Suite';
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1425;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel/${channelId}/play/honeymoon-suite`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel/${channelId}/play/honeymoon-suite`
    ) {
      setNavigation({
        backward: {
          title: 'Ballroom',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/ballroom`);
          },
          image: door06,
        },
        forward: {
          title: 'Lobby',
          image: door08,
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/lobby`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path07}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={() => {}}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('07');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '07',
              channel: channelId,
              game: 'grand-hotel',
              location: 'Honeymoon Suite',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          You hurtle down to the honeymoon suite and key in the 3-digit code to
          the lock, and the door flies open. Inside, you can hear shuffling
          sounds coming from the huge, ornate wardrobe in the corner of the
          room.
        </Box>
        <Box fontSize={20} mb={30}>
          You rush over to open it. To your shock, you see Amanda and Christophe
          Blossom being tied and their mouths covered. Once you release them,
          Amanda shouts, “Lauren did this to us. That wretch keeps us hostage so
          that she can threaten our dear Kurt to do what she wants. You need to
          save Kurt. Quickly!”
        </Box>
        <Box fontSize={20}>
          As you are unsure who among the hotel staff that you could trust, you
          ask your partners to keep an eye on the couple to ensure their safety.
        </Box>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(HoneymoonSuite);
