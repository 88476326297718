import React from 'react';
import { Affix } from 'antd';
import { connect } from 'react-redux';
import { setAudio } from '@resources/Audio/actions';

const Navbar = ({ audio, setAudio, ...restProps }) => {
  return (
    <Affix
      style={{
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: 10,
      }}
    ></Affix>
  );
};

const mapStateToProps = (state) => {
  return {
    audio: state.audio,
  };
};

export default connect(mapStateToProps, { setAudio })(Navbar);
