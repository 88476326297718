import React from 'react';
import { Affix, Col, Row } from 'antd';
import { connect } from 'react-redux';
import Stories from './items/Stories/Stories';
import ReactAudioPlayer from 'react-audio-player';
import exhale from '@games/GrandHotelPro/assets/audio/exhale.mp3';
import { SoundFilled, SoundOutlined } from '@ant-design/icons';
import { setAudio } from '@resources/Audio/actions';
import { Box } from '@common/components';

const Navbar = ({ audio, setAudio, ...restProps }) => {
  return (
    <Affix
      style={{
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: 10,
      }}
    >
      <Row align="middle">
        <Col xs={12}>
          <Box ml={20}>
            <Stories />
          </Box>
        </Col>
        <Col xs={12} align="right">
          <Box
            onClick={() => {
              setAudio(!audio);
            }}
            display="inline-block"
            justifyContent="center"
            align="middle"
            p={12}
            textAlign="center"
            width={50}
            height={50}
            borderRadius={25}
            backgroundColor="#333"
            mr={20}
          >
            {audio ? (
              <SoundFilled style={{ fontSize: 25 }} />
            ) : (
              <SoundOutlined style={{ fontSize: 25 }} />
            )}
          </Box>
          {audio && (
            <ReactAudioPlayer src={exhale} autoPlay loop volume={0.1} />
          )}
        </Col>
      </Row>
    </Affix>
  );
};

const mapStateToProps = (state) => {
  return {
    audio: state.audio,
  };
};

export default connect(mapStateToProps, { setAudio })(Navbar);
