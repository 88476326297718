import React, { useState, useCallback } from 'react';
import { Form, Upload } from 'antd';
import { deleteMedia } from '@resources/Media/actions';
import { formErrorHandler } from '@common/utils/form';
import { API_MEDIA } from '@config/api';
import { connect } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import themes from '@theme';
import { Box, Modal } from '@components';
import './assets/basic-upload.scss';

const FormItem = Form.Item;

const BasicUploadField = ({
  auth,
  form,
  source,
  folder,
  setSource,
  fieldOptions,
  uploadOptions = { className: 'basic-uploader' },
  deleteMedia,
  cropOptions,
  limit = 1,
  text,
}) => {
  const [preview, setPreview] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const uploadButton = (
    <div>
      <PlusOutlined style={{ color: themes.base.primary, fontSize: 20 }} />
      <Box px={20}>{text || `Upload`}</Box>
    </div>
  );

  const handlePreview = useCallback((file) => {
    console.log(file);
    setPreview(file.url || file.preview || file.thumbUrl);
  }, []);

  const handleChange = (set) => ({ fileList: newFileList }) => {
    set(newFileList.filter((file) => !!file.status));
  };

  const handleRemove = useCallback(
    (set, sources) => (file) => {
      const id =
        file.response && file.response.data && file.response.data.length > 0
          ? file.response.data[0]._id
          : file._id;

      setDeleting(true);
      deleteMedia(id)
        .then((res) => {
          set(sources.filter((fileItem) => fileItem.uid !== file.uid));

          setDeleting(false);
        })
        .catch((err) => {
          formErrorHandler(err, {}, form);

          setDeleting(false);
        });

      return false;
    },
    [deleteMedia]
  );
  const uploadField = (
    <Upload
      action={`${API_MEDIA}${folder}`}
      listType="picture-card"
      fileList={source}
      onPreview={handlePreview}
      onChange={handleChange(setSource)}
      onRemove={!deleting ? handleRemove(setSource, source) : deleting}
      {...{ className: 'basic-uploader', ...uploadOptions }}
    >
      {source.length > limit - 1 ? null : uploadButton}
    </Upload>
  );
  return (
    <Box>
      <Modal visible={preview} footer={null} onCancel={() => setPreview(false)}>
        {preview ? (
          <img alt="example" style={{ width: '100%' }} src={preview} />
        ) : null}
      </Modal>
      <FormItem {...fieldOptions}>
        {cropOptions ? (
          <ImgCrop minZoom={1} {...cropOptions}>
            {uploadField}
          </ImgCrop>
        ) : (
          uploadField
        )}
      </FormItem>
    </Box>
  );
};

const mapStateToProps = ({ auth, categories }) => {
  return { auth, categories };
};

export default connect(mapStateToProps, { deleteMedia })(BasicUploadField);
