import React, { useEffect } from 'react';
import { Box, Title, View } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { Avatar, Button, Space, Table, Modal } from 'antd';
import {
  deletePuzzle,
  addPuzzle,
  fetchPuzzles,
  fetchPuzzlesByGame,
} from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const Manage = ({
  puzzles,
  match,
  history,
  fetchPuzzlesByGame,
  deletePuzzle,
}) => {
  const { params } = match || {};
  const { gameId } = params;

  const fetchData = async () => {
    await fetchPuzzlesByGame(gameId);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: '#',
      width: 50,
      dataIndex: 'stage',
      key: 'stage',

      fixed: 'left',
      render: (text) => text,
    },
    {
      title: 'Image',
      dataIndex: 'media',
      key: 'media',
      width: 120,
      render: (text) => {
        return <Avatar src={text.path} size={100} />;
      },
    },
    {
      title: 'Booth',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Action',
      key: 'action',
      width: 300,
      render: (text, record) => (
        <Space size="middle">
          <Button
            size="small"
            onClick={() => {
              history.push(`/carnival/${gameId}/puzzle/${record._id}/manage`);
            }}
          >
            Instructions
          </Button>
          <Button
            size="small"
            onClick={() => {
              history.push(`/carnival/${gameId}/puzzle/${record._id}`);
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={async () => {
              Modal.confirm({
                title: 'Delete Booth',
                content: 'Are you sure you want to delete this booth?',
                onOk: async () => {
                  await deletePuzzle(record._id);
                  fetchData();
                },
              });
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Title>Manage</Title>
        <Button onClick={() => history.push(`/carnival/${gameId}/game-master`)}>
          Back to Game
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => history.push(`/carnival/${gameId}/puzzle`)}
          type="primary"
        >
          + Add Booth
        </Button>
        <Table scroll={{ x: 800 }} columns={columns} dataSource={puzzles} />
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
  fetchPuzzles,
  fetchPuzzlesByGame,
  deletePuzzle,
})(Manage);
