import {
	SET_MEMBERS,
	SET_MEMBER,
	CLEAR_MEMBERS,
	CLEAR_MEMBER,
	ADD_MEMBER,
	UPDATE_MEMBER,
	UPDATE_MEMBERS,
	REMOVE_MEMBER,
} from './actionTypes';

export const member = (state = {}, action) => {
	switch (action.type) {
		case SET_MEMBER:
			return action.payload;
		case UPDATE_MEMBER:
			if (
				state.id &&
				state.id.toString() !== action.payload.id.toString()
			) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_MEMBER:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_MEMBERS:
			return action.payload;
		case ADD_MEMBER:
			if (
				state.filter((item) => item.id === action.payload.id).length > 0
			) {
				return state.map((item) => {
					if (item.id.toString() !== action.payload.id.toString()) {
						return item;
					}

					// Otherwise, this is the one we want - return an updated value
					return {
						...item,
						...action.payload,
					};
				});
			}

			return [...state, action.payload];
		case UPDATE_MEMBER:
			return state.map((item) => {
				if (item.id.toString() !== action.payload.id.toString()) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case UPDATE_MEMBERS:
			return state.map((item) => {
				const payload = action.payload.filter(
					(payload) => payload.id === item.id
				);
				if (payload.length > 0) {
					return { ...item, ...payload[0] };
				} else {
					return item;
				}
			});
		case REMOVE_MEMBER:
			return state.filter((item) => {
				return item.id !== action.payload.id;
			});
		case CLEAR_MEMBERS:
			return [];
		default:
			return state;
	}
};
