import {
  SET_STORIES,
  SET_STORY,
  CLEAR_STORIES,
  CLEAR_STORY,
  ADD_STORY,
  ADD_STORIES,
  UPDATE_STORY,
  REMOVE_STORY,
} from './actionTypes';

export const story = (state = {}, action) => {
  switch (action.type) {
    case SET_STORY:
      return action.payload;
    case UPDATE_STORY:
      if (state.toString() !== action.payload.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_STORY:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_STORIES:
      return action.payload;
    case ADD_STORY:
      if (state.filter((item) => item === action.payload).length > 0) {
        return [...state];
      }

      return [...state, action.payload];
    case ADD_STORIES:
      const c = [...state];
      action.payload.forEach((payload) => {
        if (state.filter((item) => item === payload).length === 0) {
          c.push(payload);
        }
      });

      return c;
    case UPDATE_STORY:
      return state.map((item) => {
        if (item !== action.payload) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_STORY:
      return state.map((item) => {
        if (item !== action.payload) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_STORIES:
      return [];
    default:
      return state;
  }
};
