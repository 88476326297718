import { SET_CLUES, SET_CLUE, ADD_CLUE, ADD_CLUES } from './actionTypes';

const setClues = (clues) => (dispatch) => {
	dispatch({
		type: SET_CLUES,
		payload: clues,
	});
};

const setClue = (clue) => (dispatch) => {
	dispatch({
		type: SET_CLUE,
		payload: clue,
	});
};

const addClue = (clue) => (dispatch) => {
	dispatch({
		type: ADD_CLUE,
		payload: clue,
	});
};

const addClues = (clue) => (dispatch) => {
	dispatch({
		type: ADD_CLUES,
		payload: clue,
	});
};

export { setClues, setClue, addClue, addClues };
