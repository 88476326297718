import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Carousel, Modal, Tooltip } from 'antd';
import { Box } from '@common/components';

const Clues = ({ clues }) => {
	const [visible, setVisible] = useState(false);
	return (
		<>
			{clues.length > 0 && (
				<Box mr={30} display="inline-block">
					<Badge count={clues.length}>
						<Button onClick={() => setVisible(true)}>Clues</Button>
					</Badge>
				</Box>
			)}
			<Modal
				visible={visible}
				width={600}
				footer={null}
				onCancel={() => {
					setVisible(false);
				}}>
				<Box height={520}>
					<Carousel dotPosition="right">
						{clues &&
							clues.map((clue, index) => (
								<div key={`clue-${index}`}>
									<Box
										centered
										textAlign="center"
										height={520}>
										<Tooltip
											title={`Found in ${clue.room}`}>
											<img
												src={clue.image}
												width={clue.width}
												height={clue.height}
												alt="Clue"
											/>
										</Tooltip>
									</Box>
								</div>
							))}
					</Carousel>
				</Box>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	clues: state.clues,
});

export default connect(mapStateToProps)(Clues);
