import React, { useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { Box, View, Title } from '@common/components';
import { setUser } from '@resources/User/actions';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { deleteCookie } from '@common/utils/cookie';
const FormItem = Form.Item;

const User = ({ match, history, setNavigation, setUser }) => {
  const { params } = match || {};
  const { gameId, channelId } = params;
  const handleFinish = (values) => {
    setUser(
      { ...values },
      gameId === 'mid-autumn'
        ? 'CNV'
        : gameId === 'threevia'
        ? 'THV'
        : gameId === 'grand-hotel-pro'
        ? 'GHP'
        : gameId === 'grand-hotel'
        ? 'GH'
        : ''
    );
    if (gameId === 'threevia') {
      history.push(`/${gameId}/${channelId}/play/lobby`);
    } else if (gameId === 'mid-autumn') {
      history.push(`/carnival/${gameId}/${channelId}/play/lobby`);
    } else {
      history.push(`/${gameId}/${channelId}/play/intro`);
    }
  };

  useEffect(() => {
    deleteCookie(
      `${
        gameId === 'mid-autumn'
          ? 'CNV'
          : gameId === 'threevia'
          ? 'THV'
          : gameId === 'grand-hotel'
          ? 'GHP'
          : 'grand-hotel'
          ? 'GH'
          : ''
      }user`
    );
    deleteCookie(
      `${
        gameId === 'mid-autumn'
          ? 'CNV'
          : gameId === 'threevia'
          ? 'THV'
          : gameId === 'grand-hotel'
          ? 'GHP'
          : 'grand-hotel'
          ? 'GH'
          : ''
      }solved`
    );
  }, []);

  useEffect(() => {
    setNavigation({
      backward: null,
      forward: null,
    });
  }, [history, match]);

  return (
    <View>
      <Box centered textAlign="center">
        <Form layout="vertical" name="access-form" onFinish={handleFinish}>
          <Title>Enter your name below</Title>
          <FormItem
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter your name',
              },
            ]}
          >
            <Input style={{ fontSize: 30 }} placeholder="e.g. John Doe" />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {`Enter ${
                gameId === 'mid-autumn'
                  ? 'Carnival'
                  : gameId === 'threevia'
                  ? 'Threevia'
                  : gameId === 'grand-hotel-pro'
                  ? 'Grand Hotel PRO'
                  : gameId === 'grand-hotel'
                  ? 'Grand Hotel'
                  : 'Batavia Express'
              }`}
            </Button>
          </FormItem>
        </Form>
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  channel: state.channel,
});

export default connect(mapStateToProps, { setUser, setNavigation })(User);
