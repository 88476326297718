import React, { useState } from 'react';
import { Affix, Badge, Button, PageHeader, Table, Tag, Modal } from 'antd';
import logo from './assets/logo.jpg';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

const Navbar = ({ game, team, members }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Affix style={{ position: 'absolute', zIndex: 100, width: '100%' }}>
        <PageHeader
          className="site-page-header"
          backIcon={false}
          title={
            <span>
              <img src={logo} width={50} alt="Logo" /> Hope SG Indo
            </span>
          }
          tags={
            !isEmpty(team) && (
              <Tag color="orange">{team && team._id ? team.name : team}</Tag>
            )
          }
          subTitle={game.title}
          extra={
            members.length > 0 &&
            game.title !== 'Threevia' &&
            game.title !== 'Carnival'
              ? [
                  <Badge key={'Member'} count={members.length}>
                    <Button
                      size="medium"
                      key="3"
                      onClick={() => setVisible(true)}
                    >
                      Members
                    </Button>
                  </Badge>,
                ]
              : []
          }
        />
      </Affix>
      <Modal visible={visible} footer={null} onCancel={() => setVisible(false)}>
        <Table
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name' },
            {
              title: 'Location',
              dataIndex: 'location',
              key: 'location',
            },
          ]}
          dataSource={members.map((member) => {
            return {
              name: member.name,
              location: member.location,
              key: member.id,
            };
          })}
          pagination={false}
          width="100%"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  game: state.game,
  team: state.team,
  members: state.members,
});

export default connect(mapStateToProps)(Navbar);
