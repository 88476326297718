let env, socket;

const hostname = window && window.location && window.location.hostname;
const domain = 'hopesgindo.life';
const protocol = window.location.protocol;
let subdomain = hostname.split('.')[0];

if (subdomain === `staging`) {
  env = `staging.api.${domain}`;
  socket = `staging.socket.${domain}`;
} else if (subdomain === `games`) {
  env = `api.${domain}`;
  socket = `socket.${domain}`;
} else if (/^qa/.test(hostname)) {
  env = `api.${domain}`;
  socket = `socket.${domain}`;
} else {
  env = process.env.REACT_APP_BACKEND_HOST || 'localhost:5000';
  socket = process.env.REACT_APP_SOCKET_HOST || 'localhost:5000';
}

export const API_ROOT = `${protocol}//${env}/api/v1`;
export const API_SOCKET = `${protocol}//${socket}/api/v1`;
export const API_MEDIA = `${API_SOCKET}/medias/upload`;
export const API_ENV = env;
