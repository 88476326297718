import citiesReducers, { city } from './City/reducers';
import countriesReducers, { country } from './Country/reducers';
import languagesReducers, { language } from './Language/reducers';

export const commonReducers = {
	cities: citiesReducers,
	city,
	countries: countriesReducers,
	country,
	languages: languagesReducers,
	language,
};
