import React, { useState, useEffect } from 'react';
import { Box, View, Title } from '@common/components';
import { Row, Col, Modal } from 'antd';
import { path0101, path0201, path0301, door0401 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie, setCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';

const Fork = ({
  setNavigation,
  history,
  solves,
  match,
  socket,
  doAction,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const onboarded = getCookie('onboarded')
    ? JSON.parse(getCookie('onboarded'))
    : false;
  const { params } = match || {};
  const { channelId } = params;
  const location = 'Hallway';

  const [onboarding, setOnboarding] = useState(!onboarded);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/batavia-express/${channelId}/play/fork`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/batavia-express/${channelId}/play/fork`
    ) {
      setNavigation({
        backward: {
          title: 'Intro',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/intro`);
          },
        },
        forward: {
          title: 'Radio',
          image: door0401,
          locked:
            solves.filter(
              (solve) =>
                solve === '0102' ||
                solve === '0103' ||
                solve === '0104' ||
                solve === '0201' ||
                solve === '0202' ||
                solve === '0203' ||
                solve === '0301' ||
                solve === '0302' ||
                solve === '0303' ||
                solve === '0304'
            ).length < 10,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/radio`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  return (
    <View>
      <Box centered textAlign="center">
        <Box width="100%">
          <Title>Choose your path</Title>
          <Box width={'80%'} mx={'10%'}>
            <Row gutter={24}>
              <Col xs={8}>
                <Box
                  width="100%"
                  height={[100, 200, 300]}
                  backgroundImage={`url("${path0101}")`}
                  backgroundSize={'100% 100%'}
                  onClick={() => {
                    history.push(
                      `/batavia-express/${channelId}/play/smoking-car`
                    );
                  }}
                  centered
                  style={{ cursor: 'pointer' }}
                >
                  {solves.filter(
                    (solve) =>
                      solve === '0102' || solve === '0103' || solve === '0104'
                  ).length === 3 && (
                    <CheckCircleFilled
                      style={{
                        fontSize: 50,
                        color: '#52c41a',
                      }}
                    />
                  )}
                </Box>
              </Col>
              <Col xs={8}>
                <Box
                  width="100%"
                  height={[100, 200, 300]}
                  backgroundImage={`url("${path0201}")`}
                  backgroundSize={'100% 100%'}
                  onClick={() => {
                    history.push(
                      `/batavia-express/${channelId}/play/dining-room`
                    );
                  }}
                  centered
                  style={{ cursor: 'pointer' }}
                >
                  {solves.filter(
                    (solve) =>
                      solve === '0201' || solve === '0202' || solve === '0203'
                  ).length === 3 && (
                    <CheckCircleFilled
                      style={{
                        fontSize: 50,
                        color: '#52c41a',
                      }}
                    />
                  )}
                </Box>
              </Col>
              <Col xs={8}>
                <Box
                  width="100%"
                  height={[100, 200, 300]}
                  backgroundImage={`url("${path0301}")`}
                  backgroundSize={'100% 100%'}
                  onClick={() => {
                    history.push(
                      `/batavia-express/${channelId}/play/mary-ovenham`
                    );
                  }}
                  centered
                  style={{ cursor: 'pointer' }}
                >
                  {solves.filter(
                    (solve) =>
                      solve === '0301' ||
                      solve === '0302' ||
                      solve === '0303' ||
                      solve === '0304'
                  ).length === 4 && (
                    <CheckCircleFilled
                      style={{
                        fontSize: 50,
                        color: '#52c41a',
                      }}
                    />
                  )}
                </Box>
              </Col>
            </Row>
          </Box>
        </Box>
      </Box>
      <Modal
        visible={onboarding}
        footer={null}
        onCancel={() => {
          setCookie('onboarded', JSON.stringify(true));
          setOnboarding(false);
        }}
      >
        <Box>
          <Title>Tips</Title>
          <ul>
            <li>
              Not all objects you see in the room are needed to solve the
              puzzles, so be selective in what objects you use to solve the
              puzzle{' '}
            </li>
            <li>
              In case you missed it, the Naga Mas Notebook mentioned in the
              video is located on the bottom of the page. You can access it at
              any room you are in
            </li>
            <li>
              There is an Ask for help button for each puzzle and your team only
              have 3 chances during the whole game to ask for help.
            </li>
            <li>
              Whenever your team ask for help, your team will incur penalty
              points
            </li>
            <li>
              Once you discover a puzzle or clue, they will be available for you
              to see on the bottom right. You can access it in any room you are
              in
            </li>
          </ul>
        </Box>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  setNavigation,
  doAction,
  updateMember,
})(Fork);
