import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box, View } from '@common/components';
import { doAction } from '@resources/Action/actions';
import { connect } from 'react-redux';
import { getCookie, setCookie } from '@common/utils/cookie';
import { setNavigation } from '@resources/Navigation/actions';
import { door01 } from '../assets/images';
import { updateMember } from '@resources/Member/actions';
import { isEmpty } from 'lodash';
import { setAudio } from '@resources/Audio/actions';

const Intro = ({
  match,
  clues,
  socket,
  setNavigation,
  history,
  updateMember,
  setAudio,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Intro';
  const { params } = match || {};
  const { channelId } = params;
  const video = true; //getCookie('GHvideo') ? JSON.parse(getCookie('GHvideo')) : true;

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/intro`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/intro`
    ) {
      setNavigation({
        backward: null,
        forward: {
          image: door01,
          title: 'Room 202',
          onPress: () => {
            setAudio(true);
            history.push(`/grand-hotel/${channelId}/play/room-202`);
          },
        },
      });
    }
  }, [clues, video, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/intro`
    ) {
      setNavigation({
        backward: null,
        forward: {
          image: door01,
          title: 'Room 202',
          onPress: () => {
            setAudio(true);
            history.push(`/grand-hotel/${channelId}/play/room-202`);
          },
        },
      });
    }
  }, []);

  return (
    <View>
      <Box centered textAlign="center">
        <ReactPlayer
          url="https://youtu.be/YRHQpW2XnPc"
          playing
          width="100%"
          height="80%"
          onPlay={() => {
            doAction({
              game: 'grand-hotel',
              channel: socket.channel,
              event: 'log',
              message: {
                id: socket.id,
                name: u.name,
                location: location,
                channel: channelId,
                message: `is watching the ${location}`,
                game: 'grand-hotel',
              },
            });
            setAudio(false);
          }}
          onEnded={() => {
            setAudio(true);
            setCookie('GHvideo', JSON.stringify(true));
            doAction({
              game: 'grand-hotel',
              channel: socket.channel,
              event: 'log',
              message: {
                id: socket.id,
                name: u.name,
                location: location,
                channel: channelId,
                message: `has finised watching the ${location}`,
                game: 'grand-hotel',
              },
            });
            history.push(`/grand-hotel/${channelId}/play/room-202`);
          }}
        />
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
});

export default connect(mapStateToProps, {
  doAction,
  setNavigation,
  updateMember,
  setAudio,
})(Intro);
