import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path04,
  door03,
  door05,
  puzzle04,
  puzzle0402,
  clue04,
  scenarioBg,
  hint04,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import Help from '@games/GrandHotelPro/fragments/Help';

const Kitchen = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addSolve,
  addStory,
}) => {
  const u = getCookie('GHPuser') ? JSON.parse(getCookie('GHPuser')) : {};
  const location = 'Kitchen';
  const [visiblePuzzle04, setVisiblePuzzle04] = useState(false);
  const [visiblePuzzle0402, setVisiblePuzzle0402] = useState(false);
  const [visibleClue04, setVisibleClue04] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1747;
  const height = 1218;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle04',
        shape: 'poly',
        coords: [550, 308, 550, 482, 642, 482, 642, 320],
      },
      {
        name: 'puzzle0402',
        shape: 'poly',
        coords: [518, 517, 535, 516, 536, 601, 518, 605],
      },
      {
        name: 'clue04',
        shape: 'poly',
        coords: [148, 500, 140, 588, 286, 588, 288, 506],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/kitchen`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel-pro',
          },
        });
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel-pro',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/kitchen`
    ) {
      setNavigation({
        backward: {
          title: 'Wine Cellar',
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/wine-cellar`);
          },
          image: door03,
        },
        forward: {
          title: "Maid's Quarter",
          locked:
            solves.filter((solve) => solve === '04').length === 0 ||
            solves.filter((solve) => solve === '0402').length === 0,
          image: door05,
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/maids-quarter`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle04') {
      setVisiblePuzzle04(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Kitchen puzzle',
          game: 'grand-hotel-pro',
        },
      });
    }

    if (area.name === 'puzzle0402') {
      setVisiblePuzzle0402(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Kitchen puzzle 02',
          game: 'grand-hotel-pro',
        },
      });
    }

    if (area.name === 'clue04') {
      setVisibleClue04(true);
      doAction({
        game: 'grand-hotel-pro',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Kitchen clue',
          game: 'grand-hotel-pro',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path04}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('04');
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '04',
              channel: channelId,
              game: 'grand-hotel-pro',
              location: 'Kitchen',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          Success! The door to the wine cellar swings open and you all rush to
          the next room. With the scrubbed steel countertops and glistening
          copper pots, it’s evident you’re in the hotel kitchen.
        </Box>
        <Box fontSize={20}>
          Faced with another locked door and another combination lock, you punch
          the same code as in the wine cellar. No luck.
        </Box>
      </Modal>
      <Modal
        visible={visibleClue04}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Kitchen clue',
              game: 'grand-hotel-pro',
            },
          });
          setVisibleClue04(false);
        }}
        footer={null}
        width={800}
      >
        <img src={clue04} alt="Clue" width="100%" />
      </Modal>
      <Modal
        visible={visiblePuzzle04}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Kitchen puzzle',
              game: 'grand-hotel-pro',
            },
          });
          setVisiblePuzzle04(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle04} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '04').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="120"
                    height={70}
                    onMatch={() => {
                      message.success(
                        solves.filter((solve) => solve === '0402').length === 0
                          ? 'Congrats! You have solved one out of two puzzles for this room! Solve the other puzzle in the room to proceed'
                          : 'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('04');
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Kitchen Puzzle Solved',
                          game: 'grand-hotel-pro',
                          description:
                            solves.filter((solve) => solve === '0402')
                              .length === 0
                              ? `Your team member ${
                                  u.name ? u.name : ''
                                } has successfully solved one of the puzzle in the Kitchen! There is one more puzzle in the room to be unlocked.`
                              : `Your team member ${
                                  u.name ? u.name : ''
                                } has successfully solved the puzzle in the Kitchen! The door to Maid's Quarter has now been unlocked.`,
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '04',
                          channel: channelId,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '04',
                          message: 'has solved the Kitchen puzzle',
                          game: 'grand-hotel-pro',
                        },
                      });
                      setNavigation({
                        backward: {
                          title: 'Wine Cellar',
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/wine-cellar`
                            );
                          },
                          content: door03,
                        },
                        forward: {
                          title: "Maid's Quarter",
                          image: door05,
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/maids-quarter`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="04"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>

                        <br />
                        <img width={300} src={hint04} alt="hint04" />
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visiblePuzzle0402}
        onCancel={() => {
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Kitchen puzzle 02',
              game: 'grand-hotel-pro',
            },
          });
          setVisiblePuzzle0402(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0402} alt="Puzzle" width="100%" />
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0402').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="093"
                    height={70}
                    onMatch={() => {
                      message.success(
                        solves.filter((solve) => solve === '04').length === 0
                          ? 'Congrats! You have solved one out of two puzzles for this room! Solve the other puzzle in the room to proceed'
                          : 'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('0402');
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Kitchen Puzzle Solved',
                          game: 'grand-hotel-pro',
                          description:
                            solves.filter((solve) => solve === '04').length ===
                            0
                              ? `Your team member ${
                                  u.name ? u.name : ''
                                } has successfully solved one of the puzzle in the Kitchen! There is one more puzzle in the room to be unlocked.`
                              : `Your team member ${
                                  u.name ? u.name : ''
                                } has successfully solved the puzzle in the Kitchen! The door to Maid's Quarter has now been unlocked.`,
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0402',
                          channel: channelId,
                          game: 'grand-hotel-pro',
                        },
                      });
                      doAction({
                        game: 'grand-hotel-pro',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0402',
                          message: 'has solved the Kitchen puzzle 02',
                          game: 'grand-hotel-pro',
                        },
                      });
                      setNavigation({
                        backward: {
                          title: 'Wine Cellar',
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/wine-cellar`
                            );
                          },
                          content: door03,
                        },
                        forward: {
                          title: "Maid's Quarter",
                          image: door05,
                          onPress: () => {
                            history.push(
                              `/grand-hotel-pro/${channelId}/play/maids-quarter`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0402"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        There is a repeated number
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Kitchen);
