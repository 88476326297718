import { API_SOCKET } from '@config/api';
import {} from './actionTypes';
import request from '@common/utils/request';

const doAction = (data) => (dispatch) =>
  request(
    'post',
    `${API_SOCKET}/actions/${data.game}${
      data.channel ? `/${data.channel}` : ''
    }`,
    data
  )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export { doAction };
