import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_SOLVES,
  SET_SOLVE,
  ADD_SOLVE,
  ADD_SOLVES,
  UPDATE_SOLVE,
} from './actionTypes';

const fetchSolves = (gameId, channel) => (dispatch) => {
  return request('get', `${API_SOCKET}/solves/${gameId}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_SOLVES,
        payload: response.data.data.map((data) => data.puzzle),
      });
      return response.data.data.map((data) => data.puzzle);
    })
    .catch((err) => err);
};

const fetchSolvesFull = (gameId, channel) => (dispatch) => {
  return request('get', `${API_SOCKET}/solves/${gameId}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_SOLVES,
        payload: response.data.data,
      });
      return response.data.data.map((data) => data.puzzle);
    })
    .catch((err) => err);
};

const fetchSolvesByChannel = (gameId, channelId) => (dispatch) => {
  return request('get', `${API_SOCKET}/solves/${gameId}/${channelId}`)
    .then((response) => {
      dispatch({
        type: SET_SOLVES,
        payload: response.data.data,
      });
      return response.data.data.map((data) => data.puzzle);
    })
    .catch((err) => err);
};

const fetchSolvesByGame = (gameId) => (dispatch) => {
  return request('get', `${API_SOCKET}/solves/${gameId}`)
    .then((response) => {
      dispatch({
        type: SET_SOLVES,
        payload: response.data.data,
      });
      return response.data.data.map((data) => data.puzzle);
    })
    .catch((err) => err);
};

const fetchSolvesByName = (gameId, channel, name) => (dispatch) => {
  return request('post', `${API_SOCKET}/solves/${gameId}/${channel}/name`, {
    name,
  })
    .then((response) => {
      dispatch({
        type: SET_SOLVES,
        payload: response.data.data,
      });
      return response.data.data.map((data) => data.puzzle);
    })
    .catch((err) => err);
};

const setSolves = (solves) => (dispatch) => {
  dispatch({
    type: SET_SOLVES,
    payload: solves,
  });
};

const setSolve = (solve) => (dispatch) => {
  dispatch({
    type: SET_SOLVE,
    payload: solve,
  });
};

const addSolve = (solve) => (dispatch) => {
  dispatch({
    type: ADD_SOLVE,
    payload: solve,
  });
};

const updateSolve = (solve) => (dispatch) => {
  dispatch({
    type: UPDATE_SOLVE,
    payload: solve,
  });
};

const addSolves = (solve) => (dispatch) => {
  dispatch({
    type: ADD_SOLVES,
    payload: solve,
  });
};

export {
  fetchSolves,
  fetchSolvesFull,
  fetchSolvesByChannel,
  fetchSolvesByName,
  fetchSolvesByGame,
  setSolves,
  setSolve,
  addSolve,
  addSolves,
  updateSolve,
};
