import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_ENDS,
  SET_END,
  ADD_END,
  ADD_ENDS,
  UPDATE_END,
} from './actionTypes';

const fetchEnd = (game, channel) => (dispatch) => {
  request('get', `${API_SOCKET}/ends/${game}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_END,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => Promise.reject(err));
};

const setEnds = (ends) => (dispatch) => {
  dispatch({
    type: SET_ENDS,
    payload: ends,
  });
};

const setEnd = (end) => (dispatch) => {
  dispatch({
    type: SET_END,
    payload: end,
  });
};

const addEnd = (end) => (dispatch) => {
  dispatch({
    type: ADD_END,
    payload: end,
  });
};

const updateEnd = (end) => (dispatch) => {
  dispatch({
    type: UPDATE_END,
    payload: end,
  });
};

const addEnds = (end) => (dispatch) => {
  dispatch({
    type: ADD_ENDS,
    payload: end,
  });
};

export { fetchEnd, setEnds, setEnd, addEnd, addEnds, updateEnd };
