import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import { SET_LOGS, SET_LOG, ADD_LOG, ADD_LOGS } from './actionTypes';

const fetchLogs = (game, channel) => (dispatch) => {
  request('get', `${API_SOCKET}/logs/${game}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_LOGS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));
};

const setLogs = (logs) => (dispatch) => {
  dispatch({
    type: SET_LOGS,
    payload: logs,
  });
};

const setLog = (log) => (dispatch) => {
  dispatch({
    type: SET_LOG,
    payload: log,
  });
};

const addLog = (log) => (dispatch) => {
  dispatch({
    type: ADD_LOG,
    payload: log,
  });
};

const addLogs = (log) => (dispatch) => {
  dispatch({
    type: ADD_LOGS,
    payload: log,
  });
};

export { fetchLogs, setLogs, setLog, addLog, addLogs };
