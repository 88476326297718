import { SET_AUDIO } from './actionTypes';

const setAudio = (audio) => (dispatch) => {
	dispatch({
		type: SET_AUDIO,
		payload: audio,
	});
};

export { setAudio };
