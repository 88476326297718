let analytics_id, google_api_key, pusher_id, pusher_key, pusher_cluster;

const hostname = window && window.location && window.location.hostname;
let subdomain = hostname.split('.')[0];
const protocol = window.location.protocol;

analytics_id = 'UA-173278120-1';
google_api_key = 'AIzaSyA1WHQIlJtZwI6-WC2n4eMEDaK6pkiDAy4';

const DOMAIN = 'hopesgindo.life';

if (subdomain === `staging`) {
	pusher_id = '810978';
	pusher_key = '1d6e41a82ac1606f6779';
	pusher_cluster = 'ap1';

	// pusher_id = '1032735';
	// pusher_key = '20d8da337c8060207acc';
	// pusher_cluster = 'ap1';
} else if (subdomain === `games`) {
	pusher_id = '1032736';
	pusher_key = 'a98da27ca0afa085853a';
	pusher_cluster = 'ap1';
} else if (/^qa/.test(hostname)) {
	pusher_id = '1032736';
	pusher_key = 'a98da27ca0afa085853a';
	pusher_cluster = 'ap1';
} else {
	pusher_id = '1032734';
	pusher_key = '5ba9813e1e823ba7c8fc';
	pusher_cluster = 'ap1';
}

export const APP_ROOT = `${protocol}//${hostname}`;

export const HOPESGINDO_GOOGLE_ANALYTICS_ID = analytics_id;
export const HOPESGINDO_GOOGLE_API_KEY = google_api_key;

export const HOPESGINDO_PUSHER_ID = pusher_id;
export const HOPESGINDO_PUSHER_KEY = pusher_key;
export const HOPESGINDO_PUSHER_CLUSTER = pusher_cluster;

export const HOPESGINDO_DOMAIN = DOMAIN;

export const USER_ROLE = { PLAYER: 'player', SPECTATOR: 'spectator' };
