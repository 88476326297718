import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_PUZZLES,
  SET_PUZZLE,
  ADD_PUZZLE,
  ADD_PUZZLES,
  UPDATE_PUZZLE,
  CLEAR_PUZZLE,
  REMOVE_PUZZLE,
} from './actionTypes';

const fetchPuzzles = (gameId, channel) => (dispatch) => {
  return request(
    'get',
    `${API_SOCKET}/puzzles/${gameId}${channel ? `/${channel}` : ''}`
  )
    .then((response) => {
      dispatch({
        type: SET_PUZZLES,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchPuzzlesByGame = (gameId) => (dispatch) => {
  return request('get', `${API_SOCKET}/puzzles/game/${gameId}`)
    .then((response) => {
      dispatch({
        type: SET_PUZZLES,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchPuzzleByStage = (gameId, channel, stage) => (dispatch) => {
  return request('get', `${API_SOCKET}/puzzles/${gameId}/${channel}/${stage}`)
    .then((response) => {
      dispatch({
        type: SET_PUZZLE,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const fetchPuzzle = (id) => (dispatch) => {
  return request('get', `${API_SOCKET}/puzzles/${id}`)
    .then((response) => {
      dispatch({
        type: SET_PUZZLE,
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((err) => err);
};

const setPuzzles = (puzzles) => (dispatch) => {
  dispatch({
    type: SET_PUZZLES,
    payload: puzzles,
  });
};

const setPuzzle = (puzzle) => (dispatch) => {
  dispatch({
    type: SET_PUZZLE,
    payload: puzzle,
  });
};

const addPuzzle = (puzzle) => (dispatch) => {
  dispatch({
    type: ADD_PUZZLE,
    payload: puzzle,
  });
};

const updatePuzzle = (puzzle) => (dispatch) => {
  dispatch({
    type: UPDATE_PUZZLE,
    payload: puzzle,
  });
};

const addPuzzles = (puzzle) => (dispatch) => {
  dispatch({
    type: ADD_PUZZLES,
    payload: puzzle,
  });
};

const clearPuzzle = () => (dispatch) => {
  dispatch({
    type: CLEAR_PUZZLE,
  });
};

const deletePuzzle = (id, fid) => (dispatch) =>
  request('delete', `${API_SOCKET}/puzzles/${id}`)
    .then((response) => {
      dispatch({
        type: REMOVE_PUZZLE,
        payload: response.data,
      });

      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));

export {
  fetchPuzzles,
  fetchPuzzle,
  fetchPuzzleByStage,
  fetchPuzzlesByGame,
  setPuzzles,
  setPuzzle,
  addPuzzle,
  addPuzzles,
  updatePuzzle,
  clearPuzzle,
  deletePuzzle,
};
