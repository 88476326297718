import {
	clue010201,
	clue010202,
	clue0103,
	clue0104,
	clue0201,
	clue0202,
	clue0203,
	clue0301,
	clue0302,
	clue0303,
	clue0304,
} from '../assets/images';

export default (channelId) => {
	return {
		clue010201: {
			order: 1,
			id: 'clue010201',
			image: clue010201,
			width: 331,
			height: 500,
			room: 'Hector McKing Room',
			url: `/batavia-express/${channelId}/play/hector-mc-king`,
			puzzle: '0102',
		},
		clue010202: {
			order: 2,
			id: 'clue010202',
			image: clue010202,
			width: 325,
			height: 500,
			room: 'Hector McKing Room',
			url: `/batavia-express/${channelId}/play/hector-mc-king`,
			puzzle: '0102',
		},
		clue0103: {
			order: 3,
			id: 'clue0103',
			image: clue0103,
			width: 321,
			height: 500,
			room: 'Countess Andrenzki Room',
			url: `/batavia-express/${channelId}/play/countess-andrenzki`,
			puzzle: '0103',
		},
		clue0104: {
			order: 4,
			id: 'clue0104',
			image: clue0104,
			width: 320,
			height: 500,
			room: 'Signore Bianco Room',
			url: `/batavia-express/${channelId}/play/signore-bianco`,
			puzzle: '0104',
		},
		clue0201: {
			order: 5,
			id: 'clue0201',
			image: clue0201,
			width: 500,
			height: 320,
			room: 'Dining Room',
			url: `/batavia-express/${channelId}/play/dining-room`,
			puzzle: '0201',
		},
		clue0202: {
			order: 6,
			id: 'clue0202',
			image: clue0202,
			width: 330,
			height: 500,
			room: 'Agatha Godsson Room',
			url: `/batavia-express/${channelId}/play/agatha-godsson`,
			puzzle: '0202',
		},
		clue0203: {
			order: 7,
			id: 'clue0203',
			image: clue0203,
			width: 341,
			height: 500,
			room: 'Mr Fleddoes Room',
			url: `/batavia-express/${channelId}/play/mr-fleddoes`,
			puzzle: '0203',
		},
		clue0301: {
			order: 8,
			id: 'clue0301',
			image: clue0301,
			width: 500,
			height: 495,
			room: 'Mary Ovenham Room',
			url: `/batavia-express/${channelId}/play/mary-ovenham`,
			puzzle: '0301',
		},
		clue0302: {
			order: 9,
			id: 'clue0302',
			image: clue0302,
			width: 318,
			height: 500,
			room: 'Mary Ovenham Room',
			url: `/batavia-express/${channelId}/play/mary-ovenham`,
			puzzle: '0302',
		},
		clue0303: {
			order: 10,
			id: 'clue0303',
			image: clue0303,
			width: 500,
			height: 379,
			room: 'Colonel Arbuthyet Room',
			url: `/batavia-express/${channelId}/play/colonel-arbuthyet`,
			puzzle: '0303',
		},
		clue0304: {
			order: 11,
			id: 'clue0304',
			image: clue0304,
			width: 320,
			height: 500,
			room: 'Radio Room',
			url: `/batavia-express/${channelId}/play/radio-room`,
			puzzle: '0303',
		},
	};
};
