import { SET_USER } from './actionTypes';
import { setCookie } from '@common/utils/cookie';

const setUser = (user, game) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });

  setCookie(`${game}user`, JSON.stringify(user));
};

export { setUser };
