import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { LockFilled, RightCircleFilled } from '@ant-design/icons';
import { Box } from '@common/components';
import { Popover } from 'antd';
import { setNavigation } from '@resources/Navigation/actions';
import './assets/styles.scss';

const Forward = ({ navigation, setNavigation }) => {
  const { forward } = navigation || {};
  const { image, title, locked, onPress } = forward || {};

  const content = useCallback(() => {
    return (
      <Box>
        {!locked && title && <div>{title}</div>}
        {locked && (
          <Box
            position="absolute"
            top="40%"
            marginRight={10}
            textAlign="center"
            backgroundColor="rgba(0,0,0,0.5)"
            color={'#FFF'}
            padding={15}
            left={0}
          >
            <LockFilled style={{ color: '#FFF', fontSize: 50 }} />
            <br />
            You are not able to move to the next room until you solve the puzzle
            in the current room
          </Box>
        )}
        {!locked ? (
          <img src={image} alt="Forward" />
        ) : (
          <Box height={500} width={300} backgroundColor="#000"></Box>
        )}
      </Box>
    );
  }, [image, locked]);

  const nav = () => {
    return locked ? (
      <LockFilled style={{ fontSize: 50 }} />
    ) : (
      <RightCircleFilled
        style={{ fontSize: 50 }}
        onClick={() => {
          setNavigation({ forward: null, backward: null });
          onPress && onPress();
        }}
      />
    );
  };

  return forward ? (
    <Box className="hs-navigation-forward">
      {image ? (
        <Popover content={content()} placement="left" trigger="hover">
          {nav()}
        </Popover>
      ) : (
        nav()
      )}
    </Box>
  ) : null;
};

const mapStateToProps = (state) => ({
  navigation: state.navigation,
});

export default connect(mapStateToProps, { setNavigation })(Forward);
