import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_STORIES,
  SET_STORY,
  ADD_STORY,
  ADD_STORIES,
  UPDATE_STORY,
} from './actionTypes';

const fetchStories = (game, channel) => (dispatch) => {
  return request('get', `${API_SOCKET}/stories/${game}/${channel}`)
    .then((response) => {
      console.log(response);
      dispatch({
        type: SET_STORIES,
        payload: response.data.data.map((data) => data.story),
      });
      return response.data.data.map((data) => data.story);
    })
    .catch((err) => err);
};

const setStories = (stories) => (dispatch) => {
  dispatch({
    type: SET_STORIES,
    payload: stories,
  });
};

const setStory = (story) => (dispatch) => {
  dispatch({
    type: SET_STORY,
    payload: story,
  });
};

const addStory = (story) => (dispatch) => {
  dispatch({
    type: ADD_STORY,
    payload: story,
  });
};

const updateStory = (story) => (dispatch) => {
  dispatch({
    type: UPDATE_STORY,
    payload: story,
  });
};

const addStories = (story) => (dispatch) => {
  dispatch({
    type: ADD_STORIES,
    payload: story,
  });
};

export {
  fetchStories,
  setStories,
  setStory,
  addStory,
  addStories,
  updateStory,
};
