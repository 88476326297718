import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0302, door0301, door0303 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Modal, message } from 'antd';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';
import { Redirect } from 'react-router-dom';
import { cluesConfig } from '../config';
import { addSolve } from '@resources/Solve/actions';

const MaryOvenham = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  addClue,
  addSolve,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Colonel Arbuthyet Room';
  const [visibleNagaMas, setVisibleNagaMas] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1428;
  const cluesConf = cluesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'naga-mas',
        shape: 'poly',
        coords: [
          438,
          788,
          469,
          806,
          497,
          857,
          459,
          911,
          426,
          936,
          466,
          948,
          597,
          1079,
          706,
          1199,
          718,
          1242,
          702,
          1282,
          670,
          1232,
          656,
          1270,
          637,
          1191,
          455,
          1051,
          441,
          1118,
          633,
          1250,
          667,
          1424,
          528,
          1424,
          482,
          1303,
          489,
          1426,
          363,
          1426,
          321,
          1301,
          125,
          1123,
          47,
          1120,
          148,
          930,
          210,
          881,
          334,
          869,
          369,
          813,
        ],
      },
    ],
  };

  if (solves.filter((solve) => solve === '0302').length === 0) {
    return <Redirect to={`/batavia-express/${channelId}/play/mary-ovenham`} />;
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/colonel-arbuthyet`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/colonel-arbuthyet`
    ) {
      setNavigation({
        backward: {
          title: "Mary Ovenham's Room",
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/mary-ovenham`);
          },
          image: door0301,
        },
        forward: {
          title: 'Radio Room',
          image: door0303,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/radio-room`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'naga-mas') {
      if (solves.filter((solve) => solve === '0303').length === 0) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: 'has opened the Naga Mas message and found Naga Mas clue',
            game: 'batavia-express',
          },
        });
        message.success('Congrats! You have found a clue in this room!');
        addSolve('0303');
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'notification',
          message: {
            type: 'success',
            title: 'Colonel Arbuthyet Clue Found',
            description: `Your team member ${
              u.name ? u.name : ''
            } has successfully found a clue in Colonel Arbuthyet's room`,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'solved',
          message: {
            puzzle: '0303',
            channel: channelId,
            game: 'batavia-express',
          },
        });
        addClue({
          ...cluesConf.clue0303,
        });
      }
      setVisibleNagaMas(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0302}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visibleNagaMas}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Naga Mas message',
              game: 'batavia-express',
            },
          });
          setVisibleNagaMas(false);
        }}
        width={800}
        footer={null}
      >
        <Box centered height={500} textAlign="center" fontSize={20}>
          <p>
            You find Inspector Naga Mas. He has been rendered unconscious –
            probably with knockout drops. What’s he mumbling in his sleep? That
            in addition to the murderer, there’s another kleptomaniac person on
            the train who ripped off another passenger while the murder was
            happening? What a seedy alibi… but an alibi nevertheless.
          </p>
          <p>
            It’s high time we opened the door to the radio room and reported the
            perpetrator to the authorities. Soon we’ll be in Djakarta. We have
            to send the radio message as quickly as possible.
          </p>
        </Box>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(MaryOvenham);
