export default () => {
  return {
    story01: {
      paragraphs: [
        `You are invited to explore the hotel until welcome drinks are served.
      However, Lauren makes a point of telling you all: “The hotel has a
      very intricate security system. We have precious items we need to
      protect, so please be careful that you don’t find yourself in a room
      that you can’t get out of.”`,
        `Feeling tired from the journey, you’re all eager to go to your rooms
      and get settled in. But as the group disperses, there is an obvious
      air of unease between Lauren and Kurt. Whilst Lauren seems every inch
      the natural hostess, Kurt appears anxious and uneasy.`,
        `As you enter your suite, you spot an envelope taped to the mirror of
      the bathroom.`,
      ],
    },
    story02: {
      paragraphs: [
        `As soon as you decipher intent of the letter and the location
        concealed in it, you hastily search for the nearest lift. Along the
        corridor, you bump into Lauren. “Good evening. You seem lost. May I
        help you?” asked Lauren. Hesitating to tell her what happened, you
        reply, “I am fine. I just wanted to explore more of this exquisite
        hotel. I will be on my way.”`,
        `As you raise your hand to press the button, Lauren beat you to it and
        with a pleasant smile in her face say, “Let me escort you to your
        location. We do not want our guest to be stranded in an unknown room,
        won’t we? Where are you heading to?“`,
      ],
    },
    story03: {
      paragraphs: [
        `Lauren guided you in and stand behind you. As the gilded doors of the lift closes and the lift starts to ascend, you feel a violent thud at the back of your head with a metal object. Your vision blurs and your head starts to swim. You falls on you knees and then there is nothing but black.`,
      ],
    },
    story04: {
      paragraphs: [
        `Success! The door to the wine cellar swings open and you all rush to
      the next room. With the scrubbed steel countertops and glistening
      copper pots, it’s evident you’re in the hotel kitchen.`,
        `Faced with another locked door and another combination lock, you punch
        the same code as in the wine cellar. No luck.`,
      ],
    },
    story05: {
      paragraphs: [
        `You type the code to the door and with a slight push it swings open.
      As you cautiously inch your way to the room, the astringent smell of
      bleach stings the back of your throat. These must be the maid’s
      quarters.`,
        `Again, you’re faced with an unyielding locked door and you desperately
      scour the room in search of clues to the combination.`,
      ],
    },
    story06: {
      paragraphs: [
        `Another room closer to the Honeymoon Suite, you enter the next space:
      the world-famous Palm Ballroom with its dramatic glass ceilings ,
      marble floor, and gold encrusted wall carvings.`,
        `You spot a concert program but will they help you to crack the code
      to the next room?`,
      ],
    },
    story07: {
      paragraphs: [
        `You hurtle down to the honeymoon suite and key in the 3-digit code to
      the lock, and the door flies open. Inside, you can hear shuffling
      sounds coming from the huge, ornate wardrobe in the corner of the
      room.`,
        `You rush over to open it. To your shock, you see Amanda and Christophe
      Blossom being tied and their mouths covered. Once you release them,
      Amanda shouts, “Lauren did this to us. That wretch keeps us hostage so
      that she can threaten our dear Kurt to do what she wants. You need to
      save Kurt. Quickly!”`,
        `As you are unsure who among the hotel staff that you could trust, you
      ask your partners to keep an eye on the couple to ensure their safety.`,
      ],
    },
    story08: {
      paragraphs: [
        `You see Kurt worriedly talks to the front desk staff. You approach him
      and explain the situation. Relieved that his parents are save, Kurt
      shares “Lauren is a daughter of a former head maid of the Grand Hotel.
      She bears grudges to me and my family because she feels that we wasted
      the hard work poured by her mother and the rest of the hotel staff in
      running the hotel. Her plan of taking control of the hotel would have
      succeeded if it wasn’t for you. Truly I am indebted to you.”`,
        `“I just saw her left the hotel a while ago, in an attempt to escape
      from the authorities. I have called the emergency line and police
      officers are already in pursuit. Since I need to stay to ensure the
      security of the hotel and everyone in it, I have to trouble you once
      more to help in capturing Lauren.”`,
        `You nod. Kurt gives you some important tips that will help you know
      where Lauren is running to. With that, you exit the Grand Hotel.`,
      ],
    },
    story09: {
      paragraphs: [
        `Objective:`,
        `1) Determine the Furthest Point Reachable by Lauren. Click on Grand
        Hotel icon for instruction.`,
        `2) Determine Lauren’s Exit Point. One time chance only. Click on
        point 103 for instruction.`,
      ],
    },
  };
};
