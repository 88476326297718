import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0202, door0201, door0203, puzzle0202 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { Redirect } from 'react-router-dom';
import { addSolve } from '@resources/Solve/actions';
import Help from '@games/BataviaExpress/fragments/Help';

const AgathaGodsson = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Agatha Godsson Room';
  const [visiblePuzzle0202, setVisiblePuzzle0202] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1400;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0202',
        shape: 'poly',
        coords: [
          1171,
          1210,
          1310,
          1186,
          1459,
          1233,
          1455,
          1360,
          1271,
          1377,
          1171,
          1336,
        ],
      },
    ],
  };

  if (solves.filter((solve) => solve === '0201').length === 0) {
    return <Redirect to={`/batavia-express/${channelId}/play/dining-room`} />;
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/agatha-godsson`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/agatha-godsson`
    ) {
      setNavigation({
        backward: {
          title: 'Dining Room',
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/dining-room`);
          },
          image: door0201,
        },
        forward: {
          title: "Mr Fleddoes's Room",
          locked: solves.filter((solve) => solve === '0202').length === 0,
          image: door0203,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/mr-fleddoes`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0202') {
      addPuzzle({
        ...puzzlesConf.puzzle0202,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Agatha Godsson puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0202(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0202}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0202}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Agatha Godsson puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0202(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={12} style={{ textAlign: 'center' }}>
            <img src={puzzle0202} alt="Puzzle" />
          </Col>
          <Col xs={12}>
            <Box centered height={500} textAlign="center">
              {solves.filter((solve) => solve === '0202').length === 0 ? (
                <>
                  You need to solve the puzzle to the left and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    style={{ color: '#333' }}
                    combination="575"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                      );
                      addSolve('0202');
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Agatha Godsson Room Puzzle Solved',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in Agatha Godsson's room and found a clue! The door to Mr Fleddoes's room has now been unlocked.`,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '0202',
                          channel: channelId,
                          game: 'batavia-express',
                        },
                      });
                      doAction({
                        game: 'batavia-express',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '0202',
                          message: 'has solved the Agatha Godsson puzzle',
                          game: 'batavia-express',
                        },
                      });
                      addClue({
                        ...cluesConf.clue0202,
                      });
                      updatePuzzle({
                        ...puzzlesConf.puzzle0202,
                        status: 'solved',
                      });
                      setNavigation({
                        backward: {
                          title: 'Dining Room',
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/dining-room`
                            );
                          },
                          content: door0201,
                        },
                        forward: {
                          title: "Mr Fleddoes's Room",
                          image: door0203,
                          onPress: () => {
                            history.push(
                              `/batavia-express/${channelId}/play/mr-fleddoes`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0202"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        The answer of the riddle is “eye”.
                        <br />
                        <br />
                        Pair of “eyes”, no “I” in team (“I” sound like “eye”),
                        spectacle is for “eyes”, “eye” of the needle, “eye” of
                        the hurricane, hard to open “eyes” when sleepy, and
                        “eyes” are nearly always closed after midnight when
                        sleeping.
                        <br />
                        <br />
                        Convert the word to 3 digit numbers
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
})(AgathaGodsson);
