import React, { useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { Box, View, Title } from '@common/components';
import { setTeam } from '@resources/Team/actions';
import { connect } from 'react-redux';
import { deleteCookie } from '@common/utils/cookie';
const FormItem = Form.Item;

const Team = ({ match, history, setTeam }) => {
	const { params } = match || {};
	const { gameId } = params;

	useEffect(() => {
		deleteCookie('user');
		deleteCookie('solved');
	}, []);

	const handleFinish = async (values) => {
		await setTeam(values.channel);

		history.push(`/${gameId}/${values.channel}/user`);
	};
	return (
		<View>
			<Box centered textAlign="center">
				<Form
					layout="vertical"
					name="access-form"
					onFinish={handleFinish}>
					<Title>Enter your team</Title>
					<FormItem
						name="channel"
						rules={[
							{
								required: true,
								message: 'Please create or join a team',
							},
						]}>
						<Input style={{ fontSize: 30 }} />
					</FormItem>
					<FormItem>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button">
							Join/Create Team
						</Button>
					</FormItem>
				</Form>
			</Box>
		</View>
	);
};

const mapStateToProps = (state) => ({
	game: state.game,
});

export default connect(mapStateToProps, { setTeam })(Team);
