import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import {
  path05,
  door04,
  door06,
  puzzle05,
  clue05,
  scenarioBg,
} from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import Help from '@games/GrandHotel/fragments/Help';

const MaidsQuarter = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addSolve,
  addStory,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Maids Quarter';
  const [visiblePuzzle05, setVisiblePuzzle05] = useState(false);
  const [visibleClue05, setVisibleClue05] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1750;
  const height = 1230;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle05',
        shape: 'poly',
        coords: [305, 593, 310, 733, 407, 732, 389, 592],
      },
      {
        name: 'clue05',
        shape: 'poly',
        coords: [1311, 825, 1343, 1017, 1754, 879, 1638, 733],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel/${channelId}/play/maids-quarter`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/grand-hotel/${channelId}/play/maids-quarter`
    ) {
      setNavigation({
        backward: {
          title: 'Kitchen',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/kitchen`);
          },
          image: door04,
        },
        forward: {
          title: 'Ballroom',
          locked: solves.filter((solve) => solve === '05').length === 0,
          image: door06,
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/ballroom`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle05') {
      setVisiblePuzzle05(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Maids Quarter puzzle',
          game: 'grand-hotel',
        },
      });
    }

    if (area.name === 'clue05') {
      setVisibleClue05(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Maids Quarter clue',
          game: 'grand-hotel',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path05}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('05');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '05',
              channel: channelId,
              game: 'grand-hotel',
              location: "Maid's Quarter",
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          You type the code to the door and with a slight push it swings open.
          As you cautiously inch your way to the room, the astringent smell of
          bleach stings the back of your throat. These must be the maid’s
          quarters.
        </Box>
        <Box fontSize={20}>
          Again, you’re faced with an unyielding locked door and you desperately
          scour the room in search of clues to the combination.
        </Box>
      </Modal>
      <Modal
        visible={visibleClue05}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Maids Quarter clue',
              game: 'grand-hotel',
            },
          });
          setVisibleClue05(false);
        }}
        footer={null}
      >
        <img src={clue05} alt="Clue" width="100%" />

        <Box mt={15}>
          Under the pillow you find photo fragments of a group of maid. One of
          them has a resemblance to Lauren Blossom
        </Box>
      </Modal>
      <Modal
        visible={visiblePuzzle05}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Maids Quarter puzzle',
              game: 'grand-hotel',
            },
          });
          setVisiblePuzzle05(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Box mb={30}>
              <img src={puzzle05} width="100%" alt="Puzzle" />
            </Box>
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '05').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="037"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('05');
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Maids Quarter Puzzle Solved',
                          game: 'grand-hotel',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in the MaidsQuarter! The door to the Ballroom has now been unlocked.`,
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '05',
                          channel: channelId,
                          game: 'grand-hotel',
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '05',
                          message: 'has solved the Maids Quarter puzzle',
                          game: 'grand-hotel',
                        },
                      });
                      setNavigation({
                        backward: {
                          title: 'Kitchen',
                          onPress: () => {
                            history.push(
                              `/grand-hotel/${channelId}/play/kitchen`
                            );
                          },
                          content: door04,
                        },
                        forward: {
                          title: 'Ballroom',
                          image: door06,
                          onPress: () => {
                            history.push(
                              `/grand-hotel/${channelId}/play/ballroom`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="05"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Big plate has the numbers. Small plate has the position.
                        <br />
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(MaidsQuarter);
