import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path0103, door0102, door0104, puzzle0103 } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle, updatePuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { addSolve } from '@resources/Solve/actions';
import { doAction } from '@resources/Action/actions';
import { cluesConfig, puzzlesConfig } from '../config';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { Redirect } from 'react-router-dom';
import Help from '@games/BataviaExpress/fragments/Help';

const CountessAndrenzki = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  addClue,
  addPuzzle,
  addSolve,
  clues,
  updateMember,
}) => {
  const u = getCookie('user') ? JSON.parse(getCookie('user')) : {};
  const location = 'Countess Andrenzki Room';
  const [visiblePuzzle0103, setVisiblePuzzle0103] = useState(false);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1412;
  const cluesConf = cluesConfig(channelId);
  const puzzlesConf = puzzlesConfig(channelId);
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle0103',
        shape: 'poly',
        coords: [
          33,
          691,
          106,
          680,
          168,
          680,
          229,
          677,
          311,
          687,
          317,
          915,
          256,
          938,
          135,
          947,
          38,
          929,
        ],
      },
    ],
  };

  if (solves && solves.filter((solve) => solve === '0102').length === 0) {
    return (
      <Redirect to={`/batavia-express/${channelId}/play/hector-mc-king`} />
    );
  }

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/countess-andrenzki`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'batavia-express',
          },
        });
        doAction({
          game: 'batavia-express',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'batavia-express',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname ===
        `/batavia-express/${channelId}/play/countess-andrenzki`
    ) {
      setNavigation({
        backward: {
          title: "Hector McKing's Room",
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/hector-mc-king`);
          },
          image: door0102,
        },
        forward: {
          title: "Signore Bianco's Room",
          locked: solves.filter((solve) => solve === '0103').length === 0,
          image: door0104,
          onPress: () => {
            history.push(`/batavia-express/${channelId}/play/signore-bianco`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle0103') {
      addPuzzle({
        ...puzzlesConf.puzzle0103,
        status: 'unsolved',
      });
      doAction({
        game: 'batavia-express',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Countess Andrenzki puzzle',
          game: 'batavia-express',
        },
      });
      setVisiblePuzzle0103(true);
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path0103}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={visiblePuzzle0103}
        onCancel={() => {
          doAction({
            game: 'batavia-express',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Countess Andrenzki puzzle',
              game: 'batavia-express',
            },
          });
          setVisiblePuzzle0103(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <img src={puzzle0103} alt="Puzzle" />
          </Col>
          <Col xs={24}>
            <br />
            <br />
            <Box textAlign="center">
              {solves.filter((solve) => solve === '0103').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <Box width="100%">
                    <CombinationLock
                      combination="842"
                      height={70}
                      onMatch={() => {
                        message.success(
                          'Congrats! You have solved the puzzle for this room and found a clue! You may proceed to the next room'
                        );
                        addSolve('0103');
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'notification',
                          message: {
                            type: 'success',
                            title: 'Countess Andrenzki Puzzle Solved',
                            description: `Your team member ${
                              u.name ? u.name : ''
                            } has successfully solved the puzzle in Countess Andrenzki's room and found a clue! The door to Signore Bianco's room has now been unlocked.`,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'solved',
                          message: {
                            puzzle: '0103',
                            channel: channelId,
                            game: 'batavia-express',
                          },
                        });
                        doAction({
                          game: 'batavia-express',
                          channel: socket.channel,
                          event: 'log',
                          type: 'solved',
                          message: {
                            id: socket.id,
                            name: u.name,
                            location: location,
                            channel: channelId,
                            puzzle: '0103',
                            message: 'has solved the Countess Andrenzki puzzle',
                            game: 'batavia-express',
                          },
                        });
                        addClue({
                          ...cluesConf.clue0103,
                        });
                        updatePuzzle({
                          ...puzzlesConf.puzzle0103,
                          status: 'solved',
                        });
                        setNavigation({
                          backward: {
                            title: "Hector McKing's Room",
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/smoking-car`
                              );
                            },
                            content: door0102,
                          },
                          forward: {
                            title: "Signore Bianco's Room",
                            image: door0104,
                            onPress: () => {
                              history.push(
                                `/batavia-express/${channelId}/play/smoking-car`
                              );
                            },
                          },
                        });
                      }}
                      openText={'Unlocked!'}
                    />
                  </Box>
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="0103"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Countess Andrenzki's vanity case requires a three-digit
                        code that must have something to do with the playing
                        card suits of hearts, spades and clubs.
                        <br />
                        <br />
                        The puzzle tells us that we are looking for royal
                        couples between which partial solutions can be found.
                        You can find the clues in the different rooms where the
                        playing cards are present
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle and found a clue
                  in this room
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  addSolve,
  setNavigation,
  updateMember,
})(CountessAndrenzki);
