import {
	SET_SOCKETS,
	SET_SOCKET,
	CLEAR_SOCKETS,
	CLEAR_SOCKET,
	ADD_SOCKET,
	UPDATE_SOCKET,
	REMOVE_SOCKET,
} from './actionTypes';

export const socket = (state = {}, action) => {
	switch (action.type) {
		case SET_SOCKET:
			return action.payload;
		case UPDATE_SOCKET:
			if (state.id.toString() !== action.payload.id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_SOCKET:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_SOCKETS:
			return action.payload;
		case ADD_SOCKET:
			return [...state, action.payload];
		case UPDATE_SOCKET:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_SOCKET:
			return state.map((item) => {
				if (item.id !== action.payload.id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_SOCKETS:
			return [];
		default:
			return state;
	}
};
