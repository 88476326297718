import { API_SOCKET } from '@config/api';
import request from '@common/utils/request';
import {
  SET_HELPS,
  SET_HELP,
  ADD_HELP,
  ADD_HELPS,
  UPDATE_HELP,
} from './actionTypes';

const fetchHelps = (game, channel) => (dispatch) => {
  request('get', `${API_SOCKET}/helps/${game}/${channel}`)
    .then((response) => {
      dispatch({
        type: SET_HELPS,
        payload: response.data.data.map((data) => data.puzzle),
      });
      return response.data.data;
    })
    .catch((err) => Promise.reject(err));
};

const setHelps = (helps) => (dispatch) => {
  dispatch({
    type: SET_HELPS,
    payload: helps,
  });
};

const setHelp = (help) => (dispatch) => {
  dispatch({
    type: SET_HELP,
    payload: help,
  });
};

const addHelp = (help) => (dispatch) => {
  dispatch({
    type: ADD_HELP,
    payload: help,
  });
};

const updateHelp = (help) => (dispatch) => {
  dispatch({
    type: UPDATE_HELP,
    payload: help,
  });
};

const addHelps = (help) => (dispatch) => {
  dispatch({
    type: ADD_HELPS,
    payload: help,
  });
};

export { fetchHelps, setHelps, setHelp, addHelp, addHelps, updateHelp };
