import React, { useEffect } from 'react';
import { Box, Title, View } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import { Button, Space, Table } from 'antd';
import { addPuzzle, fetchPuzzles } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const Manage = ({ puzzles, match, history, fetchPuzzles }) => {
  const { params } = match || {};
  const { channelId } = params;

  const fetchData = async () => {
    await fetchPuzzles('threevia', channelId);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      width: 50,
      render: (text) => text,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
    },

    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button
            size="small"
            onClick={() => {
              history.push(`/threevia/${channelId}/puzzle/${record._id}`);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Title>Manage</Title>
        <Button
          onClick={() => history.push(`/threevia/${channelId}/game-master`)}
        >
          Back to Game
        </Button>
        &nbsp;&nbsp;
        <Button
          onClick={() => history.push(`/threevia/${channelId}/puzzle`)}
          type="primary"
        >
          + Add Question
        </Button>
        <Table columns={columns} dataSource={puzzles} />
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
  fetchPuzzles,
})(Manage);
