import {
	SET_ENDS,
	SET_END,
	CLEAR_ENDS,
	CLEAR_END,
	ADD_END,
	ADD_ENDS,
	UPDATE_END,
	REMOVE_END,
} from './actionTypes';

export const end = (state = false, action) => {
	switch (action.type) {
		case SET_END:
			return action.payload;
		case UPDATE_END:
			if (state.toString() !== action.payload.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_END:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_ENDS:
			return action.payload;
		case ADD_END:
			if (state.filter((item) => item === action.payload).length > 0) {
				return [...state];
			}

			return [...state, action.payload];
		case ADD_ENDS:
			const c = [...state];
			action.payload.forEach((payload) => {
				if (state.filter((item) => item === payload).length === 0) {
					c.push(payload);
				}
			});

			return c;
		case UPDATE_END:
			return state.map((item) => {
				if (item !== action.payload) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_END:
			return state.map((item) => {
				if (item !== action.payload) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_ENDS:
			return [];
		default:
			return state;
	}
};
