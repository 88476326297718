import React, { useEffect, useState, useCallback } from 'react';
import WebSocket from '@services/WebSocket';
import { connect } from 'react-redux';
import { USER_ROLE } from '@config/app';
import { setTeam } from '@resources/Team/actions';
import { addClue, addClues } from '@resources/Clue/actions';
import { addLog } from '@resources/Log/actions';
import { addPuzzle, addPuzzles } from '@resources/Puzzle/actions';
import { setSocket } from '@resources/Socket/actions';
import {
  addMember,
  removeMember,
  updateMember,
} from '@resources/Member/actions';
import { setUser } from '@resources/User/actions';
import { doAction } from '@resources/Action/actions';

import { fetchState, setState } from '@resources/State/actions';
import moment from 'moment';
import { Box, Title, View } from '@common/components';
import { Col, Row, Button, notification, Table } from 'antd';

import {
  addSolve,
  updateSolve,
  fetchSolvesFull,
} from '@resources/Solve/actions';
import { addHelp, fetchHelps } from '@resources/Help/actions';
import { fetchPuzzleByStage } from '@resources/Puzzle/actions';

const GameMaster = ({
  solves,
  match,
  history,
  removeMember,
  updateMember,
  addMember,
  setTeam,
  addLog,
  fetchState,
  fetchSolvesFull,
  addSolve,
  doAction,
  state,
  setState,
  updateSolve,
  setSocket,
  members,
  fetchPuzzleByStage,
  puzzle,
}) => {
  const { params } = match || {};
  const { channelId } = params || {};
  const [roundOnly, setRoundOnly] = useState(true);
  const [chan, setChan] = useState();

  const [mem, showMem] = useState(true);

  const fetchData = async (channelId) => {
    await fetchState('threevia', channelId);
    await fetchSolvesFull('threevia', channelId);
  };

  const fetchPuzzle = async (state) => {
    await fetchPuzzleByStage('threevia', channelId, state.stage);
  };

  useEffect(() => {
    channelId && fetchData(channelId);
  }, [channelId]);

  useEffect(() => {
    state && fetchPuzzle(state);
  }, [state]);

  useEffect(() => {
    if (channelId) {
      setTeam(channelId);
      let uid = null;
      const { pusher, channel } = WebSocket({
        game: 'threevia',
        channel: channelId,
        role: USER_ROLE.SPECTATOR,
        onSubscribed: (member) => {
          uid = member.me.id;
          for (var id in member.members) {
            const m = member.members[id];
            if (m.role === 'player') {
              addMember({ id, name: m.name, info: member.info });
            }
          }
          setSocket({ id: uid, channel });

          setChan(channel);
        },
        onMemberAdded: (member) => {
          if (member.info.role === 'player') {
            // addLog({
            // 	id: moment().format('DDMMYYYYHHmmss'),
            // 	date: moment(),
            // 	name: member.info.name,
            // 	member: member.id,
            // 	message: `has joined the game`,
            // });
            addMember({
              id: member.id,
              name: member.info.name,
              info: member.info,
            });
          }
        },
        onMemberRemoved: (member) => {
          if (member.info.role === 'player') {
            addLog({
              id: moment().format('DDMMYYYYHHmmss'),
              name: member.info.name,
              member: member.id,
              date: moment(),
              message: `has left the game`,
            });

            removeMember(member);
          }
        },
        events: [
          // {
          // 	title: 'member',
          // 	callback: (data) => {
          // 		updateMember(data);
          // 		data.location &&
          // 			addLog({
          // 				...data,
          // 				id: moment().format('DDMMYYYYHHmmss'),
          // 				date: moment(),
          // 				message: `has moved to ${data.location}`,
          // 			});
          // 	},
          // },
          {
            title: 'log',
            callback: (data) => {
              addLog({
                ...data,
                id: `${data.id}.${moment().format('DDMMYYYYHHmmss')}`,
                date: moment(),
              });

              updateMember(data);
            },
          },
          {
            title: 'notification',
            callback: (notif) => {
              // console.log('SOCKET NOTIFICATION', notif);
              notification[notif.type]({
                message: notif.title,
                description: notif.description,
              });
            },
          },
          {
            title: 'solved',
            callback: (solve) => {
              console.log('SOLVE', solve);
              if (solve.act === 'create') {
                addSolve(solve);
              } else {
                updateSolve(solve);
              }
            },
          },

          {
            title: 'state',
            callback: (state) => {
              setState(state);
            },
          },
        ],
      });

      window.addEventListener('beforeunload', () => {
        pusher.unsubscribe(channel);
      });
    }
  }, [channelId, history]);

  // useEffect(() => {
  //   stage = 0;
  // }, [solves, members, logs, state]);

  const ranking = useCallback(() => {
    const rank = [];
    if (solves && solves.length > 0) {
      solves.forEach((solve) => {
        if (
          solve.isCorrect === true &&
          rank.map((ran) => ran.user).indexOf(solve.name) > -1
        ) {
          const idx = rank.map((ran) => ran.user).indexOf(solve.name);

          rank[idx].total += solve.point;
        } else if (
          solve.isCorrect === true &&
          rank.map((ran) => ran.user).indexOf(solve.name) === -1
        ) {
          rank.push({ user: solve.name, total: solve.point });
        }
      });
    }

    if (!state || !solves || (solves && solves.length === 0)) {
      return null;
    }

    return (
      <Box>
        <Title fontSize={30}>Ranking</Title>
        <Row>
          <Col xs={4} md={2}>
            <strong>Rank</strong>
          </Col>
          <Col xs={10} md={10}>
            <strong>User</strong>
          </Col>
          <Col xs={10} md={12}>
            <strong>Points</strong>
          </Col>
        </Row>
        {rank
          .sort((a, b) => {
            if (a.total < b.total) {
              return 1;
            }

            if (a.total > b.total) {
              return -1;
            }

            return 0;
          })
          .map((ran, index) => (
            <Row>
              <Col xs={4} md={2}>
                {index + 1}
              </Col>
              <Col xs={10} md={10}>
                {ran.user}
              </Col>
              <Col xs={10} md={12}>
                {ran.total}
              </Col>
            </Row>
          ))}
      </Box>
    );
  }, [solves]);

  let stage = 0;

  return (
    <View>
      <Box pt={100} px={30} mb={100}>
        <Row gutter={24}>
          <Col xs={24} md={mem ? 18 : 24} lg={mem ? 18 : 24}>
            <Row align="middle">
              <Col xs={12}>
                <Title mb={0}>Game Master </Title>
              </Col>
              <Col xs={12}>
                <Row align="middle" gutter={24}>
                  <Col>
                    <Button
                      size="small"
                      onClick={() => {
                        history.push(`/threevia/${channelId}/manage`);
                      }}
                    >
                      Manage Game
                    </Button>
                  </Col>
                  <Col>
                    {state && state.isStart ? (
                      <Button
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                          doAction({
                            action: 'update',
                            id: state._id,
                            game: 'threevia',
                            channel: chan,
                            event: 'state',
                            message: {
                              channel: channelId,
                              game: 'threevia',
                              isStart: false,
                            },
                          });
                        }}
                      >
                        End Game
                      </Button>
                    ) : (
                      state &&
                      state.isStart === false && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            doAction({
                              action: 'update',
                              id: state._id,
                              game: 'threevia',
                              channel: chan,
                              event: 'state',
                              message: {
                                channel: channelId,
                                game: 'threevia',
                                isStart: true,
                              },
                            });
                          }}
                        >
                          Resume Game
                        </Button>
                      )
                    )}
                    <br />
                    <br />
                    {state && state.hasTimer ? (
                      <Button
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                          doAction({
                            action: 'update',
                            id: state._id,
                            game: 'threevia',
                            channel: chan,
                            event: 'state',
                            message: {
                              channel: channelId,
                              game: 'threevia',
                              hasTimer: false,
                            },
                          });
                        }}
                      >
                        Remove Timer
                      </Button>
                    ) : (
                      state &&
                      !state.hasTimer && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            doAction({
                              action: 'update',
                              id: state._id,
                              game: 'threevia',
                              channel: chan,
                              event: 'state',
                              message: {
                                channel: channelId,
                                game: 'threevia',
                                hasTimer: true,
                              },
                            });
                          }}
                        >
                          Use Timer
                        </Button>
                      )
                    )}
                  </Col>

                  <Col>
                    {mem ? (
                      <Button
                        size="small"
                        onClick={() => {
                          showMem(false);
                        }}
                      >
                        Hide participants
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        onClick={() => {
                          showMem(true);
                        }}
                      >
                        Show participants ({members.length})
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <br />

            <Row align="middle">
              <Col xs={12}>
                <Title fontSize={70} lineHeight={1} mb={0}>
                  {state && `ROUND ${state.stage}`}
                </Title>
              </Col>
              <Col xs={12}>
                {state ? (
                  <Row gutter={24}>
                    <Col>
                      <Button
                        size="small"
                        onClick={() => {
                          doAction({
                            action: 'update',
                            id: state._id,
                            game: 'threevia',
                            channel: chan,
                            event: 'state',
                            message: {
                              channel: channelId,
                              game: 'threevia',
                              stage: state.stage - 1,
                            },
                          });
                        }}
                      >
                        Previous Stage
                      </Button>
                    </Col>
                    {state.isOpen ? (
                      <Col>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            doAction({
                              action: 'update',
                              id: state._id,
                              game: 'threevia',
                              channel: chan,
                              event: 'state',
                              message: {
                                channel: channelId,
                                game: 'threevia',
                                isOpen: false,
                              },
                            });
                          }}
                        >
                          Close Form
                        </Button>
                      </Col>
                    ) : (
                      <Col>
                        <Button
                          size="small"
                          onClick={() => {
                            doAction({
                              action: 'update',
                              id: state._id,
                              game: 'threevia',
                              channel: chan,
                              event: 'state',
                              message: {
                                channel: channelId,
                                game: 'threevia',
                                isOpen: true,
                              },
                            });
                          }}
                        >
                          Open Form
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <Button
                        size="small"
                        onClick={() => {
                          doAction({
                            action: 'update',
                            id: state._id,
                            game: 'threevia',
                            channel: chan,
                            event: 'state',
                            message: {
                              channel: channelId,
                              game: 'threevia',
                              stage: state.stage + 1,
                            },
                          });
                        }}
                      >
                        Next Stage
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      doAction({
                        action: 'create',
                        game: 'threevia',
                        channel: chan,
                        event: 'state',
                        message: {
                          channel: channelId,
                          game: 'threevia',
                          stage: 1,
                          isStart: true,
                          isOpen: true,
                        },
                      });
                    }}
                  >
                    Start Game
                  </Button>
                )}
              </Col>
            </Row>

            <br />

            <br />
            {ranking()}
            <br />
            <br />

            {solves && solves.length > 0 ? (
              state && roundOnly ? (
                <Button
                  size="small"
                  onClick={() => {
                    setRoundOnly(false);
                  }}
                >
                  Show all round
                </Button>
              ) : (
                state && (
                  <Button
                    size="small"
                    onClick={() => {
                      setRoundOnly(true);
                    }}
                  >
                    Show current round only
                  </Button>
                )
              )
            ) : null}

            <br />
            <br />
            {solves.filter((sol) =>
              roundOnly ? sol.stage === state.stage : true
            ).length === 0
              ? 'No participant has submitted an answer'
              : solves
                  .filter((sol) =>
                    roundOnly ? sol.stage === state.stage : true
                  )
                  .sort((a, b) => {
                    if (roundOnly) {
                      if (puzzle && puzzle.type === 'number') {
                        if (a.answer < b.answer) {
                          return -1;
                        }

                        if (a.answer > b.answer) {
                          return 1;
                        }
                      } else {
                        if (a.createdDate < b.createdDate) {
                          return -1;
                        }

                        if (a.createdDate > b.createdDate) {
                          return 1;
                        }
                      }

                      return 0;
                    } else {
                      if (a.stage < b.stage) {
                        return -1;
                      }

                      if (a.stage > b.stage) {
                        return 1;
                      }

                      return 0;
                    }
                  })
                  .map((log, index) => {
                    let header;
                    if (stage < log.stage) {
                      header = (
                        <Box my={20}>
                          <Row>
                            <Col xs={9} sm={9} md={9} lg={9}>
                              <Title fontSize={20}>Round {log.stage}</Title>
                            </Col>
                            {puzzle && puzzle.answer && (
                              <Col>
                                {puzzle.answer.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )}
                              </Col>
                            )}
                          </Row>
                        </Box>
                      );
                      stage = log.stage;
                    }

                    return log ? (
                      <>
                        {header}
                        <Row
                          key={`log-${index}`}
                          gutter={24}
                          style={{
                            backgroundColor:
                              log.isCorrect === true
                                ? '#125015'
                                : log.isCorrect === false
                                ? '#501218'
                                : 'transparent',
                            border: '1px dashed #333',
                          }}
                          align="middle"
                        >
                          <Col xs={4} sm={4} md={4} lg={4}>
                            {moment(
                              log.date ? log.date : log.createdDate
                            ).format('DD/MM/YYYY HH:mm:ss')}
                          </Col>

                          <Col xs={5} sm={5} md={5} lg={5}>
                            <strong>{log.name}</strong>
                          </Col>
                          <Col xs={8} sm={8} md={8} lg={8}>
                            {log.answer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2}>
                            {log.point}pts
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5}>
                            <Button
                              size="small"
                              type={
                                log.isCorrect === true && log.point === 3
                                  ? 'primary'
                                  : 'default'
                              }
                              onClick={() => {
                                doAction({
                                  action: 'update',
                                  id: log._id,
                                  game: 'threevia',
                                  channel: chan,
                                  event: 'solved',
                                  message: {
                                    isCorrect: true,
                                    point: 900,
                                  },
                                });
                              }}
                            >
                              Rd 1
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              size="small"
                              type={
                                log.isCorrect === true && log.point === 2
                                  ? 'primary'
                                  : 'default'
                              }
                              onClick={() => {
                                doAction({
                                  action: 'update',
                                  id: log._id,
                                  game: 'threevia',
                                  channel: chan,
                                  event: 'solved',
                                  message: {
                                    isCorrect: true,
                                    point: 600,
                                  },
                                });
                              }}
                            >
                              Rd 2
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              size="small"
                              type={
                                log.isCorrect === true && log.point === 1
                                  ? 'primary'
                                  : 'default'
                              }
                              onClick={() => {
                                doAction({
                                  action: 'update',
                                  id: log._id,
                                  game: 'threevia',
                                  channel: chan,
                                  event: 'solved',
                                  message: {
                                    isCorrect: true,
                                    point: 300,
                                  },
                                });
                              }}
                            >
                              Rd 3
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                              type={
                                log.isCorrect === false ? 'primary' : 'default'
                              }
                              onClick={() => {
                                doAction({
                                  action: 'update',
                                  id: log._id,
                                  game: 'threevia',
                                  channel: chan,
                                  event: 'solved',
                                  message: {
                                    isCorrect: false,
                                    point: 0,
                                  },
                                });
                              }}
                              size="small"
                              danger
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ''
                    );
                  })}
          </Col>
          {mem && (
            <Col>
              <Title fontSize={30}>Participants</Title>
              <Table
                columns={[{ title: 'Name', dataIndex: 'name', key: 'name' }]}
                dataSource={members.map((member) => {
                  return {
                    name: member.name,
                    location: member.location,
                    key: member.id,
                  };
                })}
                pagination={false}
                width="100%"
              />
            </Col>
          )}
        </Row>

        <br />
        <br />
      </Box>

      <Box height={100}></Box>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clues: state.clues,
    logs: state.logs,
    members: state.members,
    state: state.state,
    solves: state.solves,
    helps: state.helps,
    puzzle: state.puzzle,
  };
};

export default connect(mapStateToProps, {
  addMember,
  addPuzzle,
  addPuzzles,
  removeMember,
  updateMember,
  setTeam,
  setUser,
  doAction,
  setSocket,
  addClue,
  addClues,
  addLog,
  fetchSolvesFull,
  fetchHelps,
  fetchState,
  addSolve,
  addHelp,
  setState,
  updateSolve,
  fetchPuzzleByStage,
})(GameMaster);
