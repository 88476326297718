import React, { useState, useEffect } from 'react';
import { Box, View, Title } from '@common/components';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import {
  Button,
  Input,
  Form,
  InputNumber,
  Select,
  Checkbox,
  Space,
} from 'antd';
import {
  fetchPuzzles,
  fetchPuzzle,
  clearPuzzle,
} from '@resources/Puzzle/actions';
import { doAction } from '@resources/Action/actions';
import { updateMember } from '@resources/Member/actions';
import { isEmpty } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const FormItem = Form.Item;
const { Option } = Select;

const Ballroom = ({
  socket,
  match,
  history,
  fetchPuzzle,
  puzzle,
  doAction,
  clearPuzzle,
}) => {
  const { params } = match || {};
  const { channelId, puzzleId } = params;
  const [chan, setChan] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    puzzleId && (await fetchPuzzle(puzzleId));
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
    setLoading(false);

    return () => {
      clearPuzzle();
    };
  }, []);

  useEffect(() => {
    socket && socket.channel && setChan(socket.channel);
  }, [socket]);

  const handleFinish = async (values) => {
    await doAction({
      game: 'threevia',
      channel: chan,
      event: 'puzzle',
      action: puzzleId ? 'update' : 'create',
      id: puzzleId,
      message: {
        channel: channelId,
        game: 'threevia',
        stage: values.stage,
        question: values.question,
        answer: values.answer,
        type: values.type,
        options: values.options,
      },
    });

    history.push(`/threevia/${channelId}/manage`);
  };

  console.log(puzzle);

  return (
    <View>
      <Box centered>
        {!loading && (!puzzleId || !isEmpty(puzzle)) && (
          <Form
            layout="vertical"
            name="access-form"
            onFinish={handleFinish}
            initialValues={puzzle}
          >
            <Title>Enter your question</Title>
            <FormItem
              label="stage"
              name="stage"
              rules={[
                {
                  required: true,
                  message: 'Please enter your question',
                },
              ]}
            >
              <InputNumber />
            </FormItem>
            <FormItem
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: 'Please enter your question',
                },
              ]}
            >
              <Input />
            </FormItem>

            <FormItem
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: 'Please enter your answer',
                },
              ]}
            >
              <Input />
            </FormItem>

            <FormItem
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select your type',
                },
              ]}
            >
              <Select>
                <Option value="text">Text</Option>
                <Option value="number">Number</Option>
                <Option value="select">Multiple Choice</Option>
              </Select>
            </FormItem>

            <Form.Item
              shouldUpdate={(prevValues, curValues) => {
                console.log(curValues);
                return prevValues.type !== curValues.type;
              }}
            >
              {({ getFieldValue }) => {
                return getFieldValue('type') === 'select' ? (
                  <Form.List name="options">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'title']}
                              fieldKey={[fieldKey, 'title']}
                              rules={[
                                { required: true, message: 'Missing title' },
                              ]}
                            >
                              <Input placeholder="Title" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'isAnswer']}
                              fieldKey={[fieldKey, 'isAnswer']}
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add field
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ) : null;
              }}
            </Form.Item>

            <FormItem>
              <Button
                className="login-form-button"
                onClick={() => {
                  history.push(`/threevia/${channelId}/manage`);
                }}
              >
                {`Cancel`}
              </Button>
              &nbsp;&nbsp;
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {`Submit Question`}
              </Button>
            </FormItem>
          </Form>
        )}
      </Box>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  puzzle: state.puzzle,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
  state: state.state,
});

export default connect(mapStateToProps, {
  doAction,
  setNavigation,
  updateMember,
  fetchPuzzles,
  fetchPuzzle,
  clearPuzzle,
})(Ballroom);
