import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path06, door05, door07, scenarioBg } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Col, Row, Modal, message } from 'antd';
import CombinationLock from 'combination-lock-react';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';
import Help from '@games/GrandHotel/fragments/Help';

const Ballroom = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addSolve,
  addStory,
}) => {
  const u = getCookie('GHuser') ? JSON.parse(getCookie('GHuser')) : {};
  const location = 'Ballroom';
  const [visiblePuzzle06, setVisiblePuzzle06] = useState(false);
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 1760;
  const height = 1230;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [
      {
        name: 'puzzle06',
        shape: 'poly',
        coords: [135, 869, 87, 1199, 222, 1201, 260, 880],
      },
    ],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/ballroom`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel',
          },
        });
        doAction({
          game: 'grand-hotel',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel/${channelId}/play/ballroom`
    ) {
      setNavigation({
        backward: {
          title: 'Kitchen',
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/kitchen`);
          },
          image: door05,
        },
        forward: {
          title: 'Honeymoon Suite',
          locked: solves.filter((solve) => solve === '06').length === 0,
          image: door07,
          onPress: () => {
            history.push(`/grand-hotel/${channelId}/play/honeymoon-suite`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  const handleClick = (area) => {
    if (area.name === 'puzzle06') {
      setVisiblePuzzle06(true);
      doAction({
        game: 'grand-hotel',
        channel: socket.channel,
        event: 'log',
        message: {
          id: socket.id,
          name: u.name,
          location: location,
          channel: channelId,
          message: 'has opened the Ballroom puzzle',
          game: 'grand-hotel',
        },
      });
    }
  };

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path06}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={handleClick}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('06');
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '06',
              channel: channelId,
              game: 'grand-hotel',
              location: 'Ballroom',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          Another room closer to the Honeymoon Suite, you enter the next space:
          the world-famous Palm Ballroom with its dramatic glass ceilings ,
          marble floor, and gold encrusted wall carvings.
        </Box>
        <Box fontSize={20}>
          You spot a concert program but will they help you to crack the code to
          the next room?
        </Box>
      </Modal>
      <Modal
        visible={visiblePuzzle06}
        onCancel={() => {
          doAction({
            game: 'grand-hotel',
            channel: socket.channel,
            event: 'log',
            message: {
              id: socket.id,
              name: u.name,
              location: location,
              channel: channelId,
              message: 'has closed the Ballroom puzzle',
              game: 'grand-hotel',
            },
          });
          setVisiblePuzzle06(false);
        }}
        width={800}
        footer={null}
      >
        <Row gutter={24}>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Box mb={30}>
              <p>
                When the last concert was played in the ballroom, five musicians
                (Penelope, Harold, Eduardo, William, and Beatrice) performed
                five musical solos.
              </p>
              <p>
                Two of them were rock solos, two were blues solos, and one was a
                funk solo. Three musicians were guitar players and two were
                drummers. Each musician performed one solo and played one
                instrument.
              </p>
              <p>
                William did not play the funk. Harold played a blues, and was
                immediately followed by Beatrice, who played the drums. Eduardo
                played third. There were no blues drum. The funk was played last
                and the blues was played first. There was one drums that was
                played between two guitar, and two guitar played between the
                first and last drums. The same musical genre is not played
                consecutively.
              </p>
              <Box my={15} textAlign="center">
                ----------------------------------------------------------------
              </Box>
              <p>
                <strong>1st digit</strong>: number of letters of the name of the
                third player, minus number of letters of the name of the first
                player
              </p>
              <p>
                <strong>2nd digit</strong>: number of letters of the name of the
                fifth player, minus number of letters of the name of the fourth
                player.
              </p>
              <p>
                <strong>3rd digit</strong>: number of letters of the name of the
                second player
              </p>
            </Box>
          </Col>
          <Col xs={24}>
            <Box textAlign="center">
              {solves.filter((solve) => solve === '06').length === 0 ? (
                <>
                  You need to solve the puzzle above and use the answer
                  combination to open the lock below
                  <br />
                  <br />
                  <CombinationLock
                    combination="118"
                    height={70}
                    onMatch={() => {
                      message.success(
                        'Congrats! You have solved the puzzle for this room! You may proceed to the next room'
                      );
                      addSolve('06');
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'notification',
                        message: {
                          type: 'success',
                          title: 'Ballroom Puzzle Solved',
                          game: 'grand-hotel',
                          description: `Your team member ${
                            u.name ? u.name : ''
                          } has successfully solved the puzzle in the Ballroom! The door to the Honeymoon Suite has now been unlocked.`,
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'solved',
                        message: {
                          puzzle: '06',
                          channel: channelId,
                          game: 'grand-hotel',
                        },
                      });
                      doAction({
                        game: 'grand-hotel',
                        channel: socket.channel,
                        event: 'log',
                        type: 'solved',
                        message: {
                          id: socket.id,
                          name: u.name,
                          location: location,
                          channel: channelId,
                          puzzle: '06',
                          message: 'has solved the Ballroom puzzle',
                          game: 'grand-hotel',
                        },
                      });
                      setNavigation({
                        backward: {
                          title: 'Kitchen',
                          onPress: () => {
                            history.push(
                              `/grand-hotel/${channelId}/play/kitchen`
                            );
                          },
                          content: door05,
                        },
                        forward: {
                          title: 'Ballroom',
                          image: door07,
                          onPress: () => {
                            history.push(
                              `/grand-hotel/${channelId}/play/ballroom`
                            );
                          },
                        },
                      });
                    }}
                    openText={'Unlocked!'}
                  />
                  <br />
                  <br />
                  <Help
                    location={location}
                    puzzle="06"
                    channel={channelId}
                    solution={
                      <Box>
                        <strong>Hint</strong>
                        <br />
                        Harold played first.
                      </Box>
                    }
                  />
                </>
              ) : (
                <>
                  Your team has successfully solved this puzzle
                  <br />
                  <br />
                  <CheckCircleFilled
                    style={{
                      fontSize: 100,
                      color: '#52c41a',
                    }}
                  />
                </>
              )}
            </Box>
          </Col>
        </Row>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Ballroom);
