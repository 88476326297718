import React, { useState, useEffect } from 'react';
import { Box, View } from '@common/components';
import { path08, door07, door09, scenarioBg } from '../assets/images';
import { connect } from 'react-redux';
import { setNavigation } from '@resources/Navigation/actions';
import ImageMapper from 'react-image-mapper';
import { Modal } from 'antd';
import { addPuzzle } from '@resources/Puzzle/actions';
import { addClue } from '@resources/Clue/actions';
import { doAction } from '@resources/Action/actions';
import { getCookie } from '@common/utils/cookie';
import { isEmpty } from 'lodash';
import { updateMember } from '@resources/Member/actions';
import { addSolve } from '@resources/Solve/actions';
import { addStory } from '@resources/Story/actions';

const Ballroom = ({
  setNavigation,
  socket,
  solves,
  match,
  history,
  doAction,
  clues,
  updateMember,
  addStory,
}) => {
  const u = getCookie('GHPuser') ? JSON.parse(getCookie('GHPuser')) : {};
  const location = 'Ballroom';
  const [roomScenario, setRoomScenario] = useState(true);
  const { params } = match || {};
  const { channelId } = params;
  const width = 2000;
  const height = 1425;
  // const solvedPuzzles = getCookie('solved')
  // 	? JSON.parse(getCookie('solved'))
  // 	: [];

  const MAP = {
    name: 'my-map',
    areas: [],
  };

  useEffect(() => {
    if (
      !isEmpty(socket) &&
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/lobby`
    ) {
      if (!isEmpty(socket)) {
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'member',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            game: 'grand-hotel-pro',
          },
        });
        doAction({
          game: 'grand-hotel-pro',
          channel: socket.channel,
          event: 'log',
          message: {
            id: socket.id,
            name: u.name,
            location: location,
            channel: channelId,
            message: `has moved to ${location}`,
            game: 'grand-hotel-pro',
          },
        });
        updateMember({ id: socket.id, name: u.name, location });
        // doAction({
        // 	channel: socket.channel,
        // 	event: 'clues',
        // 	message: clues,
        // });
      }
    }
  }, [socket, history]);

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === `/grand-hotel-pro/${channelId}/play/lobby`
    ) {
      setNavigation({
        backward: {
          title: 'Honeymoon Suite',
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/honeymoon-suite`);
          },
          image: door07,
        },
        forward: {
          title: 'Outside',
          image: door09,
          onPress: () => {
            history.push(`/grand-hotel-pro/${channelId}/play/outside`);
          },
        },
      });
    }
  }, [clues, solves, history]);

  return (
    <View>
      <Box textAlign="center">
        <ImageMapper
          src={path08}
          width={window.innerWidth}
          height={(window.innerWidth * height) / width}
          map={MAP}
          imgWidth={width}
          onClick={() => {}}
        />
      </Box>
      <Modal
        visible={roomScenario}
        footer={null}
        onCancel={() => {
          addStory('08');
          doAction({
            game: 'grand-hotel-pro',
            channel: socket.channel,
            event: 'story',
            message: {
              story: '08',
              channel: channelId,
              game: 'grand-hotel-pro',
              location: 'Lobby',
            },
          });
          setRoomScenario(false);
        }}
        bodyStyle={{ backgroundImage: `url(${scenarioBg})`, color: '#333' }}
      >
        <Box fontSize={20} mb={30}>
          You see Kurt worriedly talks to the front desk staff. You approach him
          and explain the situation. Relieved that his parents are save, Kurt
          shares “Lauren is a daughter of a former head maid of the Grand Hotel.
          She bears grudges to me and my family because she feels that we wasted
          the hard work poured by her mother and the rest of the hotel staff in
          running the hotel. Her plan of taking control of the hotel would have
          succeeded if it wasn’t for you. Truly I am indebted to you.”
        </Box>
        <Box fontSize={20} mb={30}>
          “I just saw her left the hotel a while ago, in an attempt to escape
          from the authorities. I have called the emergency line and police
          officers are already in pursuit. Since I need to stay to ensure the
          security of the hotel and everyone in it, I have to trouble you once
          more to help in capturing Lauren.”
        </Box>

        <Box fontSize={20}>
          You nod. Kurt gives you some important tips that will help you know
          where Lauren is running to. With that, you exit the Grand Hotel.
        </Box>
      </Modal>
    </View>
  );
};

const mapStateToProps = (state) => ({
  puzzles: state.puzzles,
  socket: state.socket,
  clues: state.clues,
  solves: state.solves,
});

export default connect(mapStateToProps, {
  doAction,
  addPuzzle,
  addClue,
  setNavigation,
  updateMember,
  addSolve,
  addStory,
})(Ballroom);
